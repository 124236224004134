import { useState } from 'react';
import ViewInstrument from './view-instrument';
import AddInstrument from './add-instrument';
import './styles.scss';

const Instrument: React.FC = () => {
  const [instrumentInfo, setInstrumentInfo] = useState();
  const [isViewInstrument, setIsViewInstrument] = useState(true);

  const handleViewInstrumentInfo = (data: any) => {
    setInstrumentInfo(data);
    setIsViewInstrument(false);
  };

  return (
    <div className="instrument">
      <h1 className="instrument-title">Instrument management</h1>
      {isViewInstrument ? (
        <ViewInstrument
          setIsViewInstrument={setIsViewInstrument}
          handleViewInstrumentInfo={handleViewInstrumentInfo}
        />
      ) : (
        <AddInstrument
          instrumentInfo={instrumentInfo}
          setIsViewInstrument={setIsViewInstrument}
        />
      )}
    </div>
  );
};

export default Instrument;
