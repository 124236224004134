import {
  SET_TIER_INFO,
  SET_INSTRUMENT_LIST,
  SET_SYMBOL_LIST,
  SET_COIN_LIST,
} from '../constants/instrument';

const initialState = {
  instrumentList: {},
  tradingTierInfo: [],
  symbolList: [],
  coinList: [],
};

export const instrumentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_INSTRUMENT_LIST: {
      return {
        ...state,
        instrumentList: action.payload,
      };
    }
    case SET_TIER_INFO: {
      return {
        ...state,
        tradingTierInfo: action.payload,
      };
    }
    case SET_SYMBOL_LIST: {
      return {
        ...state,
        symbolList: action.payload,
      };
    }
    case SET_COIN_LIST: {
      return {
        ...state,
        coinList: action.payload,
      };
    }

    default:
      return state;
  }
};
