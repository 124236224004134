import { FracInput } from 'src/components/12.inputs';
import SearchIcon from 'src/assets/icons/common/search-icon.svg';
import RemoveIcon from 'src/assets/icons/common/remove-icon.svg';
import ArrowDown from 'src/assets/icons/common/color-arrow-down.svg';
import ArrowUp from 'src/assets/icons/common/color-arrow-up.svg';
import { ColumnsType } from 'antd/es/table';
import { Col, Row, Space } from 'antd';
import FracTable from 'src/components/05.table';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalConfirm from 'src/components/06.modals/ModalConfirm';
import { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_SIZE_OPTIONS } from 'src/constants';
import {
  fetchUserAddressList,
  fetchUserDiviceList,
  handleDeleteUserDevice,
} from 'src/store/actions/user-management';
import FracTooltip from 'src/components/19.tooltip';
import { ellipseAddress } from 'src/helpers';
import dayjs from 'dayjs';
import { FULL_DATETIME_FORMAT } from 'src/constants/formatters';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { DEBOUNCE_SEARCH_TIME } from 'src/constants/time';

enum ExpandType {
  OPEN = 'open',
  CLOSE = 'close',
  NONE = 'none',
  CHILDREN = 'children',
}

type DeviceItem = {
  id?: number;
  ip: string;
  recentActive: string;
  device: string;
  expandType: ExpandType;
};

const DeviceManagement = () => {
  const dispatch = useDispatch();

  const [curPage, setCurPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  const [deviceLoading, setDeviceLoading] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [curAddress, setCurAddress] = useState<string | undefined>();
  const [removeDevice, setRemoveDevice] = useState<number | undefined>();
  const [openDevices, setOpenDevices] = useState<number[]>([]);
  const [convertDeviceList, setConvertDeviceList] = useState<DeviceItem[]>([]);

  const userAddressData = useSelector(
    (state: any) => state.userManagement.userAddressData
  );

  const userDeviceData = useSelector(
    (state: any) => state.userManagement.userDeviceData
  );

  useEffect(() => {
    setUserLoading(true);
    dispatch(
      fetchUserAddressList(
        {
          page: curPage,
          limit: pageSize,
        },
        () => setUserLoading(false)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, curPage]);

  const fetchDeviceFirstTime = () => {
    if (!!curAddress) {
      setDeviceLoading(true);
      dispatch(
        fetchUserDiviceList(curAddress, (deviceList: any) => {
          let newArr: DeviceItem[] = [];
          deviceList.forEach((deviceInfo: any) => {
            const timeList = JSON.parse(deviceInfo?.recentActive || []);
            timeList.forEach((timeInfo: number, index: number) => {
              const datetime = dayjs
                .unix(timeInfo)
                .format(FULL_DATETIME_FORMAT);
              if (timeList.length < 2)
                newArr.push({
                  ...deviceInfo,
                  recentActive: datetime,
                  expandType: ExpandType.NONE,
                });
              else if (index === 0)
                newArr.push({
                  ...deviceInfo,
                  recentActive: datetime,
                  expandType: ExpandType.CLOSE,
                });
            });
          });
          setConvertDeviceList(newArr);
          setDeviceLoading(false);
        })
      );
    }
  };

  useEffect(() => {
    fetchDeviceFirstTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curAddress]);

  useEffect(() => {
    setCurPage(1);
  }, [pageSize]);

  useEffect(() => {
    const regenerateDeviceList = () => {
      let newArr: DeviceItem[] = [];
      (userDeviceData.data || []).forEach(
        (deviceInfo: any, deviceIndex: number) => {
          const timeList = JSON.parse(deviceInfo?.recentActive || []);
          timeList.forEach((timeInfo: number, timeIndex: number) => {
            const datetime = dayjs
              .unix(timeInfo)
              .utc()
              .format(FULL_DATETIME_FORMAT);
            if (timeList.length < 2) {
              newArr.push({
                ...deviceInfo,
                recentActive: datetime,
                expandType: ExpandType.NONE,
              });
            } else if (openDevices.includes(deviceInfo.id)) {
              if (timeIndex === 0)
                newArr.push({
                  ...deviceInfo,
                  recentActive: datetime,
                  expandType: ExpandType.OPEN,
                });
              else
                newArr.push({
                  ip: '',
                  recentActive: datetime,
                  device: '',
                  expandType: ExpandType.CHILDREN,
                });
            } else {
              if (timeIndex === 0)
                newArr.push({
                  ...deviceInfo,
                  recentActive: datetime,
                  expandType: ExpandType.CLOSE,
                });
            }
          });
        }
      );
      setConvertDeviceList(newArr);
    };

    regenerateDeviceList();
  }, [openDevices, userDeviceData]);

  const handleExpandRecord = (currentType: ExpandType, selectedId: number) => {
    let newArr = [...openDevices];
    if (currentType === ExpandType.CLOSE) {
      newArr.push(selectedId);
      setOpenDevices(newArr);
    } else {
      setOpenDevices(newArr.filter((deviceId) => deviceId !== selectedId));
    }
  };

  const handleGenerateExpandIcon = (expandType: ExpandType, id: number) => {
    switch (expandType) {
      case ExpandType.CLOSE:
        return (
          <img
            src={ArrowDown}
            alt="arrow"
            className="action-btn"
            onClick={() => handleExpandRecord(expandType, id)}
          />
        );
      case ExpandType.OPEN:
        return (
          <img
            src={ArrowUp}
            alt="arrow"
            className="action-btn"
            onClick={() => handleExpandRecord(expandType, id)}
          />
        );
      default:
        return null;
    }
  };

  const handleDeleteDevice = () => {
    if (curAddress && removeDevice) {
      setUserLoading(true);
      dispatch(
        handleDeleteUserDevice(
          removeDevice,
          () => {
            toast.success('Deleted successfully');
            const totalItem = userAddressData?.metadata?.total || 0;
            const currentItemAmount = totalItem - pageSize * (curPage - 1);
            if (currentItemAmount === 1 && curPage !== 1) {
              setCurPage(curPage - 1);
            }
            dispatch(
              fetchUserAddressList({
                page: curPage,
                limit: pageSize,
                search: searchText,
              })
            );
            fetchDeviceFirstTime();
            setUserLoading(false);
          },
          () => {
            toast.error('Deleted failed');
            setUserLoading(false);
          }
        )
      );

      setOpenRemoveModal(false);
    } else toast.error('Deleted failed');
  };

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    setCurPage(1);
    setUserLoading(true);
    dispatch(
      fetchUserAddressList(
        {
          page: curPage,
          limit: pageSize,
          search: value,
        },
        () => setUserLoading(false)
      )
    );
  };

  const debounceHandler = useMemo(
    () => debounce(handleSearchChange, DEBOUNCE_SEARCH_TIME),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const coinColumns: ColumnsType<DeviceItem> = [
    {
      title: 'Device',
      render: (record: DeviceItem) => <span>{record?.device}</span>,
    },
    {
      title: 'Recent Activity',
      render: (record: DeviceItem) => (
        <span className="text-nowrap">{record?.recentActive}</span>
      ),
    },
    {
      title: 'IP Address',
      render: (record: DeviceItem) => (
        <span className="text-nowrap">{record?.ip}</span>
      ),
    },
    {
      align: 'right',
      render: (text: string, record: DeviceItem, index: number) => (
        <Space size={15}>
          {handleGenerateExpandIcon(record?.expandType, record?.id || 0)}
          {record?.expandType !== ExpandType.CHILDREN && (
            <img
              src={RemoveIcon}
              alt="remove"
              className="action-btn"
              onClick={() => {
                setRemoveDevice(record?.id);
                setOpenRemoveModal(true);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const userColumns: ColumnsType<any> = [
    {
      title: 'User',
      render: (record: any) => (
        <FracTooltip title={record?.walletAddress}>
          <span>{ellipseAddress(record?.walletAddress || '', 6, 4)}</span>
        </FracTooltip>
      ),
    },
    {
      align: 'right',
      width: 75,
      render: (record: any) => (
        <ButtonComponent
          text="View"
          variant="default"
          customClassName="view-btn"
          onClick={() => setCurAddress(record?.walletAddress)}
        />
      ),
    },
  ];

  return (
    <div className="device-management">
      <h1 className="common-title">Device management</h1>
      <div className="search-input">
        <FracInput
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            debounceHandler(e);
          }}
          placeholder="Search by wallet address"
          prefix={<img src={SearchIcon} alt="search" />}
        />
      </div>
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <div className="common-wrapper">
            <FracTable
              showTotal
              className="device-table"
              totalText="Total Users"
              columns={userColumns}
              isLoading={userLoading}
              dataSource={userAddressData?.data || []}
              pagination={{
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                pageSize: pageSize,
                showSizeChanger: true,
                total: userAddressData?.metadata?.total,
                current: curPage,
                onShowSizeChange(current, size) {
                  setPageSize(size);
                },
                onChange(page, pageSize) {
                  setCurPage(page);
                },
              }}
            />
          </div>
        </Col>
        <Col span={16}>
          <div className="common-wrapper">
            <FracTable
              columns={coinColumns}
              isLoading={deviceLoading}
              dataSource={convertDeviceList}
            />
          </div>
        </Col>
      </Row>
      <ModalConfirm
        visible={openRemoveModal}
        description="Do you want to delete selected device?"
        cancelText="No"
        submitText="Yes"
        onCancel={() => setOpenRemoveModal(false)}
        onSubmit={handleDeleteDevice}
      />
    </div>
  );
};

export default DeviceManagement;
