import { Divider, Form, Space } from 'antd';
import { memo, useEffect, useState } from 'react';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import PlusIcon from 'src/assets/icons/common/plus-icon.svg';
import RemoveIcon from 'src/assets/icons/common/remove-icon.svg';
import { useForm } from 'antd/es/form/Form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ModalConfirm from 'src/components/06.modals/ModalConfirm';
import CustomFormItemInput from 'src/components/12.inputs/FormItemInput';
import FracLoading from 'src/components/16.loading';
import { cleanNumber, leverageRule, maxTierRule } from 'src/constants/rule';
import { findNextElementArr } from 'src/helpers';
import { formatRoundFloorDisplay } from 'src/helpers/formatNumber';
import { InstrumentParams } from 'src/services/params-type/instrument';
import {
  fetchTradingTierInfo,
  handleCreateNewInstrument,
  handleUpdateInstrument,
} from 'src/store/actions/instrument';
import './styles.scss';

const formatNumber = (value: string) => {
  if (!value) return undefined;
  return formatRoundFloorDisplay(value).replace(/[^0-9.]/g, '');
};
interface IProps {
  isEditInstrument: boolean;
  instrumentSubmitData: InstrumentParams;
  setIsViewInstrument: React.Dispatch<React.SetStateAction<boolean>>;
}

const TierForm: React.FC<IProps> = ({
  isEditInstrument,
  setIsViewInstrument,
  instrumentSubmitData,
}) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [openComfirmModal, setOpenComfirmModal] = useState(false);
  const [inputStorage, setInputStorage] = useState<number[]>([0]);

  const displayNumber = (value: any) => {
    return formatNumber(value)?.replaceAll(',', '');
  };

  useEffect(() => {
    const maxLeverageInstrument = instrumentSubmitData?.tradingRules?.maxLeverage;
    if (!isEditInstrument) {
      form.resetFields();
      form.setFieldValue('min-0', '0');
      form.setFieldValue('maxLeverage-0', maxLeverageInstrument);
    } else {
      const symbolValue = instrumentSubmitData?.instrument?.symbol || '';
      dispatch(
        fetchTradingTierInfo(symbolValue, (tierData: any) => {
          let newArr: number[] = [];
          (tierData || []).forEach((tierInfo: any, index: number) => {
            newArr.push(index);
          });
          setInputStorage(newArr);
          (tierData || []).forEach((tierInfo: any, index: number) => {
            form.setFieldValue(`min-${index}`, displayNumber(tierInfo.min));
            form.setFieldValue(`max-${index}`, displayNumber(tierInfo.max));
            form.setFieldValue(`maxLeverage-${index}`, displayNumber(tierInfo.maxLeverage));
          });
        }),
      );
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditInstrument, instrumentSubmitData]);

  useEffect(() => {
    resetMinEachTier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputStorage]);

  const addNewTier = () => {
    const potition = inputStorage.length - 1;
    const newArr = [...inputStorage];
    newArr.push(inputStorage[potition] + 1);
    setInputStorage(newArr);
  };

  const resetMinEachTier = () => {
    inputStorage.forEach((item, index) => {
      // validate max value
      // const currentTierMaxLeverage = form.getFieldValue(`maxLeverage-${item}`);
      // const maxLeverageInstrument =
      //   instrumentSubmitData?.tradingRules?.maxLeverage;
      // if (
      //   cleanNumber(currentTierMaxLeverage) >=
      //   cleanNumber(maxLeverageInstrument)
      // ) {
      //   form.setFieldValue(`maxLeverage-${item}`, maxLeverageInstrument);
      // }

      // validate min value
      if (index === 0) form.setFieldValue(`min-${item}`, '0');
      const currentTierMaxValue = form.getFieldValue(`max-${item}`);
      const nextElement = findNextElementArr(inputStorage, item);
      form.setFieldValue(`min-${nextElement}`, currentTierMaxValue);
    });
  };

  const onSubmit = () => {
    resetMinEachTier();

    //check leverage last tier with max leverage
    // const maxLeverageInstrument = cleanNumber(
    //   instrumentSubmitData?.tradingRules?.maxLeverage
    // );
    // const lastElementPos = inputStorage.length - 1;
    // const levarageLastTier = cleanNumber(
    //   form.getFieldValue(`maxLeverage-${inputStorage[lastElementPos]}`)
    // );
    // if (levarageLastTier !== maxLeverageInstrument)
    //   form.setFieldValue(
    //     `maxLeverage-${inputStorage[lastElementPos]}`,
    //     maxLeverageInstrument
    //   );

    const submitTierData = inputStorage.map((item) => {
      const min = form.getFieldValue(`min-${item}`);
      const max = form.getFieldValue(`max-${item}`);
      const maxLeverage = form.getFieldValue(`maxLeverage-${item}`);
      return {
        min: cleanNumber(min),
        max: cleanNumber(max),
        maxLeverage: cleanNumber(maxLeverage),
        maintenanceMarginRate: cleanNumber(
          instrumentSubmitData?.instrument?.maintenanceMarginRate || 0,
        ),
      };
    });

    const submitData: InstrumentParams = {
      ...instrumentSubmitData,
      leverageMargins: submitTierData,
    };
    console.log(submitData);

    if (isEditInstrument) {
      dispatch(
        handleUpdateInstrument(
          {
            id: instrumentSubmitData?.id || 0,
            name:
              instrumentSubmitData?.instrument?.newSymbol || instrumentSubmitData?.instrument?.name,
          },
          () => {
            toast.success('Edit instrument successfully');
            setIsViewInstrument(true);
          },
          () => toast.error('Edit instrument failed'),
        ),
      );
    } else {
      dispatch(
        handleCreateNewInstrument(
          submitData,
          () => {
            toast.success('Create instrument successfully');
            setIsViewInstrument(true);
          },
          () => toast.error('Create instrument failed'),
        ),
      );
    }
  };

  if (isLoading) return <FracLoading />;

  return (
    <Form form={form} className="tier-form" onFinish={onSubmit}>
      <div className="tier-form-input-wrapper">
        <div className="flex-0">
          <div className="tier-form-wrapper">
            <div className="title">No</div>
            {inputStorage.map((item, index) => (
              <div className="tier-form-input fixed-height">{index + 1}</div>
            ))}
          </div>
        </div>

        <div className="flex-2">
          <div className="tier-form-wrapper">
            <div className="title">Position Branket</div>
            {inputStorage.map((item) => (
              <div className="tier-form-input">
                <div className="branket-wrapper">
                  <CustomFormItemInput
                    form={form}
                    name={`min-${item}`}
                    // name={TIER_FIELD.MIN}
                    // rules={requiredRule()}
                    inputProps={{
                      defaultValue: '0',
                      disabled: true,
                    }}
                  />
                  <span>-</span>
                  <CustomFormItemInput
                    form={form}
                    name={`max-${item}`}
                    // name={TIER_FIELD.MAX}
                    regex={/^(?:0|[1-9]\d{0,7})(?:\.\d{0,2})?$/}
                    rules={maxTierRule(inputStorage, item)}
                    inputProps={{
                      placeholder: 'Enter max',
                      disabled: isEditInstrument,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex-1">
          <div className="tier-form-wrapper">
            <div className="title">Leverage</div>
            {inputStorage.map((item, index) => (
              <div className="tier-form-input">
                <CustomFormItemInput
                  form={form}
                  name={`maxLeverage-${item}`}
                  // name={TIER_FIELD.MAX_LEVERAGE}
                  regex={/^\d+$/}
                  rules={leverageRule(inputStorage, item, index)}
                  inputProps={{
                    placeholder: 'Enter input',
                    disabled: index === 0 || isEditInstrument,
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        {!isEditInstrument && (
          <div className="flex-0">
            <div className="tier-form-wrapper">
              <div className="title">
                <br />
              </div>
              {inputStorage.map((item) => (
                <div className="tier-form-input">
                  {item !== 0 ? (
                    <img
                      src={RemoveIcon}
                      alt="remove"
                      className="remove-btn"
                      onClick={() => {
                        const newArr = inputStorage.filter((number) => number !== item);
                        setInputStorage(newArr);
                        form.setFieldValue(`max-${item}`, '');
                        form.setFieldValue(`maxLeverage-${item}`, '');
                      }}
                    />
                  ) : (
                    <div className="remove-btn"></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* <div className="flex-1">
          <div className="tier-form-wrapper">
            <div className="title">Maintenance margin rate</div>
            <div className="tier-form-input">
              <CustomFormItemInput
                form={form}
                name={TIER_FIELD.MAINTENANCE_MARGIN_RATE}
                regex={DECIMAL_REGEX}
                rules={maintenanceMarginRateRule()}
                inputProps={{ placeholder: 'Enter input' }}
              />
            </div>
          </div>
        </div> */}

        {/* <div className="flex-1">
          <div className="tier-form-wrapper">
            <div className="title">Maintenance amount</div>
            <div className="tier-form-input">
              <CustomFormItemInput
                form={form}
                name={TIER_FIELD.MAINTENANCE_AMOUNT}
                rules={requiredRule()}
                inputProps={{
                  placeholder: 'Enter input',
                  defaultValue: '0',
                  disabled: true,
                }}
              />
            </div>
          </div>
        </div> */}
      </div>

      <div className="action-btn">
        {!isEditInstrument && (
          <ButtonComponent
            customClassName="add-btn"
            text="Add new Tier"
            variant="primary"
            disabled={inputStorage.length === 5}
            prefixIcon={<img src={PlusIcon} alt="plus" />}
            onClick={addNewTier}
          />
        )}
        <div className="action-btn">
          <Divider />
          <div className="group-btn">
            <Space size={8}>
              <ButtonComponent
                text="Save"
                variant="primary"
                onClick={() => {
                  if (isEditInstrument) setOpenComfirmModal(true);
                  else
                    form.validateFields().then(() => {
                      setOpenComfirmModal(true);
                    });
                }}
              />
              <ButtonComponent
                text="Reset"
                variant="secondary"
                disabled={isEditInstrument}
                onClick={() => {
                  setInputStorage([0]);
                  form.resetFields();
                }}
              />
            </Space>
          </div>
        </div>
      </div>

      <ModalConfirm
        visible={openComfirmModal}
        title="Save Contract"
        description="By confirming, this contract and all the information will be saved."
        onCancel={() => setOpenComfirmModal(false)}
        onSubmit={() => {
          if (isEditInstrument) onSubmit();
          else form.submit();
          setOpenComfirmModal(false);
        }}
      />
    </Form>
  );
};

export default memo(TierForm);
