import { ServiceBase } from "./core/service-base";
import { Authentication } from "./implements/implement-apis";

export class AuthServices extends ServiceBase implements Authentication {
  // Implement method call API
  login = async (params: any) => {
    const { walletAddress, signature } = params;
    return this.post("/admin/auth/login", {
      walletAddress: walletAddress,
      signature: signature,
    });
  };
  refreshToken = async (params: { refreshToken: string }) => {
    const { refreshToken } = params;
    return this.post("/admin/auth/refresh-token", {
      refreshToken,
    });
  };
  profile = () => {
    return this.post("/admin/auth/profile", {});
  };
}
