import { Form } from 'antd';
import { FracInput } from 'src/components/12.inputs';
import FracDatePicker from 'src/components/20.datePicker';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { FormInstance } from 'antd/es/form';
import SeachIcon from 'src/assets/icons/common/search-icon.svg';
import { handleDisableEndDate, handleDisableStartDate } from 'src/helpers';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'src/constants/formatters';
import { useEffect, useState } from 'react';
import './styles.scss';
import SearchSymbolInput from './search-symbol-input';

export type CommonFilterParams = {
  startTime?: any;
  endTime?: any;
  searchText?: string;
};

export const DEFAULT_FILTER_PARAMS = {
  startTime: dayjs().subtract(7, 'day'),
  endTime: dayjs(),
  searchText: '',
};

interface IProps {
  isLoading?: boolean;
  searchBy?: 'symbol' | 'walletAddress';
  form: FormInstance<any>;
  placeholder?: string;
  filterParams?: CommonFilterParams;
  setFilterParams?: React.Dispatch<React.SetStateAction<CommonFilterParams>>;
}

const CommonFilter: React.FC<IProps> = ({
  form,
  filterParams,
  setFilterParams,
  isLoading = false,
  searchBy = 'walletAddress',
  placeholder = 'Search by wallet address',
}) => {
  const [searchText, setSearchText] = useState('');
  const [showError, setShowError] = useState(false);

  const onSubmit = (data: CommonFilterParams) => {
    setFilterParams?.(data);
  };

  useEffect(() => {
    form?.setFieldsValue(filterParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  return (
    <Form
      layout="vertical"
      form={form}
      className="common-filter"
      onFinish={onSubmit}
    >
      <Form.Item label="From" name="startTime">
        <FracDatePicker
          format={DATE_FORMAT}
          disabledDate={(curDate: any) =>
            handleDisableStartDate(curDate, form, 'endTime')
          }
        />
      </Form.Item>
      <Form.Item label="To" name="endTime">
        <FracDatePicker
          format={DATE_FORMAT}
          disabledDate={(curDate: any) =>
            handleDisableEndDate(curDate, form, 'startTime')
          }
        />
      </Form.Item>
      {searchBy === 'walletAddress' ? (
        <Form.Item name="searchText">
          <FracInput
            placeholder={placeholder}
            prefix={<img src={SeachIcon} alt="search" />}
          />
        </Form.Item>
      ) : (
        <>
          <Form.Item name="searchText" className="no-display" />
          <SearchSymbolInput
            showError={showError}
            setShowError={setShowError}
            searchText={searchText}
            setSearchText={setSearchText}
            placeholder={placeholder}
            onSelectItem={(symbol) => form.setFieldValue('searchText', symbol)}
          />
        </>
      )}

      <ButtonComponent
        text="Search"
        variant="primary"
        loading={isLoading}
        customClassName="common-button"
        onClick={() => form?.submit()}
      />
      <ButtonComponent
        text="Reset"
        variant="default"
        customClassName="common-button"
        onClick={() => {
          setSearchText('');
          setShowError(false);
          form?.setFieldsValue(DEFAULT_FILTER_PARAMS);
          setFilterParams?.(DEFAULT_FILTER_PARAMS);
        }}
      />
    </Form>
  );
};

export default CommonFilter;
