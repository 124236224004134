import { ServiceBase } from './core/service-base';
import {
  RestrictionInfo,
  GetUserInfoListParams,
  UpdateUserInfoParams,
} from './params-type/user-management-type';

export class UserManagementService extends ServiceBase {
  getUserInfoList = (params: GetUserInfoListParams) => {
    return this.get('admin/users', params);
  };
  updateUserInfo = (params: UpdateUserInfoParams) => {
    return this.post('/admin/users/' + params?.id, params);
  };

  getUserAddressList = (params: GetUserInfoListParams) => {
    return this.get('admin/users/user-devices', params);
  };
  getUserDeviceList = (userAddress: string) => {
    return this.get('admin/users/devices/' + userAddress);
  };
  deleteDevice = (id: number) => {
    return this.delete('admin/users/devices', id);
  };

  getRestrictionList = () => {
    return this.get('restriction/all');
  };
  addRestriction = (body: RestrictionInfo) => {
    return this.post('restriction', body);
  };
  deleteRestriction = (params: RestrictionInfo) => {
    return this.deleteByUrlWithParams('restriction', params);
  };
}
