import { memo, useEffect, useState } from "react";
import FracTable from "src/components/05.table";
import { ColumnsType } from "antd/es/table";

import RemoveIcon from "src/assets/icons/common/remove-icon.svg";
import EditIcon from "src/assets/icons/common/edit-outline-icon.svg";
import PlusIcon from "src/assets/icons/common/plus-icon.svg";
import AcceptIcon from "src/assets/icons/common/accept-icon.svg";
import FacebookIcon from "src/assets/icons/setting/icon-facebook.svg";
import GoogleIcon from "src/assets/icons/setting/icon-google.svg";
import InstagramIcon from "src/assets/icons/setting/icon-instagram.svg";
import RedditIcon from "src/assets/icons/setting/icon-reddit.svg";
import TelegramIcon from "src/assets/icons/setting/icon-telegram.svg";
import TwitterIcon from "src/assets/icons/setting/icon-twitter.svg";
import WechatIcon from "src/assets/icons/setting/icon-wechat.svg";
import WhatsappIcon from "src/assets/icons/setting/icon-whatsapp.svg";
import YoutubeIcon from "src/assets/icons/setting/icon-youtube.svg";
import LinkedinIcon from "src/assets/icons/setting/linkedin-ic.svg";

import { Form, Space } from "antd";
import ModalConfirm from "src/components/06.modals/ModalConfirm";
import ButtonComponent from "src/components/02.buttons/ButtonComponent";
import { SocicalSettingParams } from "src/services/params-type/site-setting-type";
import CustomFormItemInput from "src/components/12.inputs/FormItemInput";
import FracSelect from "src/components/11.select";
import { SOCIAL_ICON, SOCIAL_TYPE } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSocialSetting,
  handleCreateNewSocial,
  handleDeleteSocial,
  handleUpdateSocial,
  setSocialSetting,
} from "src/store/actions/site-setting";
import { toast } from "react-toastify";
import OverflowTooltip from "src/components/17.overflow-tooltip";
import "./styles.scss";

const iconSrc = {
  [SOCIAL_TYPE.FACEBOOK]: FacebookIcon,
  [SOCIAL_TYPE.GOOGLE_PLUS]: GoogleIcon,
  [SOCIAL_TYPE.INSTAGRAM]: InstagramIcon,
  [SOCIAL_TYPE.LINKEDIN]: LinkedinIcon,
  [SOCIAL_TYPE.REDDIT]: RedditIcon,
  [SOCIAL_TYPE.TELEGRAM]: TelegramIcon,
  [SOCIAL_TYPE.TWITTER]: TwitterIcon,
  [SOCIAL_TYPE.WECHAT]: WechatIcon,
  [SOCIAL_TYPE.WHATSAPP]: WhatsappIcon,
  [SOCIAL_TYPE.YOUTUBE]: YoutubeIcon,
};

const iconName = {
  [SOCIAL_TYPE.FACEBOOK]: SOCIAL_ICON.FACEBOOK,
  [SOCIAL_TYPE.GOOGLE_PLUS]: SOCIAL_ICON.GOOGLE_PLUS,
  [SOCIAL_TYPE.INSTAGRAM]: SOCIAL_ICON.INSTAGRAM,
  [SOCIAL_TYPE.LINKEDIN]: SOCIAL_ICON.LINKEDIN,
  [SOCIAL_TYPE.REDDIT]: SOCIAL_ICON.REDDIT,
  [SOCIAL_TYPE.TELEGRAM]: SOCIAL_ICON.TELEGRAM,
  [SOCIAL_TYPE.TWITTER]: SOCIAL_ICON.TWITTER,
  [SOCIAL_TYPE.WECHAT]: SOCIAL_ICON.WECHAT,
  [SOCIAL_TYPE.WHATSAPP]: SOCIAL_ICON.WHATSAPP,
  [SOCIAL_TYPE.YOUTUBE]: SOCIAL_ICON.YOUTUBE,
};

const SocialSetting = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [editRecord, setEditRecord] = useState<number | undefined>();
  const [deleteRecord, setDeleteRecord] = useState<number | undefined>();
  const [openComfirmModal, setOpenComfirmModal] = useState(false);
  const [currentEditType, setCurrentEditType] = useState<SOCIAL_TYPE>(
    SOCIAL_TYPE.FACEBOOK
  );

  const socialSetting = useSelector(
    (state: any) => state.siteSettings.socialSetting
  );

  const listSocials = (socialSetting || []).map((item: any) => item.type);

  const type = Object.values(SOCIAL_TYPE).filter(
    (item) => !listSocials.includes(item)
  )[0];

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchSocialSetting(() => setIsLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: SocicalSettingParams) => {
    setIsLoading(true);
    if (editRecord === 0)
      dispatch(
        handleCreateNewSocial(
          data,
          () => toast.success("Added Social network successfully"),
          () => toast.error("Added Social network failed")
        )
      );
    else
      dispatch(
        handleUpdateSocial(
          { id: editRecord, ...data },
          () => toast.success("Updated successfully"),
          () => toast.error("Updated failed")
        )
      );
    setIsLoading(false);
    setEditRecord(undefined);
  };

  const handleRemoveSocial = () => {
    dispatch(
      handleDeleteSocial(
        deleteRecord || 0,
        () => toast.success("Deleted successfully"),
        () => toast.error("Deleted failed")
      )
    );
    setDeleteRecord(undefined);
    setOpenComfirmModal(false);
  };

  const columns: ColumnsType<SocicalSettingParams> = [
    {
      title: "Name",
      render: (record: SocicalSettingParams) => {
        if (record?.id !== editRecord)
          return <span className="w-180">{record?.name}</span>;
        else
          return (
            <CustomFormItemInput
              form={form}
              name="name"
              className="w-180"
              regex={/^.{0,15}$/}
              inputProps={{ placeholder: "Enter Name" }}
            />
          );
      },
    },
    {
      title: "Icon",
      render: (record: SocicalSettingParams) => {
        if (record?.id !== editRecord)
          return (
            <Space size={2}>
              <img
                src={iconSrc[record?.type as SOCIAL_TYPE] || FacebookIcon}
                alt="icon"
              />
              <span className="w-180">
                {iconName[record?.type] || SOCIAL_ICON.FACEBOOK}
              </span>
            </Space>
          );
        else {
          return (
            <Space size={2}>
              <img
                src={iconSrc[currentEditType as SOCIAL_TYPE] || FacebookIcon}
                alt="icon"
              />
              <span className="w-180">
                {iconName[currentEditType as SOCIAL_TYPE] ||
                  SOCIAL_ICON.FACEBOOK}
              </span>
            </Space>
          );
        }
      },
    },
    {
      title: "Type",
      render: (record: SocicalSettingParams) => {
        const listSocials = (socialSetting || []).map((item: any) => item.type);
        const type = Object.values(SOCIAL_TYPE).filter(
          (item) => !listSocials.includes(item)
        )[0];
        if (record?.id !== editRecord)
          return (
            <span className="text-capitalize w-140">
              {(record?.type || "").toLowerCase()}
            </span>
          );
        else
          return (
            <Form.Item initialValue={type} name="type">
              <FracSelect
                className="w-140"
                placeholder="Select Type"
                onChange={(value) => {
                  setCurrentEditType(value);
                }}
                options={Object.values(SOCIAL_TYPE)
                  .filter((item) => !listSocials.includes(item))
                  .map((item) => {
                    return {
                      value: item,
                      label: (
                        <span className="text-capitalize">
                          {item.replace("_", " ").toLowerCase()}
                        </span>
                      ),
                    };
                  })}
              />
            </Form.Item>
          );
      },
    },
    {
      title: "Link",
      render: (record: SocicalSettingParams) => {
        if (record?.id !== editRecord)
          return (
            <OverflowTooltip title={record?.link}>
              <span className="w-220 text-overflow">{record?.link}</span>
            </OverflowTooltip>
          );
        else
          return (
            <CustomFormItemInput
              form={form}
              name="link"
              className="w-220"
              inputProps={{ placeholder: "Enter Link" }}
            />
          );
      },
    },
    {
      title: "Status",
      render: (record: SocicalSettingParams) => {
        if (record?.id !== editRecord)
          return (
            <span className="text-capitalize w-120">
              {(record?.status || "").toLowerCase()}
            </span>
          );
        else
          return (
            <Form.Item name="status">
              <FracSelect
                className="w-120"
                placeholder="Status"
                options={[
                  { value: "ENABLE", label: "Enable" },
                  { value: "DISABLE", label: "Disable" },
                ]}
              />
            </Form.Item>
          );
      },
    },
    {
      title: (
        <ButtonComponent
          customClassName="add-row-btn"
          text="Add Row"
          variant="primary"
          disabled={editRecord === 0 || !type}
          prefixIcon={<img src={PlusIcon} alt="plus" />}
          onClick={() => {
            if (type) {
              setCurrentEditType(type);
              dispatch(setSocialSetting([...socialSetting, { id: 0 }]));
              form.resetFields();
              setEditRecord(0);
            }
          }}
        />
      ),
      width: 126,
      align: "right",
      render: (record: SocicalSettingParams) => (
        <Space size={15}>
          {record?.id === editRecord ? (
            <img
              src={AcceptIcon}
              alt="accept"
              className="action-btn"
              onClick={() => {
                if (!isLoading) {
                  form.submit();
                }
              }}
            />
          ) : (
            <img
              src={EditIcon}
              alt="edit"
              className="action-btn"
              onClick={() => {
                setEditRecord(record?.id);
                form.setFieldsValue(record);
              }}
            />
          )}
          <img
            src={RemoveIcon}
            alt="edit"
            className="action-btn"
            onClick={() => {
              if (editRecord !== 0) {
                setDeleteRecord(record?.id || 0);
                setOpenComfirmModal(true);
              } else {
                setEditRecord(undefined);
                dispatch(
                  setSocialSetting(
                    (socialSetting || []).filter(
                      (item: SocicalSettingParams) => item.id !== 0
                    )
                  )
                );
              }
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className="social-setting">
      <div className="title">Social Network</div>
      <Form form={form} onFinish={onSubmit} className="common-wrapper">
        <FracTable
          columns={columns}
          isLoading={isLoading}
          dataSource={socialSetting || []}
        />
      </Form>
      <ModalConfirm
        visible={openComfirmModal}
        title="Confirmation"
        description="Do you want to remove Social network?"
        cancelText="No"
        submitText="Yes"
        onCancel={() => {
          setDeleteRecord(undefined);
          setOpenComfirmModal(false);
        }}
        onSubmit={() => handleRemoveSocial()}
      />
    </div>
  );
};

export default memo(SocialSetting);
