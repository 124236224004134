import IconDownload from 'src/assets/images/icon_download.svg';
import MetamaskIcon from 'src/assets/images/metamask-logo.png';
import FracModal from 'src/components/06.modals';

import { METAMASK_DEEPLINK } from 'src/constants';
import ButtonComponent from '../02.buttons/ButtonComponent';
import './styles/wrong-network.scss';

export default function ModalMetaMaskNotFound({ visible, toggleModal }: { visible: boolean; toggleModal: any }) {
  return (
    <FracModal
      title={'MetaMask not found'}
      visible={visible}
      className='modal--dark not_found_wrap'
      onCancel={toggleModal}
      maskClosable={false}
      width={500}
      centered
    >
      <div className='metamask-not-found-modal'>
        <div className='metamask-not-found-modal__img'>
          <img src={MetamaskIcon} width={100} alt='metamask-icon' />
        </div>
        <div
          className='metamask-not-found-modal__description'
          dangerouslySetInnerHTML={{
            __html: 'It’s seem that you have not installed Metamask wallet.<br>Please install now',
          }}
        ></div>
        <ButtonComponent
          variant='primary'
          text={
            <a className='metamask-not-found-modal__link' target='_blank' href={METAMASK_DEEPLINK} rel='noreferrer'>
              <img src={IconDownload} alt='download-icon' />
              <span>{'Install MetaMask'}</span>
            </a>
          }
        />
      </div>
    </FracModal>
  );
}
