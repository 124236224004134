import { STORAGE_KEY } from 'src/constants';

const accessToken = STORAGE_KEY.ACCESS_TOKEN;
const refreshToken = STORAGE_KEY.REFRESH_TOKEN;
export const logKey = 'log';

export const userAddress = 'user_address';

export function hasStorageJwtToken() {
  return !!localStorage.getItem(accessToken);
}

export function setStorageLogKey() {
  localStorage.setItem(logKey, 'true');
}

export function getStorageJwtToken() {
  return localStorage.getItem(accessToken);
}

export function setStorageJwtToken(token: string) {
  localStorage.setItem(accessToken, token);
}

export function setStorageJwtRefreshToken(token: string) {
  localStorage.setItem(refreshToken, token);
}

export function removeStorageJwtToken() {
  window.localStorage.removeItem(accessToken);
  window.localStorage.removeItem(refreshToken);
  window.localStorage.removeItem(userAddress);
}
