import FracTable from 'src/components/05.table';
import { ColumnsType } from 'antd/es/table';
import CommonFilter, {
  CommonFilterParams,
  DEFAULT_FILTER_PARAMS,
} from 'src/components/21.common-filter';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchTransactionHistory } from 'src/store/actions/transaction-history';
import { PAGE_SIZE_OPTIONS, TRANSACTION_TYPE } from 'src/constants';
import { ellipseAddress } from 'src/helpers';
import dayjs from 'dayjs';
import { FULL_DATETIME_FORMAT } from 'src/constants/formatters';
import { formatRoundFloorDisplay } from 'src/helpers/formatNumber';
import FracTooltip from 'src/components/19.tooltip';
import './styles.scss';
import { TransactionInfo } from 'src/services/params-type/transaction-history';

const WithdrawHistory = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [filterParams, setFilterParams] = useState<CommonFilterParams>(
    DEFAULT_FILTER_PARAMS
  );

  const transactionData = useSelector(
    (state: any) => state.transactions.transactionData
  );

  useEffect(() => {
    setCurPage(1);
  }, [filterParams, pageSize]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchTransactionHistory(
        {
          page: curPage,
          limit: pageSize,
          orderBy: 'created_at',
          direction: 'DESC',
          type: TRANSACTION_TYPE.WITHDRAW,
          startTime: (
            dayjs(filterParams.startTime).startOf('day').unix() * 1000
          ).toString(),
          endTime: (
            dayjs(filterParams.endTime).endOf('day').unix() * 1000
          ).toString(),
          walletAddress: filterParams.searchText?.length
            ? filterParams.searchText
            : undefined,
        },
        () => setIsLoading(false)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, pageSize, curPage]);

  const columns: ColumnsType<TransactionInfo> = [
    {
      title: 'Time',
      render: (record: TransactionInfo) => (
        <span className="text-nowrap">
          {dayjs(record.created_at).format(FULL_DATETIME_FORMAT)}
        </span>
      ),
    },
    {
      title: 'Wallet Address',
      render: (record: TransactionInfo) => (
        <FracTooltip title={record.wallet_address}>
          <span>{ellipseAddress(record.wallet_address || '', 6, 4)}</span>
        </FracTooltip>
      ),
    },
    {
      title: 'Transaction ID',
      render: (record: TransactionInfo) => (
        <span>{record.transaction_hash}</span>
      ),
    },
    {
      title: 'Currency',
      render: (record: TransactionInfo) => <span>{record.asset}</span>,
    },
    {
      title: 'Amount',
      render: (record: TransactionInfo) => (
        <span>{formatRoundFloorDisplay(record.amount)}</span>
      ),
    },
    {
      title: 'Status',
      render: (record: TransactionInfo) => {
        if (!record.status) return null;
        return (
          <>
            {record.status === 'APPROVED' ? (
              <span className="green-text">Success</span>
            ) : (
              <span className="red-text">Fail</span>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="withdraw-history">
      <h1 className="common-title">Withdraw history</h1>
      <CommonFilter
        form={form}
        isLoading={isLoading}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
      <div className="common-wrapper">
        <FracTable
          showTotal
          columns={columns}
          isLoading={isLoading}
          totalText="Total Transactions"
          dataSource={transactionData?.data || []}
          pagination={{
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            pageSize: pageSize,
            showSizeChanger: true,
            total: transactionData?.metadata?.total,
            current: curPage,
            onShowSizeChange(current, size) {
              setPageSize(size);
            },
            onChange(page, pageSize) {
              setCurPage(page);
            },
          }}
        />
      </div>
    </div>
  );
};

export default WithdrawHistory;
