import { ColumnsType } from 'antd/es/table';
import { memo, useState } from 'react';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracTable from 'src/components/05.table';
import PlusIcon from 'src/assets/icons/common/plus-icon.svg';
import RemoveIcon from 'src/assets/icons/common/remove-icon.svg';
import ModalConfirm from 'src/components/06.modals/ModalConfirm';
import FracModal from 'src/components/06.modals';
import { Form } from 'antd';
import CustomFormItemInput from 'src/components/12.inputs/FormItemInput';
import { requiredRule } from 'src/constants/rule';
import { RestrictionInfo } from 'src/services/params-type/user-management-type';
import { useDispatch } from 'react-redux';
import {
  handleCreateRestriction,
  handleDeleteRestriction,
} from 'src/store/actions/user-management';
import { toast } from 'react-toastify';

interface IProps {
  isLoading: boolean;
  data: RestrictionInfo[];
}

const RestrictionByIP: React.FC<IProps> = ({ data, isLoading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<RestrictionInfo>({
    ipOrLocation: '',
    type: 0,
  });
  const [openAddModal, setOpenAddModal] = useState(false);

  const closeAddModal = () => {
    setOpenAddModal(false);
    form.resetFields();
  };

  const handleDeleteIP = () => {
    const onSuccess = () => {
      toast.success('Deleted restriction successfully');
      setOpenRemoveModal(false);
    };

    const onFailure = () => {
      toast.error('Deleted restriction failed');
      setOpenRemoveModal(false);
    };

    dispatch(handleDeleteRestriction(selectedRecord, onSuccess, onFailure));
  };

  const handleAddRestrictionIP = (data: RestrictionInfo) => {
    const submitData = {
      ...data,
      type: 0,
    };

    const onSuccess = () => {
      toast.success('Added restriction successfully');
    };

    const onFailure = (err: any) => {
      if (err?.response?.data?.code === 'RESTRICTION_00003')
        toast.error('IP is invalid');
      else if (err?.response?.data?.code === 'RESTRICTION_00001')
        toast.error('This IP address is already restricted');
      else toast.error('Added restriction failed');
    };

    dispatch(handleCreateRestriction(submitData, onSuccess, onFailure));

    closeAddModal();
  };

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      render: (record: RestrictionInfo, text: string, index: number) => (
        <span>{index + 1}</span>
      ),
    },
    {
      title: 'IP address',
      render: (record: RestrictionInfo) => <span>{record?.ipOrLocation}</span>,
    },
    {
      title: 'Reasoning',
      render: (record: RestrictionInfo) => <span>{record?.reason}</span>,
    },
    {
      title: (
        <ButtonComponent
          customClassName="add-row-btn"
          text="Add"
          variant="primary"
          prefixIcon={<img src={PlusIcon} alt="plus" />}
          onClick={() => setOpenAddModal(true)}
        />
      ),
      width: 82,
      align: 'right',
      render: (record: RestrictionInfo) => (
        <img
          src={RemoveIcon}
          alt="remove"
          className="action-btn"
          onClick={() => {
            setSelectedRecord(record);
            setOpenRemoveModal(true);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <h2 className="common-subtitle">Restriction by IP</h2>
      <div className="common-wrapper">
        <FracTable
          columns={columns}
          isLoading={isLoading}
          dataSource={data || []}
        />
      </div>
      <ModalConfirm
        visible={openRemoveModal}
        description="Do you want to delete selected IP?"
        cancelText="No"
        submitText="Yes"
        onCancel={() => setOpenRemoveModal(false)}
        onSubmit={handleDeleteIP}
      />
      <FracModal
        visible={openAddModal}
        wrapClassName="add-restriction-modal"
        onCancel={closeAddModal}
        //   iconClose={IconClose}
        width={448}
        maskClosable={true}
        centered
      >
        <div className="add-modal-wrapper">
          <Form
            form={form}
            layout="vertical"
            className="input-group"
            onFinish={handleAddRestrictionIP}
          >
            <CustomFormItemInput
              form={form}
              label="IP address"
              rules={requiredRule()}
              name="ipOrLocation"
              inputProps={{ placeholder: 'Please input IP address' }}
            />
            <CustomFormItemInput
              form={form}
              label="Restriction reasoning"
              rules={requiredRule()}
              name="reason"
              regex={/^.{0,255}$/}
              inputProps={{
                placeholder: 'Please input reason for restriction',
              }}
            />
          </Form>
          <div className="button-group">
            <ButtonComponent
              text="Cancel"
              variant="default"
              customClassName="cancel-btn"
              onClick={closeAddModal}
            />
            <ButtonComponent
              text="Save"
              variant="primary"
              onClick={() => form.submit()}
            />
          </div>
        </div>
      </FracModal>
    </>
  );
};

export default memo(RestrictionByIP);
