import React, { useEffect, useState } from 'react';
import { Divider, Form, FormInstance, SelectProps } from 'antd';
import FracSelect from 'src/components/11.select';
import { FracInput } from 'src/components/12.inputs';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoinList } from 'src/store/actions/instrument';
import { requiredRule } from 'src/constants/rule';
import './styles.scss';

interface IProps extends SelectProps {
  form?: FormInstance<any>;
}

const UnderlyingSymbolInput: React.FC<IProps> = ({ form, ...props }) => {
  const dispatch = useDispatch();
  const coinList = useSelector((state: any) => state.instrument.coinList);
  const items = coinList?.map((item: any) => {
    return {
      label: (item.symbol + '/USD').toUpperCase(),
      value: (item.symbol + '/USD').toUpperCase(),
    };
  });

  const [options, setOptions] = useState(items);

  useEffect(() => {
    dispatch(
      fetchCoinList('', (data: any) => {
        const mappingOptions = data.map((item: any) => {
          return {
            label: (item.symbol + '/USD').toUpperCase(),
            value: (item.symbol + '/USD').toUpperCase(),
          };
        });
        setOptions(mappingOptions);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (event: any) => {
    const value = event.target.value.toLowerCase();
    const newArr = items.filter((item: any) =>
      item.label.toLowerCase().includes(value)
    );
    setOptions(newArr);
  };

  return (
    <Form.Item
      label="Underlying asset & Quote currency"
      name="underlyingSymbol"
      rules={requiredRule()}
    >
      <FracSelect
        {...props}
        placeholder="Select underlying asset"
        popupClassName="underlying-symbol-input"
        // onChange={(value: string) =>
        //   form?.setFields([
        //     {
        //       name: INSTRUMENT_FIELD.COLLATERAL_ASSET,
        //       value: value,
        //       errors: [],
        //     },
        //   ])
        // }
        options={options}
        notFoundContent={<div className="empty">No result found</div>}
        dropdownRender={(menu) => (
          <>
            <div className="search-input">
              <FracInput
                suffix={<SearchOutlined />}
                placeholder="Search"
                onChange={handleSearchChange}
              />
            </div>
            <Divider className="divider" />
            {menu}
          </>
        )}
      />
    </Form.Item>
  );
};

export default UnderlyingSymbolInput;
