import { Layout, Space } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import IconLogOut from 'src/assets/images/icon_log_out.svg';
import AvatarImage from 'src/assets/icons/common/avatar.svg';
import { ellipseAddress } from 'src/helpers';
import { useActiveWeb3React, useConnectWallet } from 'src/web3/hooks';
import { ThemesMode } from '../PrivateLayout';
import './styles.scss';

const { Header } = Layout;

interface Props {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  theme: ThemesMode;
  setTheme: Dispatch<SetStateAction<ThemesMode>>;
}

const AppHeader: React.FC<Props> = () => {
  const { disconnectWallet } = useConnectWallet();
  const { account } = useActiveWeb3React();

  return (
    <Header className="site-layout-header">
      {account && (
        <>
          <div className="info-section">
            <Space>
              <img src={AvatarImage} alt="avatar" />
              <span>{ellipseAddress(account, 6, 4)}</span>
            </Space>
            <img
              src={IconLogOut}
              className="logout-btn"
              alt="log out"
              onClick={() => disconnectWallet()}
            />
          </div>
        </>
      )}
    </Header>
  );
};

export default React.memo(AppHeader);
