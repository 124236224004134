import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { GetTransactionHistoryParams } from 'src/services/params-type/transaction-history';
import { TracsactionHistoryService } from 'src/services/transaction-history';
import { SET_TRANSACTION_LIST } from '../constants/transaction-history';

export const setTransactionList = (payload: any) => {
  return {
    type: SET_TRANSACTION_LIST,
    payload,
  };
};

export const fetchTransactionHistory = (
  params: GetTransactionHistoryParams,
  callBack?: any
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const transactionServices = new TracsactionHistoryService();
    try {
      const res: any = await transactionServices.getTransactionHistory(params);
      if (res.data)
        dispatch(
          setTransactionList({ data: res?.data, metadata: res?.metadata })
        );
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};
