import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  SET_TIER_INFO,
  SET_INSTRUMENT_LIST,
  SET_SYMBOL_LIST,
  SET_COIN_LIST,
} from '../constants/instrument';
import { InstrumentService } from 'src/services/instrument';
import {
  GetInstrumentListParams,
  InstrumentParams,
} from 'src/services/params-type/instrument';

export const setInstrumentList = (payload: any) => {
  return {
    type: SET_INSTRUMENT_LIST,
    payload,
  };
};

export const setSymbolList = (payload: any) => {
  return {
    type: SET_SYMBOL_LIST,
    payload,
  };
};

export const setCoinList = (payload: any) => {
  return {
    type: SET_COIN_LIST,
    payload,
  };
};

export const setTrangdingTierInfo = (payload: any) => {
  return {
    type: SET_TIER_INFO,
    payload,
  };
};

export const fetchInstrumentList = (
  params: GetInstrumentListParams,
  callBack?: any
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new InstrumentService();
    try {
      const res: any = await services.getInstrumentList(params);
      if (res.data)
        dispatch(
          setInstrumentList({ data: res?.data, metadata: res?.metadata })
        );
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const fetchSymbolList = (symbol: string, callBack?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new InstrumentService();
    try {
      const res: any = await services.getSymbolList(symbol);
      if (res.data) dispatch(setSymbolList(res?.data));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const fetchCoinList = (search: string, callBack?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new InstrumentService();
    try {
      const res: any = await services.getCoinList(search);
      if (res.data) dispatch(setCoinList(res?.data));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const fetchTradingTierInfo = (symbol: string, callBack?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new InstrumentService();
    try {
      const res: any = await services.getTradingTier(symbol);
      if (res.data) dispatch(setTrangdingTierInfo(res?.data));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const handleCreateNewInstrument = (
  params: InstrumentParams,
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new InstrumentService();
    try {
      const res: any = await services.createNewInstrument(params);
      if (res && onSuccess) onSuccess();
    } catch (err: any) {
      if (onError) onError();
    }
  };
};

export const handleUpdateInstrument = (
  params: { id: number; name: string },
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new InstrumentService();
    try {
      const res: any = await services.updateInstrument(params);
      if (res && onSuccess) onSuccess();
    } catch (err: any) {
      if (onError) onError();
    }
  };
};
