import FracTable from 'src/components/05.table';
import { ColumnsType } from 'antd/es/table';
import { Form, Radio, Space } from 'antd';
import OrderFilter, {
  DEFAULT_ORDER_FILTER,
  OrderFilterParams,
} from './order-filter';
import { useEffect, useState } from 'react';
import './styles.scss';
import { ORDER_SIDE, ORDER_TYPE, PAGE_SIZE_OPTIONS } from 'src/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderList } from 'src/store/actions/future-records';
import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants/formatters';
import { formatRoundFloorDisplay } from 'src/helpers/formatNumber';
import FracTooltip from 'src/components/19.tooltip';
import { ellipseAddress } from 'src/helpers';
import { OrderInfo } from 'src/services/params-type/future-records';

const Orders = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveOrder, setIsActiveOrder] = useState(true);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [filterParams, setFilterParams] =
    useState<OrderFilterParams>(DEFAULT_ORDER_FILTER);

  const orderData = useSelector((state: any) => state.futureRecords.orderData);

  useEffect(() => {
    setCurPage(1);
  }, [filterParams, isActiveOrder, pageSize]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchOrderList(
        {
          page: curPage,
          limit: pageSize,
          isActive: isActiveOrder,
          orderBy: 'time',
          direction: 'DESC',
          type:
            filterParams.type === ORDER_TYPE.ALL
              ? undefined
              : filterParams.type,
          side:
            filterParams.side === ORDER_SIDE.ALL
              ? undefined
              : filterParams.side,
          from: (
            dayjs(filterParams.startTime).startOf('day').unix() * 1000
          ).toString(),
          to: (
            dayjs(filterParams.endTime).endOf('day').unix() * 1000
          ).toString(),
          symbol: filterParams.symbol?.length ? filterParams.symbol : undefined,
        },
        () => setIsLoading(false)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, isActiveOrder, pageSize, curPage]);

  const columns: ColumnsType<OrderInfo> = [
    {
      title: 'Time',
      render: (record: OrderInfo) => (
        <Space size={4} direction="vertical">
          <span className="text-nowrap">
            {dayjs(record?.createdAt).format(DATE_FORMAT)}
          </span>
          <span className="text-nowrap gray-text">
            {dayjs(record?.createdAt).format(TIME_FORMAT)}
          </span>
        </Space>
      ),
    },
    {
      title: 'Symbol',
      render: (record: OrderInfo) => (
        <Space size={4} direction="vertical">
          <span className="text-nowrap">{record?.symbol}</span>
          <span className="text-nowrap gray-text">Perpetual</span>
        </Space>
      ),
    },
    {
      title: 'Side',
      render: (record: OrderInfo) => {
        if (!record.side) return null;
        return (
          <>
            {record?.side === ORDER_SIDE.BUY ? (
              <span className="green-text">Buy</span>
            ) : (
              <span className="red-text">Sell</span>
            )}
          </>
        );
      },
    },
    {
      title: 'Type',
      render: (record: OrderInfo) => (
        <span className="text-capitalize">
          {(record?.tpSLType || record?.customType || '').toLowerCase()}
        </span>
      ),
    },
    {
      title: 'Order Quantity',
      render: (record: OrderInfo) => (
        <span>
          {formatRoundFloorDisplay(record?.quantity, 3)} {record?.asset}
        </span>
      ),
    },
    {
      title: 'Price',
      render: (record: OrderInfo) => (
        <span>{formatRoundFloorDisplay(record?.price, 3)}</span>
      ),
    },
    {
      title: 'Leverage',
      render: (record: OrderInfo) => (
        <span>{formatRoundFloorDisplay(record?.leverage, 3)}x</span>
      ),
    },
    {
      title: 'Cost',
      render: (record: OrderInfo) => (
        <span>{formatRoundFloorDisplay(record?.cost, 3)}</span>
      ),
    },
    {
      title: 'Filled',
      render: (record: OrderInfo) => {
        return (
          <span>
            {formatRoundFloorDisplay(record?.customFilled, 3)} {record?.asset}
          </span>
        );
      },
    },
    {
      title: 'Stop price',
      render: (record: OrderInfo) => (
        <span>{formatRoundFloorDisplay(record?.tpSLPrice, 3)}</span>
      ),
    },
    {
      title: 'Status',
      render: (record: OrderInfo) => (
        <span className="text-capitalize">
          {isActiveOrder ? 'Open' : (record?.customStatus || '').toLowerCase()}
        </span>
      ),
    },
    {
      title: 'Owner wallet address',
      render: (record: OrderInfo) => (
        <FracTooltip title={record?.userWallet}>
          <span>{ellipseAddress(record?.userWallet || '', 6, 4)}</span>
        </FracTooltip>
      ),
    },
  ];

  return (
    <div className="orders">
      <h1 className="common-title">View all orders</h1>
      <Radio.Group
        value={isActiveOrder}
        onChange={(e) => setIsActiveOrder(e.target.value)}
      >
        <Radio value={true}>Active order</Radio>
        <Radio value={false}>Order history</Radio>
      </Radio.Group>
      <OrderFilter
        form={form}
        isLoading={isLoading}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
      <div className="common-wrapper">
        <FracTable
          showTotal
          columns={columns}
          isLoading={isLoading}
          totalText="Total Orders"
          dataSource={orderData?.data || []}
          pagination={{
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            pageSize: pageSize,
            showSizeChanger: true,
            total: orderData?.metadata?.total,
            current: curPage,
            onShowSizeChange(current, size) {
              setPageSize(size);
            },
            onChange(page, pageSize) {
              setCurPage(page);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Orders;
