import { TransactionHistoryApi } from './implements/implement-apis';
import { ServiceBase } from './core/service-base';
import { GetTransactionHistoryParams } from './params-type/transaction-history';

export class TracsactionHistoryService
  extends ServiceBase
  implements TransactionHistoryApi
{
  getTransactionHistory = (params: GetTransactionHistoryParams) => {
    return this.get('admin/transactions', params);
  };
}
