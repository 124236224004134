import { Col, Form, FormInstance, Row, Space } from 'antd';
import { memo, useEffect, useState } from 'react';
import CustomFormItemInput from 'src/components/12.inputs/FormItemInput';
import {
  BIG_DECIMAL_REGEX,
  DECIMAL_REGEX,
  ONE_POSITIVE_REGEX,
} from 'src/constants/regex';
import {
  evmAddressRule,
  impactMarginNotionalRule,
  maxFiguresForPriceRule,
  maxLeverageRule,
  maxNotionalRule,
  maxOrderAmountRule,
  maxPriceRule,
  minNotionalRule,
  minOrderAmountRule,
  minPriceMovementRule,
  minPriceRule,
  requiredRule,
  tickSizeRule,
  trimDecimalPlaces,
} from 'src/constants/rule';
import InfoIcon from 'src/assets/icons/common/info-icon.svg';
import { formatRoundFloorDisplay } from 'src/helpers/formatNumber';
import FracTooltip from 'src/components/19.tooltip';
import { INSTRUMENT_FIELD, TIER_FIELD } from 'src/constants';
import ModalConfirm from 'src/components/06.modals/ModalConfirm';
import UnderlyingSymbolInput from './underlying-symbol-input';
import './styles.scss';

interface IProps {
  onSubmit: Function;
  instrumentInfo: any;
  form: FormInstance<any>;
}

const InstrumentForm: React.FC<IProps> = ({
  form,
  onSubmit,
  instrumentInfo,
}) => {
  const [impactMargin, setImpactMargin] = useState('');
  const [tickSizeList, setTickSizeList] = useState('N/A');
  const [openComfirmModal, setOpenComfirmModal] = useState(false);

  const formatNumber = (value: string) => {
    if (!value) return value;
    return formatRoundFloorDisplay(value).replace(/[^0-9.]/g, '');
  };

  useEffect(() => {
    if (!instrumentInfo) {
      form.resetFields();
      form.setFieldValue(INSTRUMENT_FIELD.COLLATERAL_ASSET, 'USD');
    } else {
      form.setFieldsValue(instrumentInfo);

      // Display tick size list
      const tickSizeValue = (instrumentInfo?.tickSize || '')
        .toString()
        .replace(/[^0-9.]/g, '');
      if (tickSizeValue.length > 0 && tickSizeValue !== '.') {
        let curValue = Number(formatRoundFloorDisplay(tickSizeValue));
        let result = curValue.toString() + '/';

        for (let i = 0; i < 3; i++) {
          curValue = curValue * 10;
          result = result + curValue.toString();
          if (i !== 2) result += '/';
        }

        setTickSizeList(result);
      } else setTickSizeList('N/A');

      // Display leverage
      const maxLeverageValue = (instrumentInfo?.maxLeverageTrade || '')
        .toString()
        .replace(/[^0-9.]/g, '');
      // if (maxLeverageValue.length > 0 && maxLeverageValue !== '.') {
      //   form.setFieldValue('leverage', Number(maxLeverageValue) / 5);
      // }

      // Display Impact margin notional
      const impactValue = (instrumentInfo?.impactMarginNotional || '')
        .toString()
        .replace(/[^0-9.]/g, '');
      if (impactValue.length > 0 && impactValue !== '.' && maxLeverageValue) {
        setImpactMargin(formatRoundFloorDisplay(impactValue));
        form.setFieldValue(
          'impactInput',
          Number(maxLeverageValue) * Number(impactValue)
        );
      }

      // Format other fields
      form.setFieldsValue({
        makerFee: formatNumber(instrumentInfo?.makerFee),
        takerFee: formatNumber(instrumentInfo?.takerFee),
        limitOrderPrice: formatNumber(instrumentInfo?.limitOrderPrice),
        floorRatio: formatNumber(instrumentInfo?.floorRatio),
        tickSize: formatNumber(instrumentInfo?.tickSize),
        minPrice: formatNumber(instrumentInfo?.minPrice),
        maxPrice: formatNumber(instrumentInfo?.maxPrice),
        minOrderAmount: formatNumber(instrumentInfo?.minOrderAmount),
        maxOrderAmount: formatNumber(instrumentInfo?.maxOrderAmount),
        maxFiguresForPrice: formatNumber(instrumentInfo?.maxFiguresForPrice),
        maxFiguresForSize: formatNumber(instrumentInfo?.maxFiguresForSize),
        liqClearanceFee: formatNumber(instrumentInfo?.liqClearanceFee),
        maxNotional: formatNumber(instrumentInfo?.maxNotional),
        minNotional: formatNumber(instrumentInfo?.minNotional),
        minPriceMovement: formatNumber(instrumentInfo?.minPriceMovement),
        maxLeverageTrade: formatNumber(instrumentInfo?.maxLeverageTrade),
      });
    }
  }, [instrumentInfo, form]);

  const onFinish = (data: any) => {
    const minPrice = trimDecimalPlaces(
      data?.minPrice,
      data?.minPriceMovement
    ).result;
    const maxPrice = trimDecimalPlaces(
      data?.maxPrice,
      data?.minPriceMovement
    ).result;
    const tickSize = Number(data?.tickSize) === 0 ? 1 : data?.tickSize;

    form.setFieldValue('minPrice', minPrice);
    form.setFieldValue('maxPrice', maxPrice);
    form.setFieldValue('tickSize', tickSize);

    onSubmit({
      ...data,
      tickSize: tickSize,
      minPrice: minPrice,
      maxPrice: maxPrice,
      impactMarginNotional: (impactMargin || '').replace(/[^0-9.]/g, ''),
    });

    // const submitData = {
    //   id: instrumentInfo.id,
    //   instrument: {
    //     symbol: data.symbol,
    //     rootSymbol: data.collateralAsset,
    //     contractAddress: data.contractAddress,
    //     makerOracleAddress: data.makerOracleAddress,
    //     orderAddress: data.orderAddress,
    //     liquidationAddress: data.liquidationAddress,
    //     contractType: 'USD-M',
    //     underlyingSymbol: data.symbol,
    //     makerFee: data.makerFee,
    //     takerFee: data.takerFee,
    //     tickSize: tickSize,
    //     maxPrice: maxPrice,
    //     minPriceMovement: data.minPriceMovement,
    //     maxFiguresForSize: data.maxFiguresForSize,
    //     maxFiguresForPrice: data.maxFiguresForPrice,
    //     impactMarginNotional: (impactMargin || '').replace(/[^0-9.]/g, ''),
    //     multiplier: 1,
    //   },
    //   tradingRules: {
    //     minPrice: minPrice,
    //     limitOrderPrice: data.limitOrderPrice,
    //     floorRatio: data.floorRatio,
    //     minOrderAmount: data.minOrderAmount,
    //     maxOrderAmount: data.maxOrderAmount,
    //     minNotional: data.minNotional,
    //     maxNotional: data.maxNotional,
    //     liqClearanceFee: data.liqClearanceFee,
    //     maxLeverage: data.maxLeverageTrade,
    //   },
    //   leverageMargin: {
    //     maintenanceMarginRate: data.maintenanceMarginRate,
    //   },
    // };

    // console.log(submitData);

    // if (instrumentInfo) {
    //   dispatch(
    //     handleUpdateInstrument(
    //       submitData,
    //       () => toast.success('Edit instrument successfully'),
    //       () => toast.error('Edit instrument failed')
    //     )
    //   );
    // } else {
    //   dispatch(
    //     handleCreateNewInstrument(
    //       submitData,
    //       () => {
    //         toast.success('Create instrument successfully');
    //         setIsViewInstrument(true);
    //       },
    //       () => toast.error('Create instrument failed')
    //     )
    //   );
    // }
  };

  return (
    <div className="instrument-form">
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <div className="instrument-form-wrapper">
              <CustomFormItemInput
                form={form}
                label="Name"
                regex={/^.{0,20}$/}
                rules={requiredRule()}
                name={INSTRUMENT_FIELD.NAME}
                inputProps={{ placeholder: 'Enter Name' }}
              />
              <CustomFormItemInput
                form={form}
                label="PerpetualV1 Address"
                // regex={/^.{0,20}$/}
                rules={evmAddressRule()}
                name="contractAddress"
                inputProps={{
                  placeholder: 'Enter PerpetualV1 Address',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Order Address"
                // regex={/^.{0,20}$/}
                rules={evmAddressRule()}
                name="orderAddress"
                inputProps={{
                  placeholder: 'Enter Order Address',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Oracle Price Address"
                // regex={/^.{0,20}$/}
                rules={evmAddressRule()}
                name="makerOracleAddress"
                inputProps={{
                  placeholder: 'Enter Oracle Price Address',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Liquidation Address"
                // regex={/^.{0,20}$/}
                rules={evmAddressRule()}
                name="liquidationAddress"
                inputProps={{
                  placeholder: 'Enter Liquidation Address',
                  disabled: !!instrumentInfo,
                }}
              />
              <UnderlyingSymbolInput form={form} disabled={!!instrumentInfo} />
              <CustomFormItemInput
                form={form}
                label="Collateral asset"
                rules={requiredRule()}
                name={INSTRUMENT_FIELD.COLLATERAL_ASSET}
                inputProps={{
                  placeholder: 'Enter Collateral asset',
                  disabled: true,
                }}
              />
              {/* <CustomFormItemInput
                form={form}
                label="Maker Fee (%)"
                regex={DECIMAL_REGEX}
                rules={requiredRule()}
                name={INSTRUMENT_FIELD.MAKER_FEE}
                inputProps={{
                  placeholder: 'Enter Maker Fee',
                  suffix: '%',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Taker Fee (%)"
                regex={DECIMAL_REGEX}
                rules={requiredRule()}
                name={INSTRUMENT_FIELD.TAKER_FEE}
                inputProps={{
                  placeholder: 'Enter Taker Fee',
                  suffix: '%',
                  disabled: !!instrumentInfo,
                }}
              /> */}
              <CustomFormItemInput
                form={form}
                label="Limit buy order price cap ratio (%)"
                regex={DECIMAL_REGEX}
                rules={requiredRule()}
                name={INSTRUMENT_FIELD.LIMIT_ORDER_PRICE}
                inputProps={{
                  placeholder: 'Enter Limit',
                  suffix: '%',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Limit sell order price floor ratio (%)"
                regex={DECIMAL_REGEX}
                rules={requiredRule()}
                name={INSTRUMENT_FIELD.FLOOR_RATIO}
                inputProps={{
                  placeholder: 'Enter Limit',
                  suffix: '%',
                  disabled: !!instrumentInfo,
                }}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="instrument-form-wrapper">
              <CustomFormItemInput
                form={form}
                label="Min tick size"
                regex={DECIMAL_REGEX}
                className="tick-size-input"
                name={INSTRUMENT_FIELD.TICK_SIZE}
                rules={tickSizeRule(setTickSizeList, form)}
                inputProps={{
                  placeholder: 'Enter Min tick size',
                  disabled: !!instrumentInfo,
                }}
              />
              <div className="tick-size-list">
                All tick size: {tickSizeList}
              </div>
              <CustomFormItemInput
                form={form}
                label="Min price"
                regex={DECIMAL_REGEX}
                rules={minPriceRule()}
                name={INSTRUMENT_FIELD.MIN_PRICE}
                inputProps={{
                  placeholder: 'Enter Min price',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Max price"
                rules={maxPriceRule()}
                regex={BIG_DECIMAL_REGEX}
                name={INSTRUMENT_FIELD.MAX_PRICE}
                inputProps={{
                  placeholder: 'Enter Max price',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Min order amount"
                regex={DECIMAL_REGEX}
                rules={minOrderAmountRule()}
                name={INSTRUMENT_FIELD.MIN_ORDER_AMOUNT}
                inputProps={{
                  placeholder: 'Enter Min order amount',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Max order amount"
                regex={BIG_DECIMAL_REGEX}
                rules={maxOrderAmountRule()}
                name={INSTRUMENT_FIELD.MAX_ORDER_AMOUNT}
                inputProps={{
                  placeholder: 'Enter Max order amount',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Max No. of decimal figures for price"
                regex={ONE_POSITIVE_REGEX}
                rules={maxFiguresForPriceRule()}
                name={INSTRUMENT_FIELD.MAX_FIGURES_FOR_PRICE}
                inputProps={{
                  placeholder: 'Enter Max decimal for price',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Max No. of decimal figures for size"
                regex={ONE_POSITIVE_REGEX}
                rules={requiredRule()}
                name={INSTRUMENT_FIELD.MAX_FIGURES_FOR_SIZE}
                inputProps={{
                  placeholder: 'Enter Max decimal for size',
                  disabled: !!instrumentInfo,
                }}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="instrument-form-wrapper">
              {/* <CustomFormItemInput
                form={form}
                label="Liquidation clearance fee (%)"
                regex={DECIMAL_REGEX}
                rules={requiredRule()}
                name={INSTRUMENT_FIELD.LIQ_CLEARANCE_FEE}
                inputProps={{
                  placeholder: 'Enter Liquidation clearance fee',
                  suffix: '%',
                }}
              /> */}
              <CustomFormItemInput
                form={form}
                label="Max leverage"
                name={INSTRUMENT_FIELD.MAX_LEVERAGE}
                regex={/^\d+$/}
                rules={maxLeverageRule(setImpactMargin)}
                inputProps={{
                  placeholder: 'Enter Max leverage',
                  disabled: !!instrumentInfo,
                }}
              />
              {/* <span className="input-label">Max leverage</span>
              <div className="leverage-wrapper">
                <CustomFormItemInput
                  form={form}
                  name={INSTRUMENT_FIELD.LEVERAGE}
                  regex={/^(?:[1-9]|[1-9][0-9])$/}
                  rules={maxLeverageRule(setImpactMargin)}
                  inputProps={{
                    placeholder: 'Enter input',
                    disabled: !!instrumentInfo,
                  }}
                />
                <span className="text-nowrap">x5 = </span>
                <CustomFormItemInput
                  form={form}
                  name={INSTRUMENT_FIELD.MAX_LEVERAGE}
                  inputProps={{
                    disabled: true,
                  }}
                />
              </div> */}
              <CustomFormItemInput
                form={form}
                label="Maintenance Margin Rate"
                name={TIER_FIELD.MAINTENANCE_MARGIN_RATE}
                regex={DECIMAL_REGEX}
                // rules={maintenanceMarginRateRule()}
                rules={requiredRule()}
                inputProps={{
                  placeholder: 'Enter Maintenance Margin Rate',
                  suffix: '%',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Max notional value"
                name={INSTRUMENT_FIELD.MAX_NOTIONAL}
                regex={/^(?:0|[1-9]\d{0,7})(?:\.\d{0,2})?$/}
                rules={maxNotionalRule()}
                inputProps={{
                  placeholder: 'Enter Max notional value',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label="Min notional value"
                name={INSTRUMENT_FIELD.MIN_NOTIONAL}
                regex={/^(?:0|[1-9]\d{0,7})(?:\.\d{0,2})?$/}
                rules={minNotionalRule()}
                inputProps={{
                  placeholder: 'Enter Min notional value',
                  disabled: !!instrumentInfo,
                }}
              />
              <CustomFormItemInput
                form={form}
                label={
                  <Space size={4}>
                    <span>Minimum price movement</span>
                    <FracTooltip title="Must set equal to Min tick set">
                      <img src={InfoIcon} alt="tooltip" />
                    </FracTooltip>
                  </Space>
                }
                name={INSTRUMENT_FIELD.MIN_PRICE_MOVEMENT}
                regex={DECIMAL_REGEX}
                rules={minPriceMovementRule()}
                inputProps={{
                  placeholder: 'Enter Minimum price movement',
                  disabled: !!instrumentInfo,
                }}
              />
              <span className="input-label">Impact margin notional</span>
              <Space size={6} align="center" className="formula-input">
                <div className="formula-wrapper">
                  <CustomFormItemInput
                    form={form}
                    regex={/^[0-9]+(\.[0-9]{0,8})?$/}
                    name={INSTRUMENT_FIELD.IMPACT_INPUT}
                    rules={impactMarginNotionalRule(setImpactMargin)}
                    inputProps={{
                      placeholder: 'Enter Input',
                      disabled: !!instrumentInfo,
                    }}
                  />
                  <div className="divider"></div>
                  <span>Max leverage</span>
                </div>
                <span className="text-nowrap">=</span>
                <span className="formula-result">{impactMargin}</span>
              </Space>
              <span className="formula-hint">
                (Impact margin notional = Constant number/ Max Leverage)
              </span>
            </div>
          </Col>
        </Row>
      </Form>
      {/* <div className="action-group">
        <Divider />
        <div className="group-btn">
          <Space size={8}>
            <ButtonComponent
              text="Save"
              variant="primary"
              onClick={() =>
                form.validateFields().then(() => {
                  setOpenComfirmModal(true);
                })
              }
            />
            <ButtonComponent
              text="Reset"
              variant="secondary"
              onClick={() => form.resetFields()}
            />
          </Space>
        </div>
      </div> */}
      <ModalConfirm
        visible={openComfirmModal}
        title="Save Contract"
        description="By confirming, this contract and all the information will be saved."
        onCancel={() => setOpenComfirmModal(false)}
        onSubmit={() => {
          form.submit();
          setOpenComfirmModal(false);
        }}
      />
    </div>
  );
};

export default memo(InstrumentForm);
