import React, { memo, useEffect, useMemo, useState } from 'react';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import PlusIcon from 'src/assets/icons/common/plus-icon.svg';
import EditIcon from 'src/assets/icons/common/edit-outline-icon.svg';
import FracTable from 'src/components/05.table';
import { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_SIZE_OPTIONS } from 'src/constants';
import { fetchInstrumentList } from 'src/store/actions/instrument';
import { formatRoundFloorDisplay } from 'src/helpers/formatNumber';
import SearchIcon from 'src/assets/icons/common/search-icon.svg';
import './styles.scss';
import { FracInput } from 'src/components/12.inputs';
import { debounce } from 'lodash';
import { DEBOUNCE_SEARCH_TIME } from 'src/constants/time';

interface IProps {
  handleViewInstrumentInfo: (data: any) => void;
  setIsViewInstrument: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewInstrument: React.FC<IProps> = ({
  setIsViewInstrument,
  handleViewInstrumentInfo,
}) => {
  const dispatch = useDispatch();

  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);

  const instrumentList = useSelector(
    (state: any) => state.instrument.instrumentList
  );

  useEffect(() => {
    setCurPage(1);
  }, [pageSize]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchInstrumentList(
        {
          page: curPage,
          limit: pageSize,
          search: searchText.length === 0 ? undefined : searchText,
        },
        () => setIsLoading(false)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, curPage, searchText]);

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    setCurPage(1);
    setIsLoading(true);
    setSearchText(value);
  };

  const debounceHandler = useMemo(
    () => debounce(handleSearchChange, DEBOUNCE_SEARCH_TIME),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      width: 112,
      render: (record: any) => (
        <span className="text-nowrap">{record?.name}</span>
      ),
    },
    {
      title: 'Underlying Asset & Quote currency',
      width: 162,
      render: (record: any) => (
        <span className="text-nowrap">{record?.underlyingSymbol}</span>
      ),
    },
    {
      title: 'Collateral Asset',
      width: 112,
      render: (record: any) => (
        <span className="text-nowrap">{record?.collateralAsset}</span>
      ),
    },
    // {
    //   title: 'Maker/ Taker Fee',
    //   width: 112,
    //   render: (record: any) => (
    //     <div>
    //       <span className="text-nowrap">
    //         {formatRoundFloorDisplay(record?.makerFee, 3)}%/
    //       </span>
    //       <span className="text-nowrap">
    //         {formatRoundFloorDisplay(record?.takerFee, 3)}%
    //       </span>
    //     </div>
    //   ),
    // },
    {
      title: 'Min. Tick Size',
      width: 112,
      render: (record: any) => (
        <span className="text-nowrap">
          {formatRoundFloorDisplay(record?.tickSize, 5)}
        </span>
      ),
    },
    {
      title: 'Min. Price Movement',
      width: 112,
      render: (record: any) => (
        <span className="text-nowrap">
          {formatRoundFloorDisplay(record?.minPriceMovement, 7)}
        </span>
      ),
    },
    {
      title: 'Min Price/ Max Price',
      width: 112,
      render: (record: any) => (
        <div>
          <span className="text-nowrap">
            {formatRoundFloorDisplay(record?.minPrice, 3)}/
          </span>
          <span className="text-nowrap">
            {formatRoundFloorDisplay(record?.maxPrice, 3)}
          </span>
        </div>
      ),
    },
    {
      title: 'Limit Order Price Buy Cap/Sell Floor Ratio',
      width: 175,
      render: (record: any) => (
        <div>
          <span className="text-nowrap">
            {formatRoundFloorDisplay(record?.limitOrderPrice)}%/
          </span>
          <span className="text-nowrap">
            {formatRoundFloorDisplay(record?.floorRatio)}%
          </span>
        </div>
      ),
    },
    {
      title: 'Min/Max Order Amt',
      width: 112,
      render: (record: any) => (
        <div>
          <span className="text-nowrap">
            {formatRoundFloorDisplay(record?.minOrderAmount)}/
          </span>
          <span className="text-nowrap">
            {formatRoundFloorDisplay(record?.maxOrderAmount)}
          </span>
        </div>
      ),
    },
    {
      title: 'Min/Max Notional Valuelue',
      width: 156,
      render: (record: any) => (
        <div>
          <span className="text-nowrap">
            {formatRoundFloorDisplay(record?.minNotional)}/
          </span>
          <span className="text-nowrap">
            {formatRoundFloorDisplay(record?.maxNotional)}
          </span>
        </div>
      ),
    },
    {
      title: 'Max Leverage',
      width: 112,
      render: (record: any) => (
        <span className="text-nowrap">
          {formatRoundFloorDisplay(record?.maxLeverage)}
        </span>
      ),
    },
    {
      title: 'Max No. of Decimal Figures for Size',
      width: 166,
      render: (record: any) => (
        <span className="text-nowrap">
          {formatRoundFloorDisplay(record?.maxFiguresForSize)}
        </span>
      ),
    },
    {
      title: 'Max No. of Decimal Figures for Price',
      width: 166,
      render: (record: any) => (
        <span className="text-nowrap">
          {formatRoundFloorDisplay(record?.maxFiguresForPrice)}
        </span>
      ),
    },
    {
      title: 'Liq. Clearance Fee',
      width: 152,
      render: (record: any) => (
        <span className="text-nowrap">
          {formatRoundFloorDisplay(record?.liqClearanceFee)}%
        </span>
      ),
    },
    {
      title: 'Impact margin notional',
      width: 152,
      render: (record: any) => (
        <span className="text-nowrap">
          {formatRoundFloorDisplay(record?.impactMarginNotional)}
        </span>
      ),
    },
    {
      title: '',
      fixed: 'right',
      width: 53,
      render: (record: any) => (
        <img
          src={EditIcon}
          alt="edit"
          className="edit-button"
          onClick={() => handleViewInstrumentInfo(record)}
        />
      ),
    },
  ];

  return (
    <div className="view-instrument">
      <div className="view-instrument-action">
        <ButtonComponent
          customClassName="btn-search"
          text="Create Instrument"
          variant="primary"
          prefixIcon={<img src={PlusIcon} alt="plus" />}
          onClick={() => {
            setIsViewInstrument(false);
          }}
        />
        {/* <SearchSymbolInput
          showError={showError}
          setShowError={setShowError}
          searchText={searchSymbol}
          setSearchText={setSearchSymbol}
          className="search-input frac-input"
          onSelectItem={(symbol) => {
            setCurPage(1);
            setSearchText(symbol);
          }}
        /> */}
        <div className="search-input">
          <FracInput
            onChange={(e) => {
              debounceHandler(e);
            }}
            placeholder="Search"
            prefix={<img src={SearchIcon} alt="search" />}
          />
        </div>
      </div>
      <div className="instrument-wrapper">
        <FracTable
          showTotal
          columns={columns}
          isLoading={isLoading}
          totalText="Total Instruments"
          dataSource={instrumentList?.data || []}
          pagination={{
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            pageSize: pageSize,
            showSizeChanger: true,
            total: instrumentList?.metadata?.total,
            current: curPage,
            onShowSizeChange(current, size) {
              setPageSize(size);
            },
            onChange(page, pageSize) {
              setCurPage(page);
            },
          }}
          scroll={{ x: 2000 }}
        />
      </div>
    </div>
  );
};

export default memo(ViewInstrument);
