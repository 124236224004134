import GeneralSetting from './general-setting';
import SocialSetting from './social-setting';
import './styles.scss';

const SiteSetting = () => {
  return (
    <div className="setting">
      <h1 className="common-title">Site setting</h1>
      <GeneralSetting />
      <SocialSetting />
    </div>
  );
};

export default SiteSetting;
