import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { ConnectorKey, connectors } from "../connectors";
import { metaMask } from "../connectors/metmask";
import { CONNECTOR_KEY } from "../constants/storages";
import { useConnectWallet } from "./useConnectWallet";
import { debounce } from "lodash";
import { fracToast2 } from "src/components/07.toast";
import { useHistory } from "react-router";

export function useEagerConnect() {
  const { isActive } = useWeb3React();
  const { disconnectWallet } = useConnectWallet();
  const [tried, setTried] = useState(false);
  const wallet = window.localStorage.getItem(CONNECTOR_KEY);
  const history = useHistory();

  const handleConnectMetamask = async () => {
    const unlockedFunc = (window.ethereum as any)?._metamask.isUnlocked;
    if (typeof unlockedFunc === "function") {
      const isUnlock = await (window.ethereum as any)?._metamask.isUnlocked();
      if (isUnlock) {
        await metaMask.connectEagerly();
      } else {
        disconnectWallet();
      }
    } else {
      await metaMask.connectEagerly();
    }
  };

  useEffect(() => {
    if (!isActive) {
      if (
        [
          ConnectorKey.coinBase,
          ConnectorKey.walletConnect,
          ConnectorKey.trustWallet,
        ].includes(wallet as ConnectorKey)
      ) {
        const connector = connectors[wallet as ConnectorKey];
        connector?.connectEagerly();
      } else if (wallet === ConnectorKey.metaMask) {
        handleConnectMetamask();
      }
      setTried(true);
    } else {
      setTried(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, wallet]);

  const handleShowExpiredTokenToast = () => {
    fracToast2.error("Your session is expired");
  };

  useEffect(() => {
    if (window) {
      (window as any).disconnect = disconnectWallet;
      (window as any).handleShowExpiredTokenToast = debounce(
        handleShowExpiredTokenToast,
        500
      );
      (window as any).push = history.push;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return tried;
}
