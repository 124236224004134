import { SET_TRANSACTION_LIST } from '../constants/transaction-history';

const initialState = {
  transactionData: {},
};

export const tracsactionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_TRANSACTION_LIST: {
      return {
        ...state,
        transactionData: action.payload,
      };
    }

    default:
      return state;
  }
};
