import { FutureRecordApi } from './implements/implement-apis';
import { ServiceBase } from './core/service-base';
import { GetFutureRecordParams } from './params-type/future-records';

export class FutureRecordService
  extends ServiceBase
  implements FutureRecordApi
{
  getOrderList = (params: GetFutureRecordParams) => {
    return this.get('admin/order', params);
  };
  getPositionList = (params: GetFutureRecordParams) => {
    return this.get('admin/positions', params);
  };
  getTradeList = (params: GetFutureRecordParams) => {
    return this.get('admin/trade', params);
  };
}
