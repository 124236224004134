// @ts-nocheck

import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import './styles.scss';

export function aaaaa(
  msgType?: any,
  msgContent = 'Default Message',
  objValue?: any
) {
  message.config({
    maxCount: 1,
  });

  let fieldMsg;
  if (objValue) {
    const key = (Object.keys(objValue) || [])[0];
    const val = objValue[key];

    fieldMsg = {
      [key]: val,
    };
  }
  message[msgType]({
    content: fieldMsg || msgContent,
    className: 'event-message',
    duration: 3,
    maxCount: 1,
  });
}

const FracLoading = () => {
  return (
    <Spin
      className="frac-loading"
      indicator={<LoadingOutlined className="loading-icon" spin />}
    />
  );
};

export default FracLoading;
