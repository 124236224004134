import { SET_CONNECTOR_NAME, DISCONNECT_WALLET, walletTypes } from '../constants/wallet';

export const connectWalletPending = (payload: any) => {
  return {
    type: walletTypes.CONNECT_WALLET_PENDING,
    payload,
  };
};

export const wrongNetwork = (payload: any) => {
  return {
    type: walletTypes.WRONG_NETWORK,
    payload,
  };
};

export const loadingNetwork = (payload: any) => {
  return {
    type: walletTypes.LOADING_NETWORK,
    payload,
  };
};

export const setConnectorName = (name: string) => {
  return {
    type: SET_CONNECTOR_NAME,
    payload: name,
  };
};

export const disconnect = () => {
  return {
    type: DISCONNECT_WALLET,
    payload: '',
  };
};
