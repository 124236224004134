import {
  SET_USER_ADDRESS_LIST,
  SET_USER_DEVICE_LIST,
  SET_USER_INFO_LIST,
  SET_RESTRICTION_LIST,
} from '../constants/user-management';

const initialState = {
  userInfoData: {},
  userDeviceData: {},
  userAddressData: {},
  restrictionData: {},
};

export const userManagementReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_INFO_LIST: {
      return {
        ...state,
        userInfoData: action.payload,
      };
    }
    case SET_USER_ADDRESS_LIST: {
      return {
        ...state,
        userAddressData: action.payload,
      };
    }
    case SET_USER_DEVICE_LIST: {
      return {
        ...state,
        userDeviceData: action.payload,
      };
    }
    case SET_RESTRICTION_LIST: {
      return {
        ...state,
        restrictionData: action.payload,
      };
    }

    default:
      return state;
  }
};
