import { PATHS } from 'src/constants/paths';

import settingIcon from 'src/assets/icons/sidebar/1setting-icon.svg';
// import walletIcon from 'src/assets/icons/sidebar/1wallet-icon.svg';
import userIcon from 'src/assets/icons/sidebar/1user-icon.svg';
// import exchangeIcon from 'src/assets/icons/sidebar/1exchange-icon.svg';
import recordIcon from 'src/assets/icons/sidebar/1future-record-icon.svg';
import transactionIcon from 'src/assets/icons/sidebar/1transaction-icon.svg';
import instrumentIcon from 'src/assets/icons/sidebar/1instrument-icon.svg';

import Login from 'src/pages/login';
import NotFound from 'src/routes/components/NotFound';
import Instrument from 'src/pages/instrument';
import SiteSetting from 'src/pages/site-setting';
// import ColdWallet from 'src/pages/wallet/cold-wallet';
// import HotWallet from 'src/pages/wallet/hot-wallet';
import UserInfomation from 'src/pages/user-management/user-infomation';
// import UserSetting from 'src/pages/user-management/user-setting';
import DeviceManagement from 'src/pages/user-management/device-management';
// import TransactionSetting from 'src/pages/exchange-setting/tracsaction-setting';
// import TradingSetting from 'src/pages/exchange-setting/trading-setting';
// import WithdrawalSetting from 'src/pages/exchange-setting/withdrawal-setting';
// import FeeSetting from 'src/pages/exchange-setting/fee-setting';
import DepositHistory from 'src/pages/transaction-history/deposit-history';
import WithdrawHistory from 'src/pages/transaction-history/withdraw-history';
import OtherHistory from 'src/pages/transaction-history/other-history';
import Positions from 'src/pages/future-record/positions';
import Trades from 'src/pages/future-record/trades';
import Orders from 'src/pages/future-record/orders';
import Restriction from 'src/pages/user-management/restriction';

export const SidebarList = [
  {
    label: 'Site settings',
    subLink: '/',
    component: SiteSetting,
    icon: settingIcon,
    inMenu: true,
    exact: true,
  },
  // {
  //   label: 'Wallet',
  //   // subLink: PATHS.revenue(),
  //   children: [
  //     {
  //       label: 'Cold wallet',
  //       key: 'Cold wallet',
  //       subLink: PATHS.coldWallet(),
  //       component: ColdWallet,
  //       exact: true,
  //     },
  //     {
  //       label: 'Hot wallet',
  //       key: 'Hot wallet',
  //       subLink: PATHS.hotWallet(),
  //       component: HotWallet,
  //       exact: true,
  //     },
  //   ],
  //   exact: true,
  //   icon: walletIcon,
  // },
  {
    label: 'User management',
    subLink: PATHS.userInformation(),
    childrenSubLinks: [
      PATHS.userInformation(),
      // PATHS.userSetting(),
      PATHS.deviceManagement(),
    ],
    exact: true,
    icon: userIcon,
    children: [
      {
        label: 'User information',
        key: 'User information',
        subLink: PATHS.userInformation(),
        component: UserInfomation,
        exact: true,
      },
      // {
      //   label: 'User enable fee setting',
      //   key: 'User enable fee setting',
      //   subLink: PATHS.userSetting(),
      //   component: UserSetting,
      //   exact: true,
      // },
      {
        label: 'Device management',
        key: 'Device management',
        subLink: PATHS.deviceManagement(),
        component: DeviceManagement,
        exact: true,
      },
      {
        label: 'Restriction',
        key: 'Restriction',
        subLink: PATHS.restrictions(),
        component: Restriction,
        exact: true,
      },
    ],
  },
  // {
  //   label: 'Exchange setting',
  //   subLink: '/dex',
  //   children: [
  //     {
  //       label: 'Transaction setting',
  //       key: 'Transaction setting',
  //       subLink: PATHS.transactionSetting(),
  //       component: TransactionSetting,
  //       exact: true,
  //     },
  //     {
  //       label: 'Trading setting',
  //       key: 'Trading setting',
  //       subLink: PATHS.tradingSetting(),
  //       component: TradingSetting,
  //       exact: true,
  //     },
  //     {
  //       label: 'User withdrawal setting',
  //       key: 'User withdrawal setting',
  //       subLink: PATHS.withdrawalSetting(),
  //       component: WithdrawalSetting,
  //       exact: true,
  //     },
  //     {
  //       label: 'Fee',
  //       key: 'Fee',
  //       subLink: PATHS.feeSetting(),
  //       component: FeeSetting,
  //       exact: true,
  //     },
  //   ],
  //   exact: true,
  //   icon: exchangeIcon,
  // },
  {
    label: 'Future records',
    subLink: PATHS.orders(),
    childrenSubLinks: [PATHS.orders(), PATHS.positions(), PATHS.trades()],
    exact: true,
    icon: recordIcon,
    children: [
      {
        label: 'Order',
        key: 'Order',
        subLink: PATHS.orders(),
        component: Orders,
        exact: true,
      },
      {
        label: 'Positions',
        key: 'Positions',
        subLink: PATHS.positions(),
        component: Positions,
        exact: true,
      },
      {
        label: 'Trades',
        key: 'Trades',
        subLink: PATHS.trades(),
        component: Trades,
        exact: true,
      },
    ],
  },
  {
    label: 'Transaction history',
    subLink: PATHS.depositHistory(),
    childrenSubLinks: [
      PATHS.depositHistory(),
      PATHS.withdrawHistory(),
      PATHS.otherHistory(),
    ],
    exact: true,
    icon: transactionIcon,
    children: [
      {
        label: 'Deposit',
        key: 'Deposit',
        subLink: PATHS.depositHistory(),
        component: DepositHistory,
        exact: true,
      },
      {
        label: 'Withdraw',
        key: 'Withdraw',
        subLink: PATHS.withdrawHistory(),
        component: WithdrawHistory,
        exact: true,
      },
      {
        label: 'Other transaction',
        key: 'Other transaction',
        subLink: PATHS.otherHistory(),
        component: OtherHistory,
        exact: true,
      },
    ],
  },
  {
    label: 'Instrument management',
    subLink: PATHS.instrument(),
    exact: true,
    component: Instrument,
    inMenu: true,
    icon: instrumentIcon,
  },
  {
    subLink: '*',
    component: NotFound,
    exact: true,
  },
];

export const PublicRouter = [
  {
    label: 'Connect Wallet',
    subLink: PATHS.login(),
    component: Login,
    exact: true,
  },
  {
    subLink: '*',
    component: NotFound,
    exact: true,
  },
];
