import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  SET_ORDER_LIST,
  SET_POSITION_LIST,
  SET_TRADE_LIST,
} from '../constants/future-records';
import { FutureRecordService } from 'src/services/future-records';
import { GetFutureRecordParams } from 'src/services/params-type/future-records';

export const setOrderList = (payload: any) => {
  return {
    type: SET_ORDER_LIST,
    payload,
  };
};

export const setPositionList = (payload: any) => {
  return {
    type: SET_POSITION_LIST,
    payload,
  };
};

export const setTradeList = (payload: any) => {
  return {
    type: SET_TRADE_LIST,
    payload,
  };
};

export const fetchOrderList = (
  params: GetFutureRecordParams,
  callBack?: any
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new FutureRecordService();
    try {
      const res: any = await services.getOrderList(params);
      if (res.data)
        dispatch(setOrderList({ data: res?.data, metadata: res?.metadata }));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const fetchPositionList = (
  params: GetFutureRecordParams,
  callBack?: any
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new FutureRecordService();
    try {
      const res: any = await services.getPositionList(params);
      if (res.data)
        dispatch(setPositionList({ data: res?.data, metadata: res?.metadata }));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const fetchTradeList = (
  params: GetFutureRecordParams,
  callBack?: any
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new FutureRecordService();
    try {
      const res: any = await services.getTradeList(params);
      if (res.data)
        dispatch(setTradeList({ data: res?.data, metadata: res?.metadata }));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};
