const CONNECT_WALLET_START = 'CONNECT_WALLET_START';
const CONNECT_WALLET_SUCCESS = 'CONNECT_WALLET_SUCCESS';
const CONNECT_WALLET_FAIL = 'CONNECT_WALLET_FAIL';
const SET_ADDRESS = 'SET_ADDRESS';
const SET_AUTH = 'SET_AUTH';
const SET_PROFILE = 'SET_PROFILE';

export const authTypes = {
  CONNECT_WALLET_START,
  CONNECT_WALLET_SUCCESS,
  CONNECT_WALLET_FAIL,
  SET_ADDRESS,
  SET_AUTH,
  SET_PROFILE,
};
