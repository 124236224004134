import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { initializeConnector } from '@web3-react/core';

export const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: process.env.REACT_APP_RPC_URL || '',
        appName: 'Axor exchange',
        reloadOnDisconnect: false,
      },
    }),
);
