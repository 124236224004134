import FracTable from 'src/components/05.table';
import { ColumnsType } from 'antd/es/table';
import CommonFilter, {
  CommonFilterParams,
  DEFAULT_FILTER_PARAMS,
} from 'src/components/21.common-filter';
import './styles.scss';
import { Form, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { PAGE_SIZE_OPTIONS } from 'src/constants';
import { fetchTradeList } from 'src/store/actions/future-records';
import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants/formatters';
import { formatRoundFloorDisplay } from 'src/helpers/formatNumber';
import { ellipseAddress } from 'src/helpers';
import FracTooltip from 'src/components/19.tooltip';
import { TradeInfo } from 'src/services/params-type/future-records';

const Trades = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [filterParams, setFilterParams] = useState<CommonFilterParams>(
    DEFAULT_FILTER_PARAMS
  );

  const tradeData = useSelector((state: any) => state.futureRecords.tradeData);

  useEffect(() => {
    setCurPage(1);
  }, [filterParams, pageSize]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchTradeList(
        {
          page: curPage,
          limit: pageSize,
          // orderBy: 'time',
          // direction: 'DESC',
          from: (
            dayjs(filterParams.startTime).startOf('day').unix() * 1000
          ).toString(),
          to: (
            dayjs(filterParams.endTime).endOf('day').unix() * 1000
          ).toString(),
          symbol: filterParams.searchText?.length
            ? filterParams.searchText
            : undefined,
        },
        () => setIsLoading(false)
      )
    );
  }, [filterParams, pageSize, curPage, dispatch]);

  const columns: ColumnsType<TradeInfo> = [
    {
      title: 'Time',
      render: (record: TradeInfo) => (
        <Space size={4} direction="vertical">
          <span className="text-nowrap">
            {dayjs(record?.created_at).format(DATE_FORMAT)}
          </span>
          <span className="text-nowrap gray-text">
            {dayjs(record?.created_at).format(TIME_FORMAT)}
          </span>
        </Space>
      ),
    },
    {
      title: 'Symbol',
      render: (record: TradeInfo) => (
        <Space size={4} direction="vertical">
          <span className="text-nowrap">{record?.symbol}</span>
          <span className="text-nowrap gray-text">Perpetual</span>
        </Space>
      ),
    },
    {
      title: 'Buy Owner wallet address',
      render: (record: TradeInfo) => (
        <FracTooltip title={record?.buyWalletAddress}>
          <span>{ellipseAddress(record?.buyWalletAddress || '', 6, 4)}</span>
        </FracTooltip>
      ),
    },
    {
      title: 'Sell Owner wallet address',
      render: (record: TradeInfo) => (
        <FracTooltip title={record?.sellWalletAddress}>
          <span>{ellipseAddress(record?.sellWalletAddress || '', 6, 4)}</span>
        </FracTooltip>
      ),
    },
    {
      title: 'Buy fee',
      render: (record: TradeInfo) => (
        <span>{formatRoundFloorDisplay(record?.buy_fee)}</span>
      ),
    },
    {
      title: 'Sell fee',
      render: (record: TradeInfo) => (
        <span>{formatRoundFloorDisplay(record?.sell_fee)}</span>
      ),
    },
    {
      title: 'Price',
      render: (record: TradeInfo) => (
        <span>{formatRoundFloorDisplay(record?.price)}</span>
      ),
    },
    {
      title: 'Quantity',
      render: (record: TradeInfo) => (
        <span>{formatRoundFloorDisplay(record?.quantity)}</span>
      ),
    },
  ];

  return (
    <div className="trades">
      <h1 className="common-title">Trade history</h1>
      <CommonFilter
        form={form}
        searchBy="symbol"
        isLoading={isLoading}
        placeholder="Search by symbol"
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
      <div className="common-wrapper">
        <FracTable
          showTotal
          columns={columns}
          isLoading={isLoading}
          totalText="Total Trades"
          dataSource={tradeData.data || []}
          pagination={{
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            pageSize: pageSize,
            showSizeChanger: true,
            total: tradeData?.metadata?.total,
            current: curPage,
            onShowSizeChange(current, size) {
              setPageSize(size);
            },
            onChange(page, pageSize) {
              setCurPage(page);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Trades;
