import { InjectedConnector } from '@web3-react/injected-connector';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Logo from 'src/assets/icons/common/logo.svg';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalMetaMaskNotFound from 'src/components/06.modals/ModalMetaMaskNotFound';
import { connectWalletPending } from 'src/store/actions/wallet';
import { ConnectorKey } from 'src/web3/connectors';
import { Col, Row, Spin } from 'antd';
import { PATHS } from 'src/constants/paths';
import { LoadingOutlined } from '@ant-design/icons';
import { BlackCloseIcon } from 'src/assets/icons';
import { useAuth } from 'src/hooks/useAuth';
import { fracToast2 } from 'src/components/07.toast';
import { setStorageLogKey } from 'src/helpers/storage';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useAccountEffect, useDisconnect } from 'wagmi';
import './styles.scss';

export const injected = new InjectedConnector({});

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  const { disconnect } = useDisconnect();
  const { isConnected, isConnecting } = useAccount();

  const [visibleMetamaskNotFoundModal, setVisibleMetamaskNotFoundModal] =
    useState(false);

  // const { visibleConnectWallet } = useSelector(
  //   (state: any) => state.connectorName
  // );

  useAccountEffect({
    onConnect(data) {
      console.log('Connected!', data?.connector?.type);
      try {
        signIn(data?.connector?.type as ConnectorKey, () => {
          history.push(PATHS.settings());
          setStorageLogKey();
        });
      } catch (error: any) {
        if (!error.message.includes('Connection request reset')) {
          fracToast2.error('Failed to connect', 'Failed to connect');
        }
      } finally {
        dispatch(connectWalletPending(false));
      }
    },
    // onDisconnect() {
    //   console.log('Disconnected!')
    // },
  });

  const toggleModalMetamaskNotFound = () =>
    setVisibleMetamaskNotFoundModal((prev) => !prev);

  // const isMetaMaskInstalled = () => {
  //   return Boolean(
  //     (window as any).ethereum && (window as any).ethereum.isMetaMask
  //   );
  // };

  // const clearWalletConnect = () => {
  //   coinbaseWallet.resetState();
  //   coinbaseWallet.deactivate();
  //   walletConnect.resetState();
  //   walletConnect.deactivate();
  //   trustWalletConnect.resetState();
  //   trustWalletConnect.deactivate();
  //   localStorage.clear();
  // };

  // const handleClick = async (connectorKey: ConnectorKey) => {
  //   try {
  //     if (!isMetaMaskInstalled() && connectorKey === ConnectorKey.metaMask) {
  //       const metamaskAppDeepLink = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
  //       setVisibleMetamaskNotFoundModal(true);
  //       window.open(metamaskAppDeepLink);

  //       return;
  //     }

  //     if (connectorKey === ConnectorKey.metaMask) {
  //       dispatch(connectWalletPending(true));
  //     }

  //     if (
  //       [ConnectorKey.walletConnect, ConnectorKey.trustWallet].includes(
  //         connectorKey
  //       )
  //     ) {
  //       clearWalletConnect();
  //     }

  //     await signIn(connectorKey, () => {
  //       history.push(PATHS.settings());
  //       setStorageLogKey();
  //     });
  //   } catch (error: any) {
  //     if (!error.message.includes('Connection request reset')) {
  //       fracToast2.error('Failed to connect', 'Failed to connect');
  //     }
  //   } finally {
  //     dispatch(connectWalletPending(false));
  //   }
  // };

  return (
    <div className="login-page container">
      <div className="login-page__content">
        <img src={Logo} alt="logo" />
        {isConnecting || isConnected ? (
          <div className="connecting-wallet-modal">
            <div className="connecting-metamask">
              <div className="close-icon">
                <img
                  src={BlackCloseIcon}
                  alt="close"
                  onClick={() => disconnect()}
                />
              </div>
              <Spin
                indicator={<LoadingOutlined spin className="connect-loading" />}
              />
              <div className="connect-state">Connecting...</div>
              <div className="connect-description">
                Check your wallet for a connection request. Connecting to Axor
                Exchange is free and does not affect your funds
              </div>
              <ButtonComponent
                onClick={() => disconnect()}
                customClassName="action-btn"
                variant="secondary"
                text="Try again"
              />
            </div>
          </div>
        ) : (
          <div className="select-wallet-modal">
            <div className="title">Login Axor</div>
            <div className="button-connect-group">
              <Row gutter={[12, 12]} className="w-full">
                {/* <Col span={12}>
                  <ButtonComponent
                    onClick={() => handleClick(ConnectorKey.metaMask)}
                    customClassName="button-action"
                    variant="light"
                    text="MetaMask"
                    prefixIcon={<img src={MetamaskIcon} alt="metamask" />}
                  />
                </Col>
                <Col span={12}>
                  <ButtonComponent
                    onClick={() => handleClick(ConnectorKey.trustWallet)}
                    customClassName="button-action"
                    variant="light"
                    text="Trust"
                    prefixIcon={<img src={TrustIcon} alt="trust" />}
                  />
                </Col>
                <Col span={12}>
                  <ButtonComponent
                    onClick={() => handleClick(ConnectorKey.coinBase)}
                    customClassName="button-action"
                    variant="light"
                    text="CoinBase"
                    prefixIcon={<img src={CoinbaseIcon} alt="coinbase" />}
                  />
                </Col>
                <Col span={12}>
                  <ButtonComponent
                    onClick={() => handleClick(ConnectorKey.walletConnect)}
                    customClassName="button-action"
                    variant="light"
                    text="WalletConnect"
                    prefixIcon={
                      <img src={WalletConnectIcon} alt="wallet-icon" />
                    }
                  />
                </Col> */}
                <Col span={24}>
                  <ConnectButton />
                </Col>
              </Row>
            </div>
          </div>
        )}
        <ModalMetaMaskNotFound
          visible={visibleMetamaskNotFoundModal}
          toggleModal={toggleModalMetamaskNotFound}
        />
      </div>
    </div>
  );
};

export default Login;
