export const SET_CONNECTOR_NAME = 'CONNECTOR_NAME';
export const DISCONNECT_WALLET = 'DISCONNECT_WALLET';
const WRONG_NETWORK = 'WRONG_NETWORK';
const LOADING_NETWORK = 'LOADING_NETWORK';
const CONNECT_WALLET_PENDING = 'CONNECT_WALLET_PENDING';

export const walletTypes = {
  WRONG_NETWORK,
  CONNECT_WALLET_PENDING,
  LOADING_NETWORK,
};
