// import { useSelector } from 'react-redux';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRouter from 'src/routes/Routes';
import PrivateLayout from './PrivateLayout';
import PublicLayout from './PublicLayout';
import { PublicRouter } from './sidebar/constants/SidebarList';
import FracLoading from '../16.loading';
import { AuthProvider } from 'src/contexts/auth';

const LayoutComponent = () => {
  const { token } = useSelector((state: any) => state.auth);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Suspense fallback={<FracLoading />}>
          {token ? (
            <PrivateLayout>
              <PrivateRouter />
            </PrivateLayout>
          ) : (
            <PublicLayout>
              <Switch>
                {PublicRouter.map((route: any, index: number) => (
                  <Route
                    key={index}
                    path={route.subLink}
                    component={route.component}
                  />
                ))}
              </Switch>
            </PublicLayout>
          )}
        </Suspense>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default LayoutComponent;
