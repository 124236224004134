import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import moment from 'moment';

export const ellipseAddress = (
  address = '',
  maxCharacters = 5,
  maxLastCharacters?: number | undefined
): string => {
  if (!address) return '';

  return `${address.slice(0, maxCharacters)}...${address.slice(
    -(maxLastCharacters ? maxLastCharacters : maxCharacters)
  )}`;
};

export const sortDirection = (order: string) => {
  switch (order) {
    case 'descend':
      return -1;
    case 'ascend':
      return 1;
    default:
      return 1;
  }
};

export const toFixed = (value: string, number: number) => {
  return value?.toString().indexOf('.') === -1
    ? value
    : new BigNumber(value)
        .toFixed(number, 4)
        .replace(/(\.\d*?[1-9]|)\.?0+$/g, '$1');
};

export const takeDecimalNumber = (num: number, n: number) => {
  const base = 10 ** n;
  const result = Math.round(num * base) / base;
  return result;
};

export const autoScrollToErrorField = (errorFieldName: any) => {
  if (!!errorFieldName) {
    const element = document.querySelector(`.role-${errorFieldName}`);

    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};

export const checkIfValidHexColor = (value: string) => {
  return (
    /^#[0-9A-F]{6}$/i.test(value.trim()) ||
    /^#([0-9A-F]{3}){1,2}$/i.test(value.trim()) ||
    /^[0-9A-F]{6}$/i.test(value.trim()) ||
    /^([0-9A-F]{3}){1,2}$/i.test(value.trim())
  );
};

export const disableStartDate = (value?: any) => (current: any) => {
  const to = value;
  const currentDate = moment(Date.now()).startOf('day');
  if (to)
    return (
      current.clone().startOf('day').isAfter(to.clone().startOf('day')) ||
      current < currentDate
    );
  return current < currentDate;
};

export const disableEndDate = (value?: any) => (current: any) => {
  const from = value;
  const currentDate = moment(Date.now()).startOf('day');
  if (from)
    return (
      current.clone().startOf('day').isBefore(from.clone().startOf('day')) ||
      current < currentDate
    );
  return current < currentDate;
};

export const handleDisableStartDate = (
  currentDate: any,
  form: any,
  endDateName: string
) => {
  const endDate = form.getFieldValue(endDateName);
  if (endDate) {
    return (
      (currentDate && currentDate > endDate) ||
      currentDate > dayjs().endOf('day')
    );
  } else if (currentDate > dayjs().endOf('day')) return true;
  return false;
};

export const handleDisableEndDate = (
  currentDate: any,
  form: any,
  startDateName: string
) => {
  const startDate = form.getFieldValue(startDateName);
  if (startDate) {
    return (
      (currentDate && currentDate < startDate) ||
      currentDate > dayjs().endOf('day')
    );
  } else if (currentDate > dayjs().endOf('day')) return true;
  return false;
};

export const onKeyDown = (
  e: React.KeyboardEvent<HTMLDivElement>,
  interger?: number,
  decimal?: number,
  prefix = true
) => {
  const target = e.target as any;
  const acceptInput = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  const escapeInput = [
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    ...[prefix && '.'],
  ];
  const splitValue = target.value?.split('.');
  const indexof = target.value.indexOf('.');
  if (
    (target.value.includes('.') && e.key === '.') ||
    (e.key === '.' && !target.value)
  ) {
    e.preventDefault();
  }
  if (!escapeInput.includes(e.key)) {
    if (
      (splitValue[0].length === interger && target.selectionStart <= indexof) ||
      (splitValue[1]?.length === decimal && target.selectionStart > indexof) ||
      !acceptInput?.includes(e.key) ||
      (splitValue.length === 1 && splitValue[0].length === interger)
    ) {
      e.preventDefault();
    }
  }
};

export const trueTypeOf = (obj: any) =>
  Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

export const findPreviousElementArr = (
  inputArr: number[],
  currentElement: number
) => {
  const currentIndex = inputArr.indexOf(currentElement);
  if (currentIndex > 0) {
    return inputArr[currentIndex - 1];
  } else {
    return -1;
  }
};

export const findNextElementArr = (
  inputArr: number[],
  currentElement: number
) => {
  const currentIndex = inputArr.indexOf(currentElement);
  if (currentIndex !== -1 && currentIndex < inputArr.length - 1) {
    return inputArr[currentIndex + 1];
  } else {
    return -1;
  }
};

export const isStringifyJson = (data: any) => {
  if (!data) {
    return false;
  }
  const regex = new RegExp(/^{".*/g);

  return regex.test(data);
};
