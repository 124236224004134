export const PATHS = {
  settings: () => '/',

  coldWallet: () => '/cold-wallet',
  hotWallet: () => '/hot-wallet',

  userInformation: () => '/user-information',
  userSetting: () => '/user-setting',
  deviceManagement: () => '/device-management',
  restrictions: () => '/restrictions',

  transactionSetting: () => '/transaction-setting',
  tradingSetting: () => '/trading-setting',
  withdrawalSetting: () => '/withdrawal-setting',
  feeSetting: () => '/fee-setting',

  depositHistory: () => '/deposit-history',
  withdrawHistory: () => '/withdraw-history',
  otherHistory: () => '/other-history',

  orders: () => '/orders',
  positions: () => '/positions',
  trades: () => '/trades',

  instrument: () => '/instrument',

  login: () => '/login',

  notFound: () => '/404',
};
