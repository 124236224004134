import React, { useRef } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import { FormItemProps } from 'antd/lib/form';
import { FracInput, IFracInputProps } from '.';

interface ICustomFormItemInput extends FormItemProps<any> {
  form: FormInstance<any>;
  regex?: RegExp;
  inputProps?: IFracInputProps;
}

const CustomFormItemInput: React.FC<ICustomFormItemInput> = ({
  form,
  regex,
  inputProps,
  ...props
}) => {
  const inputRef = useRef('');
  const { name } = props;

  const handleCheckRegex = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (regex) {
      if (regex?.test(value)) {
        inputRef.current = value;
        form?.setFieldValue(name ?? '', value);
      } else {
        if (value === '') {
          inputRef.current = value;
          form?.setFieldValue(name ?? '', value);
          return;
        }
        if (value.length === 1) inputRef.current = '';
        form?.setFieldValue(name ?? '', inputRef.current);
      }
    }
  };

  return (
    <Form.Item {...props}>
      <FracInput {...inputProps} onChange={handleCheckRegex} />
    </Form.Item>
  );
};

export default CustomFormItemInput;
