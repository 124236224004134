const {
  REACT_APP_MESSAGES_SIGN,
  REACT_APP_CHAIN_ID,
  REACT_APP_NETWORK_NAME,
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_RPC_URL,
  REACT_APP_CHAIN_ID_HEX,
  REACT_APP_ETHERSCAN_EXPLORER_URL,
} = process.env;

export {
  REACT_APP_MESSAGES_SIGN,
  REACT_APP_CHAIN_ID,
  REACT_APP_NETWORK_NAME,
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_RPC_URL,
  REACT_APP_CHAIN_ID_HEX,
  REACT_APP_ETHERSCAN_EXPLORER_URL,
};
