import { ServiceBase } from './core/service-base';
import { SiteSettingApi } from './implements/implement-apis';
import {
  GeneralSettingParams,
  SocicalSettingParams,
} from './params-type/site-setting-type';

export class SiteSettingService extends ServiceBase implements SiteSettingApi {
  getGeneralSetting = () => {
    return this.get('admin/site-setting/general');
  };
  editGeneralSetting = (params: GeneralSettingParams) => {
    return this.post('/admin/site-setting/general', params);
  };
  getSocialSetting = () => {
    return this.get('admin/site-setting/social');
  };
  createNewSocial = (params: SocicalSettingParams) => {
    return this.post('/admin/site-setting/new-social', params);
  };
  updateSocial = (params: SocicalSettingParams) => {
    return this.put('/admin/site-setting/update-social/' + params?.id, params);
  };
  deleteSocial = (id: number) => {
    return this.delete('/admin/site-setting/delete-social', id || 0);
  };
}
