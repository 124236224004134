import { SET_CONNECTOR_NAME, DISCONNECT_WALLET, walletTypes } from '../constants/wallet';

const initialState = {
  connectorName: '',
  visibleConnectWallet: false,
  visibleWrongNetwork: false,
  loadingNetwork: false,
};

export const connectorNameReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CONNECTOR_NAME:
      return {
        ...state,
        connectorName: action.payload,
      };
    case DISCONNECT_WALLET:
      return {
        ...state,
        connectorName: action.payload,
      };

    case walletTypes.CONNECT_WALLET_PENDING: {
      return {
        ...state,
        visibleConnectWallet: action.payload,
      };
    }

    case walletTypes.WRONG_NETWORK: {
      return {
        ...state,
        visibleWrongNetwork: action.payload,
      };
    }
    case walletTypes.LOADING_NETWORK: {
      return {
        ...state,
        loadingNetwork: action.payload,
      };
    }
    default:
      return state;
  }
};
