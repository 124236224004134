import { initializeConnector } from '@web3-react/core';
import { WalletConnect, WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { ConnectorKey, walletKeys } from '.';
const RPC_URLS: { [chainId: number]: string } = {
  [process.env.REACT_APP_CHAIN_ID || 97]:
    process.env.REACT_APP_RPC_URL || 'https://bsc-testnet.blockpi.network/v1/rpc/public',
};

export const handleGenerateConnector = (connector: ConnectorKey) => {
  const [walletConnect, walletConnectHooks] = initializeConnector<WalletConnect>(
    (actions) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: process.env.WALLET_CONNECT_PROJECT_ID || '9d662d196fc0e42029d6f2d541260b94',
          chains: [Number(process.env.REACT_APP_CHAIN_ID || 97)],
          showQrModal: true,
          rpcMap: RPC_URLS,
          qrModalOptions: {
            explorerRecommendedWalletIds: [...walletKeys[connector]],
            explorerExcludedWalletIds: 'ALL',
          },
          optionalMethods: [
            'personal_sign',
            'eth_sendTransaction',
            'eth_accounts',
            'eth_requestAccounts',
            'eth_call',
            'eth_getBalance',
            'eth_sendRawTransaction',
            'eth_sign',
            'eth_signTransaction',
            'eth_signTypedData',
            'eth_signTypedData_v3',
            'eth_signTypedData_v4',
            'wallet_switchEthereumChain',
            'wallet_addEthereumChain',
            'wallet_getPermissions',
            'wallet_requestPermissions',
            'wallet_registerOnboarding',
            'wallet_watchAsset',
            'wallet_scanQRCode',
          ],
        },
      }),
  );
  return {
    walletConnect,
    walletConnectHooks,
  };
};
