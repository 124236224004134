import FracTable from 'src/components/05.table';
import { ColumnsType } from 'antd/es/table';
import EditIcon from 'src/assets/icons/common/edit-outline-icon.svg';
import AcceptIcon from 'src/assets/icons/common/accept-icon.svg';
import UserFilter, {
  DEFAULT_USER_FILTER,
  UserFilterParams,
} from './user-filter';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { PAGE_SIZE_OPTIONS, USER_STATUS2, USER_TYPE } from 'src/constants';
import {
  fetchUserInfoList,
  updateUserInfo,
} from 'src/store/actions/user-management';
import dayjs from 'dayjs';
import FracTooltip from 'src/components/19.tooltip';
import { ellipseAddress } from 'src/helpers';
import { DATE_FORMAT } from 'src/constants/formatters';
import FracSelect from 'src/components/11.select';
import { toast } from 'react-toastify';
import { formatRoundFloorDisplay } from 'src/helpers/formatNumber';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracModal from 'src/components/06.modals';
import CustomFormItemInput from 'src/components/12.inputs/FormItemInput';
import OverflowTooltip from 'src/components/17.overflow-tooltip';
import './styles.scss';

const UserInfomation = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const dispatch = useDispatch();

  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openRestrictionModal, setOpenRestrictionModal] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [editRecord, setEditRecord] = useState<number | undefined>();
  const [filterParams, setFilterParams] =
    useState<UserFilterParams>(DEFAULT_USER_FILTER);

  const userInfoData = useSelector(
    (state: any) => state.userManagement.userInfoData
  );

  useEffect(() => {
    setCurPage(1);
  }, [filterParams, pageSize]);

  const fetchUserInfoData = () => {
    setIsLoading(true);
    return fetchUserInfoList(
      {
        page: curPage,
        limit: pageSize,
        orderBy: 'created_at',
        direction: 'DESC',
        type:
          filterParams.type === USER_TYPE.ALL ? undefined : filterParams.type,
        userStatus:
          filterParams.userStatus === USER_STATUS2.ALL
            ? undefined
            : filterParams.userStatus,
        from: (
          dayjs(filterParams.from).startOf('day').unix() * 1000
        ).toString(),
        to: (dayjs(filterParams.to).endOf('day').unix() * 1000).toString(),
        walletAddress: filterParams.walletAddress?.length
          ? filterParams.walletAddress
          : undefined,
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    dispatch(fetchUserInfoData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, pageSize, curPage]);

  const closeRestrictionModal = () => {
    setOpenRestrictionModal(false);
    form2.setFieldValue('status', USER_STATUS2.ACTIVE);
    form2.setFields([
      {
        name: 'restrictionReason',
        value: null,
        errors: [],
      },
    ]);
  };

  const handleChangeStatusUser = (value: USER_STATUS2) => {
    if (value === USER_STATUS2.INACTIVE) {
      setOpenRestrictionModal(true);
      form2.setFieldValue('restrictionReason', null);
    } else form2.setFieldValue('restrictionReason', null);
  };

  const handleUpdateUserInfo = (data: any) => {
    if (editRecord)
      dispatch(
        updateUserInfo(
          {
            id: editRecord,
            ...data,
            restrictionReason: data?.restrictionReason
              ? data.restrictionReason.trim()
              : null,
          },
          () => {
            toast.success('Updated successfully');
            dispatch(fetchUserInfoData());
          },
          () => toast.error('Updated failed')
        )
      );
    setEditRecord(undefined);
    form2.setFieldValue('restrictionReason ', '');
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      render: (text: string, record: any, index: number) => (
        <span>{record?.id}</span>
      ),
    },
    {
      title: 'Wallet Address',
      render: (record: any) => (
        <FracTooltip title={record?.wallet_address}>
          <span>{ellipseAddress(record?.wallet_address || '', 6, 4)}</span>
        </FracTooltip>
      ),
    },
    {
      title: 'Total Balance',
      render: (record: any) => (
        <span>{formatRoundFloorDisplay(record?.totalBalance)}</span>
      ),
    },
    {
      title: 'Available balance',
      render: (record: any) => (
        <span>{formatRoundFloorDisplay(record?.availableBalance)}</span>
      ),
    },
    {
      title: 'Coin',
      render: (record: any) => (
        <OverflowTooltip
          title={(record?.coin || '').toUpperCase().replace(/,/g, ', ')}
        >
          <span className="w-220 text-overflow">
            {(record?.coin || '').toUpperCase().replace(/,/g, ', ')}
          </span>
        </OverflowTooltip>
      ),
    },
    {
      title: 'Type',
      render: (record: any) => {
        if (record?.id !== editRecord)
          return (
            <span className="text-capitalize input-data">
              {(record?.type || '').toLowerCase()}
            </span>
          );
        else
          return (
            <Form.Item name="type">
              <FracSelect
                className="input-data"
                placeholder="User Type"
                options={[
                  { label: 'Bot', value: USER_TYPE.BOT },
                  { label: 'Normal', value: USER_TYPE.NORMAL },
                ]}
              />
            </Form.Item>
          );
      },
    },
    {
      title: 'Status',
      render: (record: any) => {
        if (record?.id !== editRecord)
          return (
            <span className="text-capitalize input-data">
              {(record?.status || '').toLowerCase()}
            </span>
          );
        else
          return (
            <Form.Item name="status">
              <FracSelect
                className="input-data"
                placeholder="User Status"
                onChange={handleChangeStatusUser}
                options={[
                  { label: 'Active', value: USER_STATUS2.ACTIVE },
                  { label: 'Inactive', value: USER_STATUS2.INACTIVE },
                ]}
              />
            </Form.Item>
          );
      },
    },
    {
      title: 'Date',
      render: (record: any) => (
        <span className="text-nowrap">
          {dayjs(record?.created_at).format(DATE_FORMAT)}
        </span>
      ),
    },
    {
      width: 24,
      align: 'right',
      render: (record: any) => (
        <>
          {record?.id === editRecord ? (
            <img
              src={AcceptIcon}
              alt="accept"
              className="action-btn"
              onClick={() => form2.submit()}
            />
          ) : (
            <img
              src={EditIcon}
              alt="edit"
              className="action-btn"
              onClick={() => {
                setEditRecord(record?.id);
                form2.setFieldsValue(record);
              }}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <div className="user-info">
      <h1 className="user-info-title">User information</h1>
      <UserFilter
        form={form1}
        isLoading={isLoading}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
      <Form
        form={form2}
        className="user-info-wrapper user-info-table"
        onFinish={handleUpdateUserInfo}
      >
        <FracTable
          showTotal
          columns={columns}
          totalText="Total Users"
          isLoading={isLoading}
          dataSource={userInfoData?.data || []}
          pagination={{
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            pageSize: pageSize,
            showSizeChanger: true,
            total: userInfoData?.metadata?.total,
            current: curPage,
            onShowSizeChange(current, size) {
              setPageSize(size);
            },
            onChange(page, pageSize) {
              setCurPage(page);
            },
          }}
        />
      </Form>
      <FracModal
        visible={openRestrictionModal}
        wrapClassName="add-restriction-modal"
        onCancel={closeRestrictionModal}
        //   iconClose={IconClose}
        width={448}
        maskClosable={true}
        centered
      >
        <div className="add-modal-wrapper">
          <Form
            form={form2}
            layout="vertical"
            className="input-group"
            onFinish={handleUpdateUserInfo}
          >
            <CustomFormItemInput
              form={form2}
              label="Restriction reasoning"
              rules={[
                ({ getFieldValue, setFields }) => ({
                  validator: (_, value) => {
                    const statusValue = getFieldValue('status');
                    if (
                      (!value || value.trim().length === 0) &&
                      statusValue === USER_STATUS2.INACTIVE
                    )
                      return Promise.reject('This field is required');
                    return Promise.resolve();
                  },
                }),
              ]}
              name="restrictionReason"
              regex={/^.{0,255}$/}
              inputProps={{
                placeholder: 'Please input reason for restriction',
              }}
            />
          </Form>
          <div className="button-group">
            <ButtonComponent
              text="Cancel"
              variant="default"
              customClassName="cancel-btn"
              onClick={closeRestrictionModal}
            />
            <ButtonComponent
              text="Save"
              variant="primary"
              onClick={() => {
                form2.validateFields().then(() => {
                  form2.submit();
                  setOpenRestrictionModal(false);
                });
              }}
            />
          </div>
        </div>
      </FracModal>
    </div>
  );
};

export default UserInfomation;
