import { ColumnsType } from 'antd/es/table';
import { memo, useState } from 'react';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracTable from 'src/components/05.table';
import PlusIcon from 'src/assets/icons/common/plus-icon.svg';
import RemoveIcon from 'src/assets/icons/common/remove-icon.svg';
import ModalConfirm from 'src/components/06.modals/ModalConfirm';
import FracModal from 'src/components/06.modals';
import { Form } from 'antd';
import { requiredRule } from 'src/constants/rule';
import { RestrictionInfo } from 'src/services/params-type/user-management-type';
import { Country } from 'src/constants';
import FracSelect from 'src/components/11.select';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
  handleCreateRestriction,
  handleDeleteRestriction,
} from 'src/store/actions/user-management';
import './styles.scss';

interface IProps {
  isLoading: boolean;
  data: RestrictionInfo[];
}

const RestrictionByLocation: React.FC<IProps> = ({ data, isLoading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<RestrictionInfo>({
    ipOrLocation: '',
    type: 0,
  });

  const closeAddModal = () => {
    setOpenAddModal(false);
    form.resetFields();
  };

  const handleDeleteLocation = () => {
    const onSuccess = () => {
      toast.success('Deleted restriction successfully');
      setOpenRemoveModal(false);
    };

    const onFailure = () => {
      toast.error('Deleted restriction failed');
      setOpenRemoveModal(false);
    };

    dispatch(handleDeleteRestriction(selectedRecord, onSuccess, onFailure));
  };

  const handleAddRestrictionLocation = (data: RestrictionInfo) => {
    const submitData = {
      ipOrLocation: data?.ipOrLocation,
      type: 1,
    };

    const onSuccess = () => {
      toast.success('Added restriction successfully');
    };

    const onFailure = (err: any) => {
      if (err?.response?.status === 400)
        toast.error('This location is already restricted');
      else toast.error('Added restriction failed');
    };

    dispatch(handleCreateRestriction(submitData, onSuccess, onFailure));

    closeAddModal();
  };

  const columns: ColumnsType<any> = [
    {
      title: 'No',
      render: (record: RestrictionInfo, text: string, index: number) => (
        <span>{index + 1}</span>
      ),
    },
    {
      title: 'Location',
      render: (record: RestrictionInfo) => {
        const convertedValue =
          Object.keys(Country)[
            Object.values(Country).indexOf(
              record?.ipOrLocation as unknown as Country
            )
          ] || '';
        return (
          <span className="text-capitalize">
            {convertedValue.replace(/_/g, ' ').toLowerCase()}
          </span>
        );
      },
    },
    {
      title: (
        <ButtonComponent
          customClassName="add-row-btn"
          text="Add"
          variant="primary"
          onClick={() => setOpenAddModal(true)}
          prefixIcon={<img src={PlusIcon} alt="plus" />}
        />
      ),
      width: 82,
      align: 'right',
      render: (record: RestrictionInfo) => (
        <img
          src={RemoveIcon}
          alt="remove"
          className="action-btn"
          onClick={() => {
            setSelectedRecord(record);
            setOpenRemoveModal(true);
          }}
        />
      ),
    },
  ];

  const capitalizeString = (location: string) => {
    return location
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  };

  return (
    <>
      <h2 className="common-subtitle">Restriction by location</h2>
      <div className="common-wrapper">
        <FracTable
          columns={columns}
          isLoading={isLoading}
          dataSource={data || []}
        />
      </div>
      <ModalConfirm
        visible={openRemoveModal}
        description="Do you want to delete selected location?"
        cancelText="No"
        submitText="Yes"
        onCancel={() => setOpenRemoveModal(false)}
        onSubmit={handleDeleteLocation}
      />
      <FracModal
        visible={openAddModal}
        wrapClassName="add-restriction-modal"
        onCancel={() => closeAddModal()}
        //   iconClose={IconClose}
        width={448}
        maskClosable={true}
        centered
      >
        <div className="add-modal-wrapper">
          <Form
            form={form}
            layout="vertical"
            className="input-group"
            onFinish={handleAddRestrictionLocation}
          >
            <Form.Item
              label="Location restriction"
              rules={requiredRule()}
              name="ipOrLocation"
            >
              <FracSelect
                showSearch
                filterOption={(input, option) => {
                  return ((option?.label as string) || '')
                    .toLowerCase()
                    .includes((input || '').toLowerCase());
                }}
                options={
                  Object.keys(Country).map((country) => {
                    let validCountry: keyof typeof Country;
                    validCountry = country as any;
                    return {
                      value: Country[validCountry],
                      label: capitalizeString(country),
                    };
                  }) as any
                }
                placeholder="Please select country"
              />
            </Form.Item>
          </Form>
          <div className="button-group">
            <ButtonComponent
              text="Cancel"
              variant="default"
              customClassName="cancel-btn"
              onClick={() => closeAddModal()}
            />
            <ButtonComponent
              text="Save"
              variant="primary"
              onClick={() => form.submit()}
            />
          </div>
        </div>
      </FracModal>
    </>
  );
};

export default memo(RestrictionByLocation);
