import { useWeb3React } from '@web3-react/core';
import jwt_decode from 'jwt-decode';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fracToast2 } from 'src/components/07.toast';
import {
  getStorageJwtToken,
  hasStorageJwtToken,
  removeStorageJwtToken,
} from 'src/helpers/storage';
import { useLogin } from 'src/hooks/useLogin';
import eventBus from 'src/socket/event-bus';
import { setToken } from 'src/store/actions/auth';
import { ConnectorKey } from 'src/web3/connectors';
import {
  useConnectWallet,
  useEagerConnect,
  useWalletListener,
} from 'src/web3/hooks';

type JWT_DECODE = {
  exp: number;
  iat: number;
  role: number;
  sub: number;
};

export const authContext = React.createContext<
  | {
      isAuthChecking: boolean;
      isAuth: boolean;
      signIn: (connector: ConnectorKey, callback: () => void) => Promise<void>;
      signOut: VoidFunction;
      hasSigned: VoidFunction;
    }
  | undefined
>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { isActive } = useWeb3React();
  const { disconnectWallet } = useConnectWallet();
  const triedEagerConnect = useEagerConnect();
  useWalletListener(triedEagerConnect);

  const { userLogin } = useLogin();
  const dispatch = useDispatch();

  const [userLogined, setUserLogined] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(handleExpireDate, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  function handleExpireDate() {
    const isLogined = hasStorageJwtToken();
    let isExpired = false;
    if (isLogined) {
      const decoded = jwt_decode<JWT_DECODE>(getStorageJwtToken() as string);
      const expiredTimeServer = moment(decoded?.exp * 1000);
      isExpired = moment().isAfter(expiredTimeServer);
      if (isExpired) {
        removeStorageJwtToken();
        dispatch(setToken({ isAdmin: false, token: '', address: '' }));
      }
      setUserLogined(isExpired ? false : true);
    } else {
      setUserLogined(isLogined);
    }
  }

  async function signIn(
    connectorKey: ConnectorKey,
    connectedCallBack: () => void
  ) {
    // Ignore signing message
    if (!hasStorageJwtToken()) {
      await userLogin(connectorKey, connectedCallBack);
      setUserLogined(true);
    }
  }

  function signOut() {
    removeStorageJwtToken();
    disconnectWallet();
  }

  function hasSigned() {
    handleExpireDate();
    setUserLogined(true);
  }

  useEffect(() => {
    const handleWalletWhenAccessTokenExpired = debounce(() => {
      if (hasStorageJwtToken()) {
        fracToast2.error('', 'Token expired');
      }
      signOut();
    }, 2000);

    eventBus.on(`access_token_expired`, handleWalletWhenAccessTokenExpired);

    return () => {
      eventBus.remove(
        `access_token_expired`,
        handleWalletWhenAccessTokenExpired
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <authContext.Provider
      value={{
        isAuthChecking: !triedEagerConnect,
        isAuth: userLogined,
        signIn,
        signOut,
        hasSigned,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
