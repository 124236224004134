import { Web3ReactProvider } from '@web3-react/core';
import 'antd/dist/antd.variable.min.css';
import BigNumber from 'bignumber.js';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import 'src/assets/scss/_themes.scss';
import 'src/assets/scss/variable.scss';
import './App.scss';
import initStore from './store';

import '@ant-design/flowchart/dist/index.css';
import { ConfigProvider } from 'antd';
import LayoutComponent from './components/01.layout';
import './index.scss';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { appConnectors } from './web3/connectors';

import {
  getDefaultConfig,
  RainbowKitProvider
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import {
  coinbaseWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { APP_NETWORKS_SUPPORT } from './web3/constants/networks';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-50, 50],
});

ConfigProvider.config({
  theme: {
    primaryColor: '#4249FD',
  },
});

// const connectors = connectorsForWallets(
//   [
//     {
//       groupName: 'Recommended',
//       wallets: [
//         metaMaskWallet,
//         trustWallet,
//         coinbaseWallet,
//         walletConnectWallet,
//       ],
//     },
//     {
//       groupName: 'Other',
//       wallets: [rainbowWallet],
//     },
//   ],
//   {
//     appName: 'Axor App',
//     projectId: '8216390abe94d1f1921399538eb0c3b0',
//   }
// );
const rpcUrl = process.env.REACT_APP_RPC_URL as string;


const chain = {
  id: Number(process.env.REACT_APP_CHAIN_ID),
  name: APP_NETWORKS_SUPPORT[Number(process.env.REACT_APP_CHAIN_ID)].details.chainName, // Provide the appropriate chain name
  nativeCurrency: {
    name: APP_NETWORKS_SUPPORT[Number(process.env.REACT_APP_CHAIN_ID)].details.nativeCurrency.name,
    symbol:
      APP_NETWORKS_SUPPORT[Number(process.env.REACT_APP_CHAIN_ID)].details.nativeCurrency.symbol,
    decimals:
      APP_NETWORKS_SUPPORT[Number(process.env.REACT_APP_CHAIN_ID)].details.nativeCurrency.decimals,
  },
  rpcUrls: {
    default: {
      http: [rpcUrl],
    },
  },
};

// const foundNetwork = networks.find(
//   (network) => Number(network.id) === Number(process.env.REACT_APP_CHAIN_ID),
// );

// @ts-ignore:next-line
const config = getDefaultConfig({
  appName: 'Axor',
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!,
  chains: [chain],
  ssr: false, // If your dApp uses server side rendering (SSR)
  wallets: [
    {
      groupName: 'Popular',
      wallets: [metaMaskWallet, walletConnectWallet, coinbaseWallet, trustWallet],
    },
  ],
});

const App: React.FC = () => {
  const { store, persistor } = initStore();

  const queryClient = new QueryClient();

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Web3ReactProvider connectors={appConnectors}>
            <PersistGate persistor={persistor} loading={null}>
              <Provider store={store}>
                <ConfigProvider>
                  <ToastContainer />
                  <LayoutComponent />
                </ConfigProvider>
              </Provider>
            </PersistGate>
          </Web3ReactProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
