import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { UserManagementService } from 'src/services/user-management';
import {
  RestrictionInfo,
  GetUserInfoListParams,
  UpdateUserInfoParams,
} from 'src/services/params-type/user-management-type';
import {
  SET_RESTRICTION_LIST,
  SET_USER_ADDRESS_LIST,
  SET_USER_DEVICE_LIST,
  SET_USER_INFO_LIST,
} from '../constants/user-management';

export const setUserInfoList = (payload: any) => {
  return {
    type: SET_USER_INFO_LIST,
    payload,
  };
};

export const setUserAddressList = (payload: any) => {
  return {
    type: SET_USER_ADDRESS_LIST,
    payload,
  };
};

export const setUserDiviceList = (payload: any) => {
  return {
    type: SET_USER_DEVICE_LIST,
    payload,
  };
};

export const setRestrictionList = (payload: any) => {
  return {
    type: SET_RESTRICTION_LIST,
    payload,
  };
};

//User infomation
export const fetchUserInfoList = (
  params: GetUserInfoListParams,
  callBack?: any
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new UserManagementService();
    try {
      const res: any = await services.getUserInfoList(params);
      if (res.data)
        dispatch(setUserInfoList({ data: res?.data, metadata: res?.metadata }));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const updateUserInfo = (
  params: UpdateUserInfoParams,
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new UserManagementService();
    try {
      const res: any = await services.updateUserInfo(params);
      if (res && onSuccess) onSuccess();
    } catch (err: any) {
      if (onError) onError();
    }
  };
};

//Device management
export const fetchUserAddressList = (
  params: GetUserInfoListParams,
  callBack?: any
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new UserManagementService();
    try {
      const res: any = await services.getUserAddressList(params);
      if (res.data)
        dispatch(
          setUserAddressList({ data: res?.data, metadata: res?.metadata })
        );
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const fetchUserDiviceList = (userAddress: string, callBack?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new UserManagementService();
    try {
      const res: any = await services.getUserDeviceList(userAddress);
      if (res.data)
        dispatch(
          setUserDiviceList({ data: res?.data, metadata: res?.metadata })
        );
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const handleDeleteUserDevice = (
  id: number,
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new UserManagementService();
    try {
      const res: any = await services.deleteDevice(id);
      if (res && onSuccess) onSuccess();
    } catch (err: any) {
      if (onError) onError();
    }
  };
};

//Restriction
export const fetchRestrictionList = (callBack?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new UserManagementService();
    try {
      const res: any = await services.getRestrictionList();
      if (res.data) dispatch(setRestrictionList(res?.data));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const handleCreateRestriction = (
  params: RestrictionInfo,
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new UserManagementService();
    try {
      const res: any = await services.addRestriction(params);
      if (res && onSuccess) {
        onSuccess();
        const res: any = await services.getRestrictionList();
        if (res.data) dispatch(setRestrictionList(res?.data));
      }
    } catch (err: any) {
      if (onError) onError(err);
    }
  };
};

export const handleDeleteRestriction = (
  params: RestrictionInfo,
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new UserManagementService();
    try {
      const res: any = await services.deleteRestriction(params);
      if (res && onSuccess) {
        onSuccess();
        const res: any = await services.getRestrictionList();
        if (res.data) dispatch(setRestrictionList(res?.data));
      }
    } catch (err: any) {
      if (onError) onError();
    }
  };
};
