import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import { useDispatch } from 'react-redux';
import { fracToast2 } from 'src/components/07.toast';
import {
  setStorageJwtRefreshToken,
  setStorageJwtToken,
  userAddress,
} from 'src/helpers/storage';
import { handleLogin, setToken } from 'src/store/actions/auth';
import { ConnectorKey, connectors } from 'src/web3/connectors';
import { REACT_APP_MESSAGES_SIGN } from 'src/web3/constants/envs';
import { CONNECTOR_KEY } from 'src/web3/constants/storages';
import { signMessage } from 'src/web3/helpers';
import { useConnectWallet } from 'src/web3/hooks';

export const apiEndPoint = process.env.REACT_APP_API_URL;

export const useLogin = () => {
  const dispatch = useDispatch();
  const { connectWallet, disconnectWallet } = useConnectWallet();

  const getAccountConnected = async (provider: Web3Provider) => {
    const signer = provider.getSigner();
    const account = await signer?.getAddress();
    return account;
  };

  const getSignature = async (provider: Web3Provider) => {
    const message = `${REACT_APP_MESSAGES_SIGN}`;
    const signer = provider?.getSigner();
    const signature = await signMessage(signer, message);
    return {
      message,
      signature,
    };
  };

  const resetStore = (accessToken: string, accountSelected: string) => {
    dispatch(setToken({ token: accessToken, address: accountSelected }));
  };

  const userLogin = async (
    connectorKey: ConnectorKey,
    connectedCallback: () => void
  ) => {
    try {
      const connector = connectors[connectorKey];
      await connectWallet(connectorKey);
      const provider = new Web3Provider(
        connector.provider! as ExternalProvider
      );
      const accountSelected = await getAccountConnected(provider);
      const { signature } = await getSignature(provider);
      dispatch(
        handleLogin({
          data: { walletAddress: accountSelected, signature },
          callbackSuccess: (data: any) => {
            connectedCallback();
            fracToast2.success(
              'Connected successfully',
              'Connected successfully'
            );
            const accessToken = data?.accessToken;
            const refreshToken = data?.refreshToken;
            resetStore(accessToken, accountSelected);
            setStorageJwtToken(accessToken);
            setStorageJwtRefreshToken(refreshToken);
            localStorage.setItem(userAddress, accountSelected);
            localStorage.setItem(CONNECTOR_KEY, connectorKey);
          },
          callbackFail: () => {
            disconnectWallet(false);
            fracToast2.error('Failed to connect', 'Failed to connect');
          },
        })
      );
    } catch (error: any) {
      localStorage.removeItem(userAddress);
      resetStore('', '');
      disconnectWallet(false);
      fracToast2.error('Failed to connect', 'Failed to connect');
      throw error;
    }
  };

  return { userLogin };
};
