import { useWeb3React } from '@web3-react/core';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { REACT_APP_CHAIN_ID, REACT_APP_CHAIN_ID_HEX } from '../constants/envs';
import { useConnectWallet } from './useConnectWallet';

export function useWalletListener(tried: boolean) {
  const { account, connector, chainId, isActivating } = useWeb3React();
  const { disconnectWallet } = useConnectWallet();
  const accountRef = useRef<string>();
  const firstLoad = useRef<boolean>(false);

  const userLoading = useSelector(
    (state: any) => state.connectorName.visibleConnectWallet
  );

  const handleChainChange = (chainHex: any) => {
    if (
      chainHex === REACT_APP_CHAIN_ID_HEX ||
      chainHex === Number(REACT_APP_CHAIN_ID)
    ) {
      return;
    }
    if (
      (chainHex !== REACT_APP_CHAIN_ID_HEX ||
        chainHex !== REACT_APP_CHAIN_ID) &&
      !userLoading
    ) {
      disconnectWallet();
    }
  };

  useEffect(() => {
    if (account) {
      accountRef.current = account;
    }
  }, [account]);

  const handleAccountChange = (accounts: string[]) => {
    if (accounts[0] !== accountRef.current) {
      disconnectWallet();
    }
  };

  useEffect(() => {
    if (!isActivating && tried) {
      setTimeout(() => {
        if (!accountRef.current) {
          disconnectWallet(false);
        }
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActivating, tried]);

  useEffect(() => {
    if (
      chainId &&
      chainId !== Number(REACT_APP_CHAIN_ID) &&
      !firstLoad.current &&
      !userLoading
    ) {
      disconnectWallet();
    }
    if (chainId) {
      firstLoad.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, userLoading]);

  useEffect(() => {
    if (account && connector && connector.provider) {
      connector.provider?.on('chainChanged', handleChainChange);
      connector.provider?.on('accountsChanged', handleAccountChange);
    } else {
      connector.provider?.removeListener('chainChanged', handleChainChange);
      connector.provider?.removeListener(
        'accountsChanged',
        handleAccountChange
      );
    }

    return () => {
      connector.provider?.removeListener('chainChanged', disconnectWallet);
      connector.provider?.removeListener(
        'accountsChanged',
        handleAccountChange
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector.provider, account, userLoading]);
}
