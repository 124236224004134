export const DEFAULT_MODAL_WIDTH = 448; // unit pixcel
export const METAMASK_DEEPLINK = 'https://metamask.io/download';

export const PAGE_SIZE_OPTIONS = [10, 20, 50];
export const PAGE_SIZE_DEFAULT = 10;

export const STORAGE_KEY = {
  ACCESS_TOKEN: 'axor_access_token',
  REFRESH_TOKEN: 'axor_refresh_token',
  WALLET_ADDRESS: 'address',
};

export enum Country {
  AFGHANISTAN = 'AF',
  ALBANIA = 'AL',
  ALGERIA = 'DZ',
  AMERICAN_SAMOA = 'AS',
  ANDORRA = 'AD',
  ANGOLA = 'AO',
  ANGUILLA = 'AI',
  ANTARCTICA = 'AQ',
  ANTIGUA_AND_BARBUDA = 'AG',
  ARGENTINA = 'AR',
  ARMENIA = 'AM',
  ARUBA = 'AW',
  ASIA_PACIFIC_REGION = 'AP',
  AUSTRALIA = 'AU',
  AUSTRIA = 'AT',
  AZERBAIJAN = 'AZ',
  BAHAMAS = 'BS',
  BAHRAIN = 'BH',
  BANGLADESH = 'BD',
  BARBADOS = 'BB',
  BELARUS = 'BY',
  BELGIUM = 'BE',
  BELIZE = 'BZ',
  BENIN = 'BJ',
  BERMUDA = 'BM',
  BHUTAN = 'BT',
  BOLIVIA = 'BO',
  BONAIRE_SINT_EUSTATIUS_AND_SABA = 'BQ',
  BOSNIA_AND_HERZEGOVINA = 'BA',
  BOTSWANA = 'BW',
  BOUVET_ISLAND = 'BV',
  BRAZIL = 'BR',
  BRITISH_INDIAN_OCEAN_TERRITORY = 'IO',
  BRUNEI_DARUSSALAM = 'BN',
  BULGARIA = 'BG',
  BURKINA_FASO = 'BF',
  BURUNDI = 'BI',
  CAMBODIA = 'KH',
  CAMEROON = 'CM',
  CANADA = 'CA',
  CAPE_VERDE = 'CV',
  CAYMAN_ISLANDS = 'KY',
  CENTRAL_AFRICAN_REPUBLIC = 'CF',
  CHAD = 'TD',
  CHILE = 'CL',
  CHINA = 'CN',
  CHRISTMAS_ISLAND = 'CX',
  COCOS_KEELING_ISLANDS = 'CC',
  COLOMBIA = 'CO',
  COMOROS = 'KM',
  CONGO = 'CG',
  CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE = 'CD',
  COOK_ISLANDS = 'CK',
  COSTA_RICA = 'CR',
  CROATIA = 'HR',
  CUBA = 'CU',
  CURAÇAO = 'CW',
  CYPRUS = 'CY',
  CZECH_REPUBLIC = 'CZ',
  CÔTE_DIVOIRE = 'CI',
  DENMARK = 'DK',
  DJIBOUTI = 'DJ',
  DOMINICA = 'DM',
  DOMINICAN_REPUBLIC = 'DO',
  ECUADOR = 'EC',
  EGYPT = 'EG',
  EL_SALVADOR = 'SV',
  EQUATORIAL_GUINEA = 'GQ',
  ERITREA = 'ER',
  ESTONIA = 'EE',
  ETHIOPIA = 'ET',
  FALKLAND_ISLANDS_MALVINAS = 'FK',
  FAROE_ISLANDS = 'FO',
  FIJI = 'FJ',
  FINLAND = 'FI',
  FRANCE = 'FR',
  FRENCH_GUIANA = 'GF',
  FRENCH_POLYNESIA = 'PF',
  FRENCH_SOUTHERN_TERRITORIES = 'TF',
  GABON = 'GA',
  GAMBIA = 'GM',
  GEORGIA = 'GE',
  GERMANY = 'DE',
  GHANA = 'GH',
  GIBRALTAR = 'GI',
  GREECE = 'GR',
  GREENLAND = 'GL',
  GRENADA = 'GD',
  GUADELOUPE = 'GP',
  GUAM = 'GU',
  GUATEMALA = 'GT',
  GUERNSEY = 'GG',
  GUINEA = 'GN',
  GUINEA_BISSAU = 'GW',
  GUYANA = 'GY',
  HAITI = 'HT',
  HEARD_ISLAND_AND_MCDONALD_ISLANDS = 'HM',
  HOLY_SEE_VATICAN_CITY_STATE = 'VA',
  HONDURAS = 'HN',
  HONG_KONG = 'HK',
  HUNGARY = 'HU',
  ICELAND = 'IS',
  INDIA = 'IN',
  INDONESIA = 'ID',
  IRAN_ISLAMIC_REPUBLIC_OF = 'IR',
  IRAQ = 'IQ',
  IRELAND = 'IE',
  ISLE_OF_MAN = 'IM',
  ISRAEL = 'IL',
  ITALY = 'IT',
  JAMAICA = 'JM',
  JAPAN = 'JP',
  JERSEY = 'JE',
  JORDAN = 'JO',
  KAZAKHSTAN = 'KZ',
  KENYA = 'KE',
  KIRIBATI = 'KI',
  KOREA_REPUBLIC_OF = 'KR',
  KUWAIT = 'KW',
  KYRGYZSTAN = 'KG',
  LAOS = 'LA',
  LATVIA = 'LV',
  LEBANON = 'LB',
  LESOTHO = 'LS',
  LIBERIA = 'LR',
  LIBYAN_ARAB_JAMAHIRIYA = 'LY',
  LIECHTENSTEIN = 'LI',
  LITHUANIA = 'LT',
  LUXEMBOURG = 'LU',
  MACAO = 'MO',
  MADAGASCAR = 'MG',
  MALAWI = 'MW',
  MALAYSIA = 'MY',
  MALDIVES = 'MV',
  MALI = 'ML',
  MALTA = 'MT',
  MARSHALL_ISLANDS = 'MH',
  MARTINIQUE = 'MQ',
  MAURITANIA = 'MR',
  MAURITIUS = 'MU',
  MAYOTTE = 'YT',
  MEXICO = 'MX',
  MICRONESIA_FEDERATED_STATES_OF = 'FM',
  MOLDOVA_REPUBLIC_OF = 'MD',
  MONACO = 'MC',
  MONGOLIA = 'MN',
  MONTENEGRO = 'ME',
  MONTSERRAT = 'MS',
  MOROCCO = 'MA',
  MOZAMBIQUE = 'MZ',
  MYANMAR = 'MM',
  NAMIBIA = 'NA',
  NAURU = 'NR',
  NEPAL = 'NP',
  NETHERLANDS = 'NL',
  NETHERLANDS_ANTILLES = 'AN',
  NEW_CALEDONIA = 'NC',
  NEW_ZEALAND = 'NZ',
  NICARAGUA = 'NI',
  NIGER = 'NE',
  NIGERIA = 'NG',
  NIUE = 'NU',
  NORFOLK_ISLAND = 'NF',
  NORTH_KOREA = 'KP',
  NORTH_MACEDONIA = 'MK',
  NORTHERN_MARIANA_ISLANDS = 'MP',
  NORWAY = 'NO',
  OMAN = 'OM',
  PAKISTAN = 'PK',
  PALAU = 'PW',
  PALESTINIAN_TERRITORY_OCCUPIED = 'PS',
  PANAMA = 'PA',
  PAPUA_NEW_GUINEA = 'PG',
  PARAGUAY = 'PY',
  PERU = 'PE',
  PHILIPPINES = 'PH',
  PITCAIRN_ISLANDS = 'PN',
  POLAND = 'PL',
  PORTUGAL = 'PT',
  PUERTO_RICO = 'PR',
  QATAR = 'QA',
  REUNION = 'RE',
  ROMANIA = 'RO',
  RUSSIAN_FEDERATION = 'RU',
  RWANDA = 'RW',
  SAINT_BARTHÉLEMY = 'BL',
  SAINT_HELENA = 'SH',
  SAINT_KITTS_AND_NEVIS = 'KN',
  SAINT_LUCIA = 'LC',
  SAINT_MARTIN = 'MF',
  SAINT_PIERRE_AND_MIQUELON = 'PM',
  SAINT_VINCENT_AND_THE_GRENADINES = 'VC',
  SAMOA = 'WS',
  SAN_MARINO = 'SM',
  SAO_TOME_AND_PRINCIPE = 'ST',
  SAUDI_ARABIA = 'SA',
  SENEGAL = 'SN',
  SERBIA = 'RS',
  SERBIA_AND_MONTENEGRO = 'CS',
  SEYCHELLES = 'SC',
  SIERRA_LEONE = 'SL',
  SINGAPORE = 'SG',
  SINT_MAARTEN = 'SX',
  SLOVAKIA = 'SK',
  SLOVENIA = 'SI',
  SOLOMON_ISLANDS = 'SB',
  SOMALIA = 'SO',
  SOUTH_AFRICA = 'ZA',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'GS',
  SOUTH_SUDAN = 'SS',
  SPAIN = 'ES',
  SRI_LANKA = 'LK',
  SUDAN = 'SD',
  SURINAME = 'SR',
  SVALBARD_AND_JAN_MAYEN = 'SJ',
  SWAZILAND = 'SZ',
  SWEDEN = 'SE',
  SWITZERLAND = 'CH',
  SYRIAN_ARAB_REPUBLIC = 'SY',
  TAIWAN = 'TW',
  TAJIKISTAN = 'TJ',
  TANZANIA_UNITED_REPUBLIC_OF = 'TZ',
  THAILAND = 'TH',
  TIMOR_LESTE = 'TL',
  TOGO = 'TG',
  TOKELAU = 'TK',
  TONGA = 'TO',
  TRINIDAD_AND_TOBAGO = 'TT',
  TUNISIA = 'TN',
  TURKEY = 'TR',
  TURKMENISTAN = 'TM',
  TURKS_AND_CAICOS_ISLANDS = 'TC',
  TUVALU = 'TV',
  UGANDA = 'UG',
  UKRAINE = 'UA',
  UNITED_ARAB_EMIRATES = 'AE',
  UNITED_KINGDOM = 'GB',
  UNITED_STATES = 'US',
  UNITED_STATES_MINOR_OUTLYING_ISLANDS = 'UM',
  URUGUAY = 'UY',
  UZBEKISTAN = 'UZ',
  VANUATU = 'VU',
  VENEZUELA = 'VE',
  VIETNAM = 'VN',
  VIRGIN_ISLANDS_BRITISH = 'VG',
  VIRGIN_ISLANDS_US = 'VI',
  WALLIS_AND_FUTUNA = 'WF',
  WESTERN_SAHARA = 'EH',
  YEMEN = 'YE',
  ZAMBIA = 'ZM',
  ZIMBABWE = 'ZW',
  ÅLAND_ISLANDS = 'AX',
}

export enum TRANSACTION_TYPE {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAWAL',
  FUNDING_FEE = 'FUNDING_FEE',
  TRADING_FEE = 'TRADING_FEE',
  REALIZED_PNL = 'REALIZED_PNL',
  LIQUIDATION_CLEARANCE = 'LIQUIDATION_CLEARANCE',
}

export enum ORDER_SIDE {
  ALL = 'ALL',
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum ORDER_TYPE {
  ALL = 'ALL',
  LIMIT = 'LIMIT',
  MARKET = 'MARKET',
  STOP_LIMIT = 'STOP_LIMIT',
  STOP_MARKET = 'STOP_MARKET',
  TRAILING_STOP = 'TRAILING_STOP',
  LIQUIDATION = 'LIQUIDATION',
}

export enum SOCIAL_TYPE {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  TELEGRAM = 'TELEGRAM',
  LINKEDIN = 'LINKEDIN',
  YOUTUBE = 'YOUTUBE',
  WHATSAPP = 'WHATSAPP',
  REDDIT = 'REDDIT',
  INSTAGRAM = 'INSTAGRAM',
  GOOGLE_PLUS = 'GOOGLE_PLUS',
  WECHAT = 'WECHAT',
}

export enum SOCIAL_ICON {
  FACEBOOK = 'icon-facebook',
  TWITTER = 'icon-twitter',
  TELEGRAM = 'icon-telegram',
  LINKEDIN = 'icon-linkedin',
  YOUTUBE = 'icon-youtube',
  WHATSAPP = 'icon-whatsapp',
  REDDIT = 'icon-reddit',
  INSTAGRAM = 'icon-instagram',
  GOOGLE_PLUS = 'icon-googleplus',
  WECHAT = 'icon-wechat',
}

export enum USER_STATUS2 {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum USER_TYPE {
  ALL = 'ALL',
  BOT = 'BOT',
  NORMAL = 'NORMAL',
}

export enum INSTRUMENT_FIELD {
  NAME = 'name',
  COLLATERAL_ASSET = 'collateralAsset',
  MAKER_FEE = 'makerFee',
  TAKER_FEE = 'takerFee',
  LIMIT_ORDER_PRICE = 'limitOrderPrice',
  FLOOR_RATIO = 'floorRatio',
  TICK_SIZE = 'tickSize',
  MIN_PRICE = 'minPrice',
  MAX_PRICE = 'maxPrice',
  MIN_ORDER_AMOUNT = 'minOrderAmount',
  MAX_ORDER_AMOUNT = 'maxOrderAmount',
  MAX_FIGURES_FOR_SIZE = 'maxFiguresForSize',
  MAX_FIGURES_FOR_PRICE = 'maxFiguresForPrice',
  LIQ_CLEARANCE_FEE = 'liqClearanceFee',
  LEVERAGE = 'leverage',
  MAX_LEVERAGE = 'maxLeverageTrade',
  MAX_NOTIONAL = 'maxNotional',
  MIN_NOTIONAL = 'minNotional',
  MIN_PRICE_MOVEMENT = 'minPriceMovement',
  IMPACT_INPUT = 'impactInput',
}

export enum TIER_FIELD {
  MIN = 'min',
  MAX = 'max',
  MAX_LEVERAGE = 'maxLeverageMargin',
  MAINTENANCE_AMOUNT = 'maintenanceAmount',
  MAINTENANCE_MARGIN_RATE = 'maintenanceMarginRate',
}
