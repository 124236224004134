import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Header from 'src/components/01.layout/header';
import { useSessionStorage } from 'src/hooks/useSessionStorage';
import { useConnectWallet, useEagerConnect } from 'src/web3/hooks';
import Sidebar from './sidebar';
import './styles.scss';

const { Content } = Layout;

interface ILayoutProps {
  children?: React.ReactNode;
}

export enum ThemesMode {
  dark = 'dark',
  light = 'light',
}

const PrivateLayout: React.FC<ILayoutProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [theme, setTheme] = useSessionStorage('theme', ThemesMode.dark);
  const { disconnectWallet } = useConnectWallet();
  const { token } = useSelector((state: any) => state.auth);

  useEagerConnect();
  useEffect(() => {
    if (!token) {
      disconnectWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div id="layout" className={theme}>
      <Layout className="container">
        <Sidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <Header
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            theme={theme}
            setTheme={setTheme}
          />
          <Content className="site-layout-background">{children}</Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default PrivateLayout;
