import { Form } from 'antd';
import FracDatePicker from 'src/components/20.datePicker';
import FracSelect from 'src/components/11.select';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';

import { ORDER_SIDE, ORDER_TYPE } from 'src/constants';

import dayjs from 'dayjs';
import { FormInstance } from 'antd/es/form';
import { useEffect, useState } from 'react';
import { DATE_FORMAT } from 'src/constants/formatters';
import { handleDisableEndDate, handleDisableStartDate } from 'src/helpers';
import SearchSymbolInput from 'src/components/21.common-filter/search-symbol-input';
import './styles.scss';

export type OrderFilterParams = {
  startTime?: any;
  endTime?: any;
  side?: ORDER_SIDE;
  type?: ORDER_TYPE;
  symbol?: string;
};

export const DEFAULT_ORDER_FILTER: OrderFilterParams = {
  startTime: dayjs().subtract(7, 'day'),
  endTime: dayjs(),
  side: ORDER_SIDE.ALL,
  type: ORDER_TYPE.ALL,
  symbol: '',
};

interface IProps {
  form: FormInstance<any>;
  isLoading: boolean;
  filterParams: OrderFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<OrderFilterParams>>;
}

const OrderFilter: React.FC<IProps> = ({
  form,
  isLoading,
  filterParams,
  setFilterParams,
}) => {
  const [searchText, setSearchText] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    form.setFieldsValue(filterParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  const sideOptions = [
    { label: 'All', value: ORDER_SIDE.ALL },
    { label: 'Buy', value: ORDER_SIDE.BUY },
    { label: 'Sell', value: ORDER_SIDE.SELL },
  ];

  const typeOptions = [
    { label: 'All', value: ORDER_TYPE.ALL },
    { label: 'Limit', value: ORDER_TYPE.LIMIT },
    { label: 'Market', value: ORDER_TYPE.MARKET },
    { label: 'Stop Limit', value: ORDER_TYPE.STOP_LIMIT },
    { label: 'Stop Market', value: ORDER_TYPE.STOP_MARKET },
    { label: 'Trailing Stop', value: ORDER_TYPE.TRAILING_STOP },
    { label: 'Liquidation', value: ORDER_TYPE.LIQUIDATION },
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      className="order-filter"
      onFinish={(data) => setFilterParams(data)}
    >
      <Form.Item label="From" name="startTime">
        <FracDatePicker
          format={DATE_FORMAT}
          disabledDate={(curDate: any) =>
            handleDisableStartDate(curDate, form, 'endTime')
          }
        />
      </Form.Item>
      <Form.Item label="To" name="endTime">
        <FracDatePicker
          format={DATE_FORMAT}
          disabledDate={(curDate: any) =>
            handleDisableEndDate(curDate, form, 'startTime')
          }
        />
      </Form.Item>
      <Form.Item label="Side" name="side">
        <FracSelect options={sideOptions} />
      </Form.Item>
      <Form.Item label="Type" name="type">
        <FracSelect
          options={typeOptions}
          popupClassName="order-type-dropdown"
        />
      </Form.Item>
      <Form.Item name="symbol" className="no-display" />
      <SearchSymbolInput
        showError={showError}
        setShowError={setShowError}
        searchText={searchText}
        setSearchText={setSearchText}
        placeholder="Search by symbol"
        onSelectItem={(symbol) => form.setFieldValue('symbol', symbol)}
      />

      <ButtonComponent
        text="Search"
        variant="primary"
        loading={isLoading}
        onClick={() => form.submit()}
        customClassName="common-button"
      />
      <ButtonComponent
        text="Reset"
        variant="default"
        customClassName="common-button"
        onClick={() => {
          setSearchText('');
          setShowError(false);
          form.setFieldsValue(DEFAULT_ORDER_FILTER);
          setFilterParams(DEFAULT_ORDER_FILTER);
        }}
      />
    </Form>
  );
};

export default OrderFilter;
