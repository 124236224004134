import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  SET_GENERAL_SETTING,
  SET_SOCIAL_SETTING,
} from '../constants/site-setting';
import {
  GeneralSettingParams,
  SocicalSettingParams,
} from 'src/services/params-type/site-setting-type';
import { SiteSettingService } from 'src/services/site-setting';

export const setGeneralSetting = (payload: any) => {
  return {
    type: SET_GENERAL_SETTING,
    payload,
  };
};

export const setSocialSetting = (payload: any) => {
  return {
    type: SET_SOCIAL_SETTING,
    payload,
  };
};

export const fetchGeneralSetting = (callBack?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new SiteSettingService();
    try {
      const res: any = await services.getGeneralSetting();
      if (res.data) dispatch(setGeneralSetting(res?.data));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const handleEditGeneralSetting = (
  params: GeneralSettingParams,
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new SiteSettingService();
    try {
      const res: any = await services.editGeneralSetting(params);
      if (res && onSuccess) {
        onSuccess();
        dispatch(setGeneralSetting(res?.data));
      }
    } catch (err: any) {
      if (onError) onError();
    }
  };
};

export const fetchSocialSetting = (callBack?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new SiteSettingService();
    try {
      const res: any = await services.getSocialSetting();
      if (res.data) dispatch(setSocialSetting(res?.data));
      if (res && callBack) callBack(res.data);
    } catch (err) {
      console.log('err', err);
    }
  };
};

export const handleCreateNewSocial = (
  params: SocicalSettingParams,
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new SiteSettingService();
    try {
      const res: any = await services.createNewSocial(params);
      if (res && onSuccess) {
        onSuccess();
        const res: any = await services.getSocialSetting();
        if (res.data) dispatch(setSocialSetting(res?.data));
      }
    } catch (err: any) {
      if (onError) onError();
    }
  };
};

export const handleUpdateSocial = (
  params: SocicalSettingParams,
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new SiteSettingService();
    try {
      const res: any = await services.updateSocial(params);
      if (res && onSuccess) {
        onSuccess();
        const res: any = await services.getSocialSetting();
        if (res.data) dispatch(setSocialSetting(res?.data));
      }
    } catch (err: any) {
      if (onError) onError();
    }
  };
};

export const handleDeleteSocial = (
  id: number,
  onSuccess?: Function,
  onError?: Function
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const services = new SiteSettingService();
    try {
      const res: any = await services.deleteSocial(id);
      if (res && onSuccess) {
        onSuccess();
        const res: any = await services.getSocialSetting();
        if (res.data) dispatch(setSocialSetting(res?.data));
      }
    } catch (err: any) {
      if (onError) onError();
    }
  };
};
