import FracTabs, { IFracTabPaneProps } from 'src/components/04.tabs';
import InstrumentForm from '../instrument-form';
import TierForm from '../tier-form';
import { memo, useState } from 'react';
import { Form } from 'antd';
import './styles.scss';
import { InstrumentParams } from 'src/services/params-type/instrument';
import { INSTRUMENT_FIELD } from 'src/constants';

interface IProps {
  instrumentInfo: any;
  setIsViewInstrument: React.Dispatch<React.SetStateAction<boolean>>;
}

enum TAB_KEY {
  INSTRUMENTS = 'instruments',
  TRADING_TIER = 'trading-tier',
}

const AddInstrument: React.FC<IProps> = ({
  instrumentInfo,
  setIsViewInstrument,
}) => {
  const [form] = Form.useForm();

  const [curTab, setCurTab] = useState(TAB_KEY.INSTRUMENTS);
  const [instrumentSubmitData, setInstrumentSubmitData] =
    useState<InstrumentParams>();
  
  const handleValidateInstrument = (data: any) => {
    const rootSymbol = data.underlyingSymbol.split('/')[0].toUpperCase()
    setInstrumentSubmitData({
      id: instrumentInfo?.id || 0,
      instrument: {
        name: data.name,
        symbol: instrumentInfo?.symbol || '',
        rootSymbol: rootSymbol,
        contractAddress: data.contractAddress,
        makerOracleAddress: data.makerOracleAddress,
        orderAddress: data.orderAddress,
        liquidationAddress: data.liquidationAddress,
        contractType: 'USD_M',
        underlyingSymbol: data.underlyingSymbol,
        makerFee: data.makerFee || '0',
        takerFee: data.takerFee || '0',
        tickSize: data.tickSize,
        maxPrice: data.maxPrice,
        minPriceMovement: data.minPriceMovement,
        maxFiguresForSize: data.maxFiguresForSize,
        maxFiguresForPrice: data.maxFiguresForPrice,
        impactMarginNotional: data.impactMarginNotional,
        maintenanceMarginRate: data.maintenanceMarginRate,
        multiplier: 1,
      },
      tradingRules: {
        minPrice: data.minPrice,
        limitOrderPrice: data.limitOrderPrice,
        floorRatio: data.floorRatio,
        minOrderAmount: data.minOrderAmount,
        maxOrderAmount: data.maxOrderAmount,
        minNotional: data.minNotional,
        maxNotional: data.maxNotional,
        liqClearanceFee: '0',
        maxLeverage: Number(data.maxLeverageTrade || '0'),
      },
    });

    setCurTab(TAB_KEY.TRADING_TIER);
  };

  const tabs: IFracTabPaneProps[] = [
    {
      title: 'Instruments',
      tabKey: TAB_KEY.INSTRUMENTS,
      content: (
        <InstrumentForm
          form={form}
          instrumentInfo={instrumentInfo}
          onSubmit={handleValidateInstrument}
        />
      ),
    },
    {
      title: 'Trading Tier',
      tabKey: TAB_KEY.TRADING_TIER,
      content: (
        <TierForm
          isEditInstrument={!!instrumentInfo}
          setIsViewInstrument={setIsViewInstrument}
          instrumentSubmitData={instrumentSubmitData as InstrumentParams}
        />
      ),
    },
  ];

  return (
    <div className="add-instrument">
      <FracTabs
        tabs={tabs}
        activeKey={curTab}
        onChange={(selectedKey) => {
          if (selectedKey === TAB_KEY.TRADING_TIER) {
            if (!!instrumentInfo) {
              const currentNameValue =
                form.getFieldValue(INSTRUMENT_FIELD.NAME) || '';
              if (currentNameValue.trim().length !== 0)
                handleValidateInstrument(instrumentInfo);
            } else form.submit();
          } else setCurTab(TAB_KEY.INSTRUMENTS);
        }}
        transparent
      />
    </div>
  );
};

export default memo(AddInstrument);
