import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './styles.scss';

interface ILayoutProps {
  children?: React.ReactNode;
}

const PublicLayout: React.FC<ILayoutProps> = ({ children }) => {
  const { token } = useSelector((state: any) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  }, [token, history]);

  return (
    <div>
      <Layout className="container">
        <Content className="site-layout-background">{children}</Content>
      </Layout>
    </div>
  );
};

export default PublicLayout;
