import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { STORAGE_KEY } from "src/constants";
import { AuthServices } from "../auth-service";
import { isStringifyJson } from "src/helpers";

export class HttpClient {
  axiosInstance: AxiosInstance;

  constructor() {
    const tokenAccess = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    let configs: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + tokenAccess,
      },
      timeout: 30000,
      transformRequest: [
        (data, headers) => {
          if (data instanceof FormData) {
            if (headers) {
              delete headers["Content-Type"];
            }
            return data;
          }
          return JSON.stringify(data);
        },
      ],
    };

    this.axiosInstance = axios.create(configs);

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        const isRefreshTokenErrorApi =
          error.config.url.includes("refresh-token");
        const refreshToken = localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);
        if (
          error?.response?.status === 401 &&
          !originalRequest._retry &&
          !isRefreshTokenErrorApi &&
          refreshToken
        ) {
          originalRequest._retry = true;

          try {
            if (refreshToken) {
              const authService = new AuthServices();
              const response = await authService.refreshToken({
                refreshToken: refreshToken || "",
              });
              const accessToken = response?.data?.accessToken;
              this.axiosInstance.defaults.headers.common["Authorization"] =
                "Bearer " + accessToken;
              originalRequest.headers["Authorization"] = accessToken;

              localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);

              //this checking is because previous data request is converted to string and need to check and convert to Object before passing to new request
              const isInvalidJsonData = isStringifyJson(originalRequest?.data);
              if (isInvalidJsonData) {
                return this.axiosInstance({
                  ...originalRequest,
                  data: JSON.parse(originalRequest.data),
                });
              }
              return this.axiosInstance(originalRequest);
            } else {
              (window as any).disconnect();
              (window as any).handleShowExpiredTokenToast &&
                (window as any).handleShowExpiredTokenToast();
            }
          } catch (error) {
            if ((window as any).disconnect) {
              (window as any).disconnect();
              (window as any).handleShowExpiredTokenToast &&
                (window as any).handleShowExpiredTokenToast();
            }
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
        if (accessToken && config.headers) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}
