import {
  SET_GENERAL_SETTING,
  SET_SOCIAL_SETTING,
} from '../constants/site-setting';

const initialState = {
  generalSetting: {},
  socialSetting: [],
};

export const siteSettingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_GENERAL_SETTING: {
      return {
        ...state,
        generalSetting: action.payload,
      };
    }
    case SET_SOCIAL_SETTING: {
      return {
        ...state,
        socialSetting: action.payload,
      };
    }

    default:
      return state;
  }
};
