import { Form } from 'antd';
import { FracInput } from 'src/components/12.inputs';
import FracDatePicker from 'src/components/20.datePicker';
import FracSelect from 'src/components/11.select';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';

import SeachIcon from 'src/assets/icons/common/search-icon.svg';

import { USER_STATUS2, USER_TYPE } from 'src/constants';
import dayjs from 'dayjs';
import { FormInstance } from 'antd/es/form';
import { useEffect, useMemo } from 'react';
import { DATE_FORMAT } from 'src/constants/formatters';
import { handleDisableEndDate, handleDisableStartDate } from 'src/helpers';
import { debounce } from 'lodash';

import { DEBOUNCE_SEARCH_TIME } from 'src/constants/time';
import './styles.scss';

export type UserFilterParams = {
  from?: any;
  to?: any;
  userStatus?: USER_STATUS2;
  type?: USER_TYPE;
  walletAddress?: string;
};

export const DEFAULT_USER_FILTER: UserFilterParams = {
  from: dayjs().subtract(7, 'day'),
  to: dayjs(),
  userStatus: USER_STATUS2.ALL,
  type: USER_TYPE.ALL,
  walletAddress: '',
};

interface IProps {
  isLoading?: boolean;
  form: FormInstance<any>;
  filterParams: UserFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<UserFilterParams>>;
}

const UserFilter: React.FC<IProps> = ({
  form,
  filterParams,
  setFilterParams,
  isLoading = false,
}) => {
  useEffect(() => {
    form.setFieldsValue(filterParams);
  }, [form, filterParams]);

  const typeOptions = [
    { label: 'All', value: USER_TYPE.ALL },
    { label: 'Bot', value: USER_TYPE.BOT },
    { label: 'Normal', value: USER_TYPE.NORMAL },
  ];

  const statusOptions = [
    { label: 'All', value: USER_STATUS2.ALL },
    { label: 'Active', value: USER_STATUS2.ACTIVE },
    { label: 'Inactive', value: USER_STATUS2.INACTIVE },
  ];

  const debounceHandler = useMemo(
    () => debounce(() => form.submit(), DEBOUNCE_SEARCH_TIME),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleInputChange = () => {
    debounceHandler();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="user-filter"
      onFinish={(data) => setFilterParams(data)}
    >
      <Form.Item label="From" name="from">
        <FracDatePicker
          format={DATE_FORMAT}
          disabledDate={(curDate: any) =>
            handleDisableStartDate(curDate, form, 'to')
          }
        />
      </Form.Item>
      <Form.Item label="To" name="to">
        <FracDatePicker
          format={DATE_FORMAT}
          disabledDate={(curDate: any) =>
            handleDisableEndDate(curDate, form, 'from')
          }
        />
      </Form.Item>
      <Form.Item label="Type" name="type">
        <FracSelect
          options={typeOptions}
          defaultValue="All"
          onChange={() => form.submit()}
        />
      </Form.Item>
      <Form.Item label="Status" name="userStatus">
        <FracSelect
          options={statusOptions}
          defaultValue="All"
          onChange={() => form.submit()}
        />
      </Form.Item>
      <Form.Item name="walletAddress">
        <FracInput
          placeholder="Search by wallet address"
          onChange={handleInputChange}
          prefix={<img src={SeachIcon} alt="search" />}
        />
      </Form.Item>

      <ButtonComponent
        text="Search"
        variant="primary"
        loading={isLoading}
        customClassName="common-button"
        onClick={() => form.submit()}
      />
      <ButtonComponent
        text="Reset"
        variant="default"
        customClassName="common-button"
        onClick={() => {
          form.setFieldsValue(DEFAULT_USER_FILTER);
          setFilterParams(DEFAULT_USER_FILTER);
        }}
      />
    </Form>
  );
};

export default UserFilter;
