import { ServiceBase } from './core/service-base';
import {
  InstrumentParams,
  GetInstrumentListParams,
} from './params-type/instrument';

export class InstrumentService extends ServiceBase {
  getInstrumentList = (params: GetInstrumentListParams) => {
    return this.get('admin/instruments', params);
  };
  getCoinList = (search: string) => {
    return this.get('admin/instruments/symbol', { search });
  };
  getSymbolList = (symbol: string) => {
    return this.get('admin/instruments/instruments-symbol', { search: symbol });
  };
  getTradingTier = (symbol: string) => {
    const encodedSymbol = encodeURIComponent(symbol);
    return this.get('admin/instruments/list-trading-tier/' + encodedSymbol);
  };
  createNewInstrument = (body: InstrumentParams) => {
    return this.post('admin/instruments', body);
  };
  updateInstrument = (body: { id: number; name: string }) => {
    return this.patch('admin/instruments/' + body?.id, body);
  };
}
