import React, { ReactNode } from 'react';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracModal from 'src/components/06.modals';
import IconClose from 'src/assets/icons/common/icon-close.svg';
import ConfirmIcon from 'src/assets/icons/common/confirm-icon.svg';
import './styles/confirm-modal.scss';

interface IProps {
  iconSrc?: string;
  title?: string;
  description?: string | ReactNode;
  visible: boolean;
  width?: any;
  cancelText?: string;
  submitText?: string;
  onCancel: () => void;
  onSubmit: () => void;
}

const ModalConfirm: React.FC<IProps> = ({
  title,
  iconSrc,
  cancelText,
  submitText,
  description,
  visible,
  onCancel,
  onSubmit,
}) => {
  return (
    <FracModal
      visible={visible}
      wrapClassName="confirm-modal"
      onCancel={onCancel}
      iconClose={IconClose}
      width={414}
      maskClosable={false}
      centered
    >
      <div className="confirm-modal-wrapper">
        <img src={iconSrc || ConfirmIcon} alt="confirm" className="icon" />
        <div className="text-group">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
        <div className="button-group">
          <ButtonComponent
            text={cancelText || 'Close'}
            variant="default"
            customClassName="cancel-btn"
            onClick={onCancel}
          />
          <ButtonComponent
            text={submitText || 'Save'}
            variant="primary"
            onClick={onSubmit}
          />
        </div>
      </div>
    </FracModal>
  );
};

export default ModalConfirm;
