import { Col, Row } from 'antd';
import RestrictionByLocation from './components/restriction-by-location';
import RestrictionByIp from './components/restriction-by-ip';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchRestrictionList } from 'src/store/actions/user-management';
import { RestrictionList } from 'src/services/params-type/user-management-type';

const Restriction = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const restrictionData: RestrictionList = useSelector(
    (state: any) => state.userManagement.restrictionData
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchRestrictionList(() => setIsLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="device-management">
      <h1 className="common-title">Restriction</h1>
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <RestrictionByLocation
            isLoading={isLoading}
            data={restrictionData.restrictionsByLocation}
          />
        </Col>
        <Col span={16}>
          <RestrictionByIp
            isLoading={isLoading}
            data={restrictionData.restrictionsByIp}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Restriction;
