import { Col, Form, Row, Space, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { fracToast2 } from 'src/components/07.toast';
import CustomFormItemInput from 'src/components/12.inputs/FormItemInput';
import { GeneralSettingParams } from 'src/services/params-type/site-setting-type';
import {
  fetchGeneralSetting,
  handleEditGeneralSetting,
} from 'src/store/actions/site-setting';
import './styles.scss';

const GeneralSetting = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [isMaintenance, setIsMaintenance] = useState(false)
  const generalSetting = useSelector(
    (state: any) => state.siteSettings.generalSetting
  );

  useEffect(() => {
    dispatch(fetchGeneralSetting());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue(generalSetting);
    setIsMaintenance(generalSetting.isMaintenance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSetting]);

  const convertValue = (value: string) => {
    return value ? value : null;
  };

  const onSubmit = (data: GeneralSettingParams) => {
    const submitData: any = {
      email: convertValue(data?.email),
      // androidAppLink: convertValue(data?.androidAppLink),
      // copyright: convertValue(data?.copyright),
      // iosAppLink: convertValue(data?.iosAppLink),
      // phoneNumber: convertValue(data?.phoneNumber),
      isMaintenance: data?.isMaintenance
    };

    dispatch(
      handleEditGeneralSetting(
        submitData,
        () => fracToast2.success('Save settings successfully',),
        () => toast.error('Save settings failed')
      )
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      className="general-setting common-wrapper"
    >
      <div className="title">General</div>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <CustomFormItemInput
            form={form}
            label="Email"
            name="email"
            regex={/^.{0,100}$/}
            rules={[
              {
                type: 'email',
                message: 'Invalid email address',
              },
            ]}
            inputProps={{ placeholder: 'Enter Email' }}
          />
        </Col>
        {/* <Col span={8}>
          <CustomFormItemInput
            form={form}
            label="Phone Number"
            name="phoneNumber"
            regex={/^[0-9+ ]{0,15}$/}
            inputProps={{ placeholder: 'Enter Phone Number' }}
          />
        </Col>
        <Col span={8}>
          <CustomFormItemInput
            form={form}
            label="Copyright"
            name="copyright"
            regex={/^.{0,100}$/}
            inputProps={{ placeholder: 'Enter Copyright' }}
          />
        </Col> */}

        <Col span={8}>
          <Form.Item label="Maintenance" name="isMaintenance">
            <Switch checked={isMaintenance} onChange={(value)=>setIsMaintenance(value)}/>
          </Form.Item>
        </Col>
      </Row>

      {/* <div className="title">Mobile application</div>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <CustomFormItemInput
            form={form}
            label="IOS App Link"
            name="iosAppLink"
            rules={[
              {
                type: 'url',
                message: 'Invalid URL',
              },
            ]}
            inputProps={{ placeholder: 'Enter IOS App Link' }}
          />
        </Col>
        <Col span={8}>
          <CustomFormItemInput
            form={form}
            label="Android App Link"
            name="androidAppLink"
            rules={[
              {
                type: 'url',
                message: 'Invalid URL',
              },
            ]}
            inputProps={{ placeholder: 'Enter Android App Link' }}
          />
        </Col>

        <Col span={8}>
          <Form.Item label="Maintenance" name="isMaintenance">
            <Switch checked={isMaintenance} onChange={(value)=>setIsMaintenance(value)}/>
          </Form.Item>
        </Col>
      </Row> */}

      <Space size={16}>
        <ButtonComponent
          text="Reset"
          variant="default"
          onClick={() => form.resetFields()}
        />
        <ButtonComponent
          customClassName="btn-search"
          text="Done"
          variant="primary"
          onClick={() => form.submit()}
        />
      </Space>
    </Form>
  );
};

export default memo(GeneralSetting);
