import { Table, TableProps } from 'antd';
import React from 'react';
import { NoResultIcon } from 'src/assets/icons';
import ButtonOutLined from '../02.buttons/ButtonOutLined';
import './styles.scss';
import FracLoading from '../16.loading';

interface IFracTableProps extends TableProps<any> {
  isLoading?: boolean;
  showAddButton?: boolean;
  onClickAdd?: () => void;
  hidePagination?: boolean;
  className?: string;
  emptyText?: any;
  showTotal?: boolean;
  totalText?: string;
}

const FracTable: React.FC<IFracTableProps> = (props: IFracTableProps) => {
  const {
    scroll,
    columns,
    dataSource,
    pagination,
    locale,
    onClickAdd,
    isLoading = false,
    showTotal = false,
    totalText = 'Total',
    showAddButton = false,
    hidePagination = false,
    className,
    emptyText,
  } = props;

  const itemRender = (
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactNode
  ) => {
    // if (type === 'prev') {
    //   // eslint-disable-next-line
    //   return <a>{`< `}</a>;
    // }
    // if (type === 'next') {
    //   // eslint-disable-next-line
    //   return <a>{` >`}</a>;
    // }
    return originalElement;
  };

  const defaultEmpty = () => {
    return (
      <div className="table-empty">
        {emptyText ? (
          emptyText
        ) : (
          <>
            <img src={NoResultIcon} alt="empty-icon" />
            <div>No result</div>
            {showAddButton && (
              <ButtonOutLined className="button-add" onClick={onClickAdd}>
                Add
              </ButtonOutLined>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="frac-table">
      <Table
        showSorterTooltip={{}}
        columns={columns}
        className={`${className}`}
        rowKey={(record) => record._id}
        {...props}
        dataSource={!isLoading ? dataSource : []}
        pagination={
          isLoading
            ? undefined
            : pagination && !hidePagination
            ? {
                ...pagination,
                showTotal(total) {
                  if (!showTotal) return null;
                  return (
                    <span>
                      {totalText}: {total}
                    </span>
                  );
                },
                itemRender,
                position: ['bottomCenter'],
              }
            : false
        }
        locale={
          locale
            ? locale
            : { emptyText: isLoading ? <FracLoading /> : defaultEmpty }
        }
        scroll={scroll || { x: '100%' }}
      />
    </div>
  );
};

export default FracTable;
