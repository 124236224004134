import { FormInstance } from 'antd/es/form';
import { Rule } from 'antd/lib/form';
import BigNumber from 'bignumber.js';
import { findNextElementArr } from 'src/helpers';
import { formatRoundFloorDisplay } from 'src/helpers/formatNumber';
import Web3 from 'web3';
import { INSTRUMENT_FIELD } from '.';

export const cleanNumber = (value: string | number) => {
  if (!value) return 0;
  const convertedValue = value.toString().replace(/[^0-9.]/g, '');
  return Number(convertedValue);
};

export const cleanNumberStr = (value: string | number) => {
  if (!value) return '';
  return value.toString().replace(/[^0-9.]/g, '');
};

export const requiredRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      if (!value || value.trim().length === 0)
        return Promise.reject('This field is required');
      return Promise.resolve();
    },
  }),
];

export const tickSizeRule: (
  setTickSizeList: React.Dispatch<React.SetStateAction<string>>,
  form: FormInstance
) => Rule[] = (setTickSizeList, form) => [
  ({ getFieldValue, setFields }) => ({
    validator: (_) => {
      const minPriceMovement = getFieldValue(
        INSTRUMENT_FIELD.MIN_PRICE_MOVEMENT
      );
      const value = form.getFieldValue('tickSize');
      if (!value) {
        setTickSizeList('N/A');
        return Promise.reject('This field is required');
      }

      //display tick size list
      const convertedValue = cleanNumberStr(value);

      if (convertedValue.length > 0 && convertedValue !== '.') {
        let curValue = BigNumber(convertedValue);
        let result = formatRoundFloorDisplay(curValue, 8) + '/';

        for (let i = 1; i < 4; i++) {
          curValue = BigNumber(curValue).times(10);
          result = result + formatRoundFloorDisplay(curValue, 8 - i);
          if (i !== 3) result += '/';
        }

        setTickSizeList(result);
      } else setTickSizeList('N/A');

      if (!minPriceMovement) return Promise.resolve();
      if (Number(convertedValue) < Number(minPriceMovement))
        return Promise.reject(
          'Min tick size must be higher than Minimum price movement'
        );

      return Promise.resolve();
    },
  }),
];

export const minPriceRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      const maxPrice = getFieldValue(INSTRUMENT_FIELD.MAX_PRICE);
      if (!value) return Promise.reject('This field is required');
      if (!maxPrice) return Promise.resolve();
      if (cleanNumber(value) >= cleanNumber(maxPrice)) {
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_PRICE,
            errors: ['Max price must be higher than min price'],
          },
        ]);
        return Promise.resolve();
      } else {
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_PRICE,
            errors: [],
          },
        ]);
        return Promise.resolve();
      }
    },
  }),
];

export const maxPriceRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      const minPrice = getFieldValue(INSTRUMENT_FIELD.MIN_PRICE);
      if (!value) return Promise.reject('This field is required');
      if (!minPrice) Promise.resolve();
      if (cleanNumber(value) <= cleanNumber(minPrice))
        return Promise.reject('Max price must be higher than min price');

      return Promise.resolve();
    },
  }),
];

export const trimDecimalPlaces = (
  curValue: string = '',
  minPriceMovement: string = ''
) => {
  const curValueStr = curValue.toString();
  const minPriceMovementStr = minPriceMovement.toString();
  const decimalPosition = minPriceMovementStr.indexOf('.');

  if (decimalPosition === -1) {
    return { result: curValue, numberDecimal: 0 };
  }

  const decimalDigits = minPriceMovementStr.length - decimalPosition - 1;
  const sliceIndex = curValueStr.indexOf('.') + decimalDigits + 1;
  let trimmedCurValue = curValueStr;
  if (curValueStr.includes('.'))
    trimmedCurValue = curValueStr.slice(0, sliceIndex);

  return { result: trimmedCurValue, numberDecimal: Number(decimalDigits) };
};

export const maxFiguresForPriceRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      const minPriceMovement = getFieldValue(
        INSTRUMENT_FIELD.MIN_PRICE_MOVEMENT
      );
      if (!value) return Promise.reject('This field is required');
      const cleanValue = cleanNumberStr(value);
      if (!minPriceMovement) return Promise.resolve();
      const { numberDecimal } = trimDecimalPlaces(
        cleanValue,
        cleanNumberStr(minPriceMovement)
      );
      if (Number(cleanValue) > numberDecimal) {
        return Promise.reject(
          'The value must be equal or less than the number of decimal figures in the Minimum price movement'
        );
      }
      return Promise.resolve();
    },
  }),
];

export const maxLeverageRule: (
  setImpactMargin: React.Dispatch<React.SetStateAction<string>>
) => Rule[] = (setImpactMargin) => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      if (!value) return Promise.reject('Required field');
      let maxLeverage = cleanNumber(value);
      if (maxLeverage === 0) {
        maxLeverage = 1;
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_LEVERAGE,
            value: 1,
          },
        ]);
      }

      if (maxLeverage > 200) {
        maxLeverage = 200;
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_LEVERAGE,
            value: 200,
          },
        ]);
      }

      const impactInput = getFieldValue(INSTRUMENT_FIELD.IMPACT_INPUT);
      if (impactInput) {
        setImpactMargin(
          formatRoundFloorDisplay(Number(impactInput) / maxLeverage, 2)
        );
      }

      return Promise.resolve();
    },
  }),
];

export const maxOrderAmountRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      const minOrderAmount = getFieldValue(INSTRUMENT_FIELD.MIN_ORDER_AMOUNT);
      if (!value) return Promise.reject('This field is required');
      if (!minOrderAmount) return Promise.resolve();
      if (cleanNumber(value) <= cleanNumber(minOrderAmount)) {
        return Promise.reject(
          'Max order amount value must be higher than Min order amount'
        );
      }
      const maxNotional = getFieldValue(INSTRUMENT_FIELD.MAX_NOTIONAL);
      if (!maxNotional) return Promise.resolve();
      if (cleanNumber(value) > cleanNumber(maxNotional)) {
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_NOTIONAL,
            errors: [
              'Max notional value must be equal to or higher than Max order amount',
            ],
          },
        ]);
        return Promise.resolve();
      } else {
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_NOTIONAL,
            errors: [],
          },
        ]);
        return Promise.resolve();
      }
    },
  }),
];

export const maxNotionalRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      const maxOrderAmount = getFieldValue(INSTRUMENT_FIELD.MAX_ORDER_AMOUNT);
      if (!value) return Promise.reject('This field is required');
      if (!maxOrderAmount) Promise.resolve();
      if (cleanNumber(value) < cleanNumber(maxOrderAmount))
        return Promise.reject(
          'Max notional value must be equal to or higher than Max order amount'
        );

      return Promise.resolve();
    },
  }),
];

export const minOrderAmountRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      const maxOrderAmount = getFieldValue(INSTRUMENT_FIELD.MAX_ORDER_AMOUNT);
      if (!value) return Promise.reject('This field is required');
      if (!maxOrderAmount) return Promise.resolve();
      if (cleanNumber(value) >= cleanNumber(maxOrderAmount)) {
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_ORDER_AMOUNT,
            errors: [
              'Max order amount value must be higher than Min order amount',
            ],
          },
        ]);
      } else {
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_ORDER_AMOUNT,
            errors: [],
          },
        ]);
      }

      const minNotional = getFieldValue(INSTRUMENT_FIELD.MIN_NOTIONAL);
      if (!minNotional) return Promise.resolve();
      if (cleanNumber(value) > cleanNumber(minNotional)) {
        setFields([
          {
            name: INSTRUMENT_FIELD.MIN_NOTIONAL,
            errors: [
              'Min notional value must be equal to or higher than Min order amount',
            ],
          },
        ]);
        return Promise.resolve();
      } else {
        setFields([
          {
            name: INSTRUMENT_FIELD.MIN_NOTIONAL,
            errors: [],
          },
        ]);
        return Promise.resolve();
      }
    },
  }),
];

export const minNotionalRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      const minOrderAmount = getFieldValue(INSTRUMENT_FIELD.MIN_ORDER_AMOUNT);
      if (!value) return Promise.reject('This field is required');
      if (!minOrderAmount) Promise.resolve();
      if (cleanNumber(value) < cleanNumber(minOrderAmount))
        return Promise.reject(
          'Min notional value must be equal to or higher than Min order amount'
        );

      return Promise.resolve();
    },
  }),
];

export const minPriceMovementRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      if (!value) return Promise.reject('This field is required');

      const recommendedValues = [0.00001, 0.0001, 0.001, 0.01, 0.1, 1];
      if (!recommendedValues.includes(cleanNumber(value)))
        return Promise.reject(
          'Recommended values: 0.00001, 0.0001, 0.001, 0.01, 0.1, 1'
        );

      // Check tickSize input
      const tickSize = getFieldValue(INSTRUMENT_FIELD.TICK_SIZE);
      if (tickSize && cleanNumber(value) > cleanNumber(tickSize)) {
        setFields([
          {
            name: INSTRUMENT_FIELD.TICK_SIZE,
            errors: [
              'Min tick size must be higher than Minimum price movement',
            ],
          },
        ]);
      } else {
        setFields([
          {
            name: INSTRUMENT_FIELD.TICK_SIZE,
            errors: [],
          },
        ]);
      }

      // Check Max figures for price
      const maxFiguresForPrice = getFieldValue(
        INSTRUMENT_FIELD.MAX_FIGURES_FOR_PRICE
      );
      if (!maxFiguresForPrice) return Promise.resolve();
      const { numberDecimal } = trimDecimalPlaces(maxFiguresForPrice, value);
      if (cleanNumber(maxFiguresForPrice) > numberDecimal) {
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_FIGURES_FOR_PRICE,
            errors: [
              'The value must be equal or less than the number of decimal figures in the Minimum price movement',
            ],
          },
        ]);
        return Promise.resolve();
      } else {
        setFields([
          {
            name: INSTRUMENT_FIELD.MAX_FIGURES_FOR_PRICE,
            errors: [],
          },
        ]);
        return Promise.resolve();
      }
    },
  }),
];

export const impactMarginNotionalRule: (
  setImpactMargin: React.Dispatch<React.SetStateAction<string>>
) => Rule[] = (setImpactMargin) => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      const maxLeverage = getFieldValue(INSTRUMENT_FIELD.MAX_LEVERAGE);
      if (!value) {
        setImpactMargin('');
        return Promise.reject('This field is required');
      }
      if (!maxLeverage) {
        setFields([
          {
            name: INSTRUMENT_FIELD.LEVERAGE,
            errors: ['Required field'],
          },
        ]);
      } else {
        setImpactMargin(
          formatRoundFloorDisplay(
            cleanNumber(value) / cleanNumber(maxLeverage),
            2
          )
        );
      }
      return Promise.resolve();
    },
  }),
];

export const maxLeverageTierRule: (
  inputName: string,
  maxLeverageInstrument: string | number
) => Rule[] = (inputName, maxLeverageInstrument) => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      if (!value) return Promise.reject('This field is required');
      if (!maxLeverageInstrument) return Promise.resolve();
      // let maxLeverageValue = value;
      if (cleanNumber(value) >= cleanNumber(maxLeverageInstrument)) {
        setFields([
          {
            name: inputName,
            value: cleanNumberStr(maxLeverageInstrument),
          },
        ]);
        // maxLeverageValue = cleanNumberStr(maxLeverageInstrument);
      }
      // const maintenanceMarginRate = getFieldValue(
      //   TIER_FIELD.MAINTENANCE_MARGIN_RATE
      // );
      // if (!maintenanceMarginRate) return Promise.resolve();
      // if (
      //   1 / cleanNumber(maxLeverageValue) <
      //   1 / cleanNumber(maintenanceMarginRate)
      // ) {
      //   setFields([
      //     {
      //       name: TIER_FIELD.MAINTENANCE_MARGIN_RATE,
      //       errors: ['Invalid value'],
      //     },
      //   ]);
      // } else {
      //   setFields([
      //     {
      //       name: TIER_FIELD.MAINTENANCE_MARGIN_RATE,
      //       errors: [],
      //     },
      //   ]);
      // }
      return Promise.resolve();
    },
  }),
];

export const maintenanceMarginRateRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      if (!value) return Promise.reject('This field is required');
      const maxLeverage = getFieldValue(INSTRUMENT_FIELD.MAX_LEVERAGE);
      if (!maxLeverage) return Promise.resolve();
      if (1 / cleanNumber(value) > 1 / cleanNumber(maxLeverage)) {
        return Promise.reject('Invalid value');
      }
      return Promise.resolve();
    },
  }),
];

export const evmAddressRule: () => Rule[] = () => [
  ({ getFieldValue, setFields }) => ({
    validator: async (_, value) => {
      try {
        const web3 = new Web3();
        if (!value) return Promise.reject('This field is required');
        if (!web3.utils.toChecksumAddress(value)) {
          return Promise.reject('Address must be in EVM format');
        } else return Promise.resolve();
      } catch (error) {
        return Promise.reject('Address must be in EVM format');
      }
    },
  }),
];

export const maxTierRule: (
  inputArr: number[],
  currentElement: number
) => Rule[] = (inputArr, currentElement) => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      const nextElement = findNextElementArr(inputArr, currentElement);
      if (!cleanNumberStr(value)) {
        setFields([
          {
            name: `min-${nextElement}`,
            value: 0,
          },
        ]);
        return Promise.reject('This field is required');
      }

      let currentValue = value;
      const minCurrentValue = getFieldValue(`min-${currentElement}`);
      if (cleanNumber(currentValue) < cleanNumber(minCurrentValue)) {
        // setFields([
        //   {
        //     name: `max-${currentElement}`,
        //     value: cleanNumberStr(minCurrentValue),
        //   },
        // ]);
        // currentValue = cleanNumberStr(minCurrentValue);
        return Promise.reject('Max tier must be equal or higher than min tier');
      }

      setFields([
        {
          name: `min-${nextElement}`,
          value: cleanNumber(formatRoundFloorDisplay(currentValue, 2)),
        },
      ]);

      return Promise.resolve();
    },
  }),
];

export const leverageRule: (
  inputArr: number[],
  currentElement: number,
  index: number
) => Rule[] = (inputArr, currentElement, index) => [
  ({ getFieldValue, setFields }) => ({
    validator: (_, value) => {
      if (!cleanNumberStr(value)) {
        return Promise.reject('This field is required');
      }
      let currentValue = value;
      const previous = getFieldValue(`maxLeverage-${inputArr[index - 1]}`);
      if (
        currentElement !== 0 &&
        cleanNumber(currentValue) >= cleanNumber(previous)
      ) {
        return Promise.reject(
          'This number must be lower than the previous leverage'
        );
      }
      return Promise.resolve();
    },
  }),
];
