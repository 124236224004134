import { DatePicker, DatePickerProps } from 'antd';
import React from 'react';
import { DATE_FORMAT, FULL_DATETIME_FORMAT_2 } from 'src/constants/formatters';
import './datepicker.scss';

type typeProps = {
  onChange?: () => void;
  clearIcon?: boolean;
  showToday?: boolean;
  isFullDate?: boolean;
  showTime?: boolean;
  placeholder?: string;
} & DatePickerProps;

const FracDatePicker: React.FC<typeProps> = ({
  onChange,
  clearIcon = true,
  isFullDate = false,
  showTime = false,
  placeholder,
  ...props
}) => {
  return (
    <DatePicker
      format={isFullDate && showTime ? FULL_DATETIME_FORMAT_2 : DATE_FORMAT}
      onChange={onChange}
      className="frac-date-picker"
      clearIcon={clearIcon}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default FracDatePicker;
