import { combineReducers } from 'redux';
import { connectorNameReducer } from './reducers/wallet';

import { authTypesReducers } from './reducers/auth';
import { tracsactionReducer } from './reducers/transaction-history';
import { futureRecordReducer } from './reducers/future-records';
import { siteSettingReducer } from './reducers/site-setting';
import { userManagementReducer } from './reducers/user-management';
import { instrumentReducer } from './reducers/instrument';

const appReducer = combineReducers({
  connectorName: connectorNameReducer,
  auth: authTypesReducers,
  transactions: tracsactionReducer,
  futureRecords: futureRecordReducer,
  siteSettings: siteSettingReducer,
  userManagement: userManagementReducer,
  instrument: instrumentReducer,
});

export const rootReducer = (state: any, action: any) =>
  appReducer(state, action);
