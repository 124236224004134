import { authTypes } from '../constants/auth';

const initialState = {
  isAdmin: false,
  address: '',
  token: '',
  profile: {},
};

export const authTypesReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case authTypes.CONNECT_WALLET_SUCCESS: {
      return {
        ...state,
        address: action?.payload?.walletAddress,
        isAdmin: !!action?.payload?.data.accessToken,
        token: action?.payload?.data.accessToken,
      };
    }

    case authTypes.CONNECT_WALLET_FAIL: {
      return {
        ...state,
        isAdmin: false,
        address: '',
        token: '',
      };
    }

    case authTypes.SET_ADDRESS: {
      return {
        ...state,
      };
    }

    case authTypes.SET_AUTH: {
      return {
        ...state,
        address: action.payload.address,
        isAdmin: action.payload.isAdmin,
        token: action.payload.token,
      };
    }

    case authTypes.SET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
