import { Dropdown, InputProps } from 'antd';
import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { fetchSymbolList, setSymbolList } from 'src/store/actions/instrument';
import './styles.scss';
import { DEBOUNCE_SEARCH_TIME } from 'src/constants/time';

interface IProps extends InputProps {
  showError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  onSelectItem: (symbol: string) => void;
}

const SearchSymbolInput: React.FC<IProps> = ({
  showError,
  setShowError,
  searchText,
  setSearchText,
  onSelectItem,
  placeholder = 'Search',
  ...props
}) => {
  const inputRef = useRef();
  const dispatch = useDispatch();

  const [openDropdown, setOpenDropdown] = useState(false);

  const symbolList = useSelector((state: any) => state.instrument.symbolList);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        inputRef.current &&
        !(inputRef.current as any).contains(event.target)
      ) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    if (value.length !== 0) {
      dispatch(
        fetchSymbolList(value, (res: any) => {
          if (res.length !== 0) {
            setShowError(false);
            setOpenDropdown(true);
          } else {
            setOpenDropdown(false);
            setShowError(true);
          }
        })
      );
    } else {
      onSelectItem('');
      setShowError(false);
      setOpenDropdown(false);
      dispatch(setSymbolList([]));
    }
  };

  const debounceHandler = useMemo(
    () => debounce(handleSearchChange, DEBOUNCE_SEARCH_TIME),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    debounceHandler(e);
  };

  return (
    <div className="search-wrapper" ref={inputRef as any}>
      <Dropdown
        open={openDropdown}
        overlayClassName="search-symbol-dropdown"
        menu={{
          items: symbolList?.map((item: any, index: number) => {
            return {
              key: index,
              label: (
                <div
                  onClick={() => {
                    onSelectItem(item?.symbol || '');
                    setSearchText(item?.symbol || '');
                    setOpenDropdown(false);
                  }}
                >
                  {(item?.symbol || '').toUpperCase()}
                </div>
              ),
            };
          }),
        }}
      >
        <InputSearch
          allowClear
          placeholder={placeholder}
          className="frac-input"
          value={searchText}
          onChange={handleInputChange}
          onClick={() => handleSearchChange({ target: { value: searchText } })}
          {...props}
        />
      </Dropdown>
      {showError && (
        <span className="red-text error-text text-nowrap">No result found</span>
      )}
    </div>
  );
};

export default SearchSymbolInput;
