import {
  SET_ORDER_LIST,
  SET_POSITION_LIST,
  SET_TRADE_LIST,
} from '../constants/future-records';

const initialState = {
  orderData: {},
  positionData: {},
  tradeData: {},
};

export const futureRecordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ORDER_LIST: {
      return {
        ...state,
        orderData: action.payload,
      };
    }
    case SET_POSITION_LIST: {
      return {
        ...state,
        positionData: action.payload,
      };
    }
    case SET_TRADE_LIST: {
      return {
        ...state,
        tradeData: action.payload,
      };
    }

    default:
      return state;
  }
};
